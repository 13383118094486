import React from "react";
import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Paper as MuiPaper,
  CardContent,
  Chip,
  Box,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";

import EditDelete from "../../../../components/EditDelete";
import Toolbar from "../../../../components/Toolbar";
import useWindowDimensions from "../../../../utils/windowDimensions";
import { useDialog } from "../../../../contexts/DialogContext";
import { getTimeStamp } from "../../../../utils/common";
import useCountry from "../../../../hooks/useCountry";
import useMainContentDimensions from "../../../../utils/mainContentDimensions";
import AdvanceFilter from "./AdvanceFilter";
import useAuth from "../../../../hooks/useAuth";
import ActivateUser from "./ActivateUser";
import CustomNotificationBadge from "../../../../components/CustomNotificationBadge";
import OnlineUserSession from "./OnlineUserSession";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const breadCrump = () => {
  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>HMS</Typography>
      <Typography>Hotel Users</Typography>
      <Typography>List</Typography>
    </Breadcrumbs>
  );
};
const userStatus = (status, sessionCount) => {
  if (status == "active") {
    return <Chip label="Active" color="primary" />;
  } else if (status == "online") {
    return <CustomNotificationBadge count={sessionCount} />;
  } else if (status == "expired") {
    return <Chip label="Expired" color="warning" />;
  } else if (status == "offline") {
    return <Chip label="Offline" sx={{ backgroundColor: "grey.500", color: "white" }} />;
  }
};
const CustomDataGrid = (props) => {
  const { user } = useAuth();
  const { isLoadingDownload, downloadFileType } = useCountry();
  const { width: mainContentWidth } = useMainContentDimensions();

  const { height, width } = useWindowDimensions();
  const {
    editPage,
    addPage,
    trans,
    rows,
    rowCount,
    isLoading,
    id,
    handleSortModelChange,
    sortModel,
    downloadFile,
    openDownloadDialog,
    payload,
    setPayload,
    refreshPage,
    setFilterResetFunc,
    paginationModel,
    paginationMeta,
    paging,
    onPaginationModelChange,
  } = props;
  console.log(rowCount, "loading child component");
  const { openDialog } = useDialog();

  const openActivateDialog = (rowId, actionType) => {
    console.log(rowId, actionType, "openActivateDialogwwwwwwwww");
    const selectedRowData = rows.filter((row) => row.radUserId == rowId);
    if (actionType == "Activate User") {
      return openDialog(
        "activate_user",
        "Activate User",
        <ActivateUser
          key={getTimeStamp()}
          selectedRows={selectedRowData}
          refreshPage={refreshPage}
        />,
      );
    } else if (actionType == "Show Sessions") {
      return openDialog(
        "session_users",
        "Session Users",
        <OnlineUserSession
          key={getTimeStamp()}
          selectedSession={selectedRowData}
          refreshPage={refreshPage}
        />,
        "lg",
      );
    }
  };
  const permission = (rowData) => {
    console.log(rowData, "rowData55555555");
    let menus = ["hotel_activation"];
    if (
      user &&
      (user.businessType == "SUPER_ADMIN" || user.businessType == "MULTI_SERVICE_OPERATOR")
    ) {
      menus.push("edit");
    }
    if (rowData.sessionCount != 0) {
      menus.push("session_users");
    }
    return menus;
  };
  const columns = [
    {
      field: "action",
      headerName: "Action",
      filter: false,
      sortable: false,
      width: 100,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;
          const thisRow = {};
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          console.log(thisRow, "thisRow");
        };
        return (
          <EditDelete
            rowId={params.id}
            rowClick={onClick}
            editPage={() => editPage(params.id)}
            clickIcon={openActivateDialog}
            menus={permission(params.row)}
          />
        );
      },
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 200,
    },
    {
      field: "password",
      headerName: "Password",
      width: 130,
    },
    {
      field: "userStatus",
      headerName: "User Status",
      width: 200,
      renderCell: (params) => {
        return userStatus(params.row["userStatus"], params.row["sessionCount"]);
      },
    },
    {
      field: "expiration",
      headerName: "Expiration",
      width: 200,
    },

    {
      field: "plan",
      headerName: "Plan Name",
      valueGetter: (params) => params?.planName,
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
    },
  ];

  const openFilterDialog = () => {
    return openDialog(
      "advance_filter",
      "Advance Filter",
      <AdvanceFilter
        payload={payload}
        setPayload={setPayload}
        setFilterResetFunc={setFilterResetFunc}
      />,
    );
  };

  return (
    <Card sx={{ width: "100%", maxWidth: mainContentWidth - 64 }}>
      <CardHeader
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // Stack on small screens
          justifyContent: "space-between",
          alignItems: "flex-start", // Adjust alignment as needed
        }}
        action={
          <Toolbar
            addPage={addPage}
            downloadFile={downloadFile}
            openDownloadDialog={openDownloadDialog}
            openFilterDialog={openFilterDialog}
            isLoadingDownload={isLoadingDownload}
            downloadFileType={downloadFileType}
            refreshPage={refreshPage}
            isEnableImport={true}
          />
        }
        title={
          <Typography variant="h6" sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem" } }}>
            {trans("hotel_user_list")}
          </Typography>
        }
        subheader={<Box sx={{ display: "flex", flexWrap: "wrap" }}>{breadCrump()}</Box>}
      />
      <CardContent sx={{ height: height - 180, display: "flex", flexDirection: "column" }}>
        <Box sx={{ flex: 1, overflow: "auto" }}>
          <DataGrid
            checkboxSelection
            initialState={{
              pagination: { paginationModel: { pageSize: 100 } },
            }}
            rowCount={rowCount}
            getRowId={(row) => row[id]}
            columns={columns}
            rows={rows}
            page={paging.pageNumber}
            pageSize={paging.pageSize}
            paginationMeta={paginationMeta}
            loading={isLoading}
            pageSizeOptions={[25, 50, 100]}
            paginationMode="server"
            onPaginationModelChange={onPaginationModelChange}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            paginationModel={paginationModel}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
CustomDataGrid.propTypes = {
  editPage: PropTypes.func.isRequired,
  addPage: PropTypes.func.isRequired,
  trans: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  handleSortModelChange: PropTypes.func.isRequired,
  sortModel: PropTypes.object.isRequired,
};
export default CustomDataGrid;
