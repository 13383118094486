import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";

import CustomTextField from "../../../../components/CustomTextField";
import CustomCheckBox from "../../../../components/CustomCheckBox";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import useTenant from "../../../../hooks/useTenant";
import useAuth from "../../../../hooks/useAuth";
import { useTranslation } from "react-i18next";

const currentTenantPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const BasicDetail = (props) => {
  const { user } = useAuth();
  const { validationFields } = props;
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  const [tenantPayload, setTenantPayload] = useState(currentTenantPayload);
  const [tenantOptions, setTenantOptions] = useState([]);
  const { autoSearchTenant, tenantAutoSearchResults } = useTenant();

  const fetchTenants = async () => {
    await autoSearchTenant(tenantPayload);
  };

  useEffect(() => {
    console.log(user, "hhhhhhhhhhhhhhhhh");
    if (
      user &&
      (user.businessType === "SUPER_ADMIN" || user.businessType === "MULTI_SERVICE_OPERATOR")
    ) {
      fetchTenants();
    }
  }, [tenantPayload]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (tenantAutoSearchResults.data.length !== 0) {
      const results = [...tenantOptions, ...tenantAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setTenantOptions(filteredResults);
    }
  }, [tenantAutoSearchResults]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (user && user.businessType == "LOCAL_CABLE_OPERATOR") {
      setValue("walletType", "ONLINE_RENEWAL_TENANT");
      setValue("comments", "Online payment added");
    }
  }, [user]);

  return (
    <Grid container spacing={12} className="row" pt={8}>
      {user &&
        (user.businessType === "SUPER_ADMIN" || user.businessType === "MULTI_SERVICE_OPERATOR") && (
          <Grid item xs={9.5} sm={12} md={6} lg={6} xl={6}>
            <SearchableAutoComplete
              control={control}
              data={tenantOptions}
              label={t("select_franchise")}
              payload={tenantPayload}
              setPayload={setTenantPayload}
              loading={false}
              name="tenant"
              multiple={false}
              defaultValue={""}
              hasNextPage={false}
              fields={validationFields}
            />
          </Grid>
        )}
      <Grid item md={6}>
        <CustomTextField
          label={t("amount")}
          name="amount"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item xs={9.5} sm={12} md={6} lg={6} xl={6}>
        <ReactHookFormSelect
          id="walletType"
          name="walletType"
          label={t("wallet_type")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
          disabled={user && user.businessType == "LOCAL_CABLE_OPERATOR" ? true : false}
        >
          <MenuItem value={"RECHARGE"}>Recharge</MenuItem>
          <MenuItem value={"ONLINE_RENEWAL_TENANT"}>Online Renewal</MenuItem>
        </ReactHookFormSelect>
      </Grid>

      <Grid item md={6}>
        <CustomTextField
          label={t("comments")}
          name="comments"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
          disabled={user && user.businessType == "LOCAL_CABLE_OPERATOR" ? true : false}
        />
      </Grid>
    </Grid>
  );
};

export default BasicDetail;
