import React, { useEffect, useState, useRef } from "react";
import {
  Alert as MuiAlert,
  Box,
  Grid,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";

import { useDialog } from "../../../../contexts/DialogContext";
import useUser from "../../../../hooks/useUser";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import useTenant from "../../../../hooks/useTenant";
import useRate from "../../../../hooks/useRate";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";
import CustomDatePicker from "../../../../components/CustomDatePicker";
import useScheduleUser from "../../../../hooks/useScheduleUser";
import ScheduleIcon from "@mui/icons-material/Schedule";

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  plan: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  rate: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  scheduleDate: Yup.string().required("Required"),
});

const ScheduleRenewalScreen = (props) => {
  const dispatch = useDispatch();
  const { getUser, user, isRenewSubmitting, renewUser } = useUser();
  const { addScheduleUser } = useScheduleUser();
  const { autoSearchRate, rateAutoSearchResults, isLoadingAutoSearch: rateLoading } = useRate();
  const { userId } = props;
  const currentPlanPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };
  const { tenantPlanGroupPlanAutoSearch, isLoadingAutoSearch, planGroupPlanAutoSearchResults } =
    useTenant();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { t } = useTranslation();

  const {
    reset,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
    control,
  } = methods;
  const { unSetDialog, openDialog } = useDialog();

  const [planPayload, setPlanPayload] = useState(currentPlanPayload);
  const [planOptions, setPlanOptions] = useState([]);

  const [ratePayload, setRatePayload] = useState(currentPlanPayload);
  const [rateOptions, setRateOptions] = useState([]);
  const [oldPlanId, setOldPlanId] = useState(0);
  const [tenantId, setTenantId] = useState(0);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (planGroupPlanAutoSearchResults.data.length !== 0) {
      const results = [...planOptions, ...planGroupPlanAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setPlanOptions(filteredResults);
    }
  }, [planGroupPlanAutoSearchResults]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (rateAutoSearchResults.data.length !== 0) {
      const results = [...rateOptions, ...rateAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setRateOptions(filteredResults);
    }
  }, [rateAutoSearchResults]);

  const fetchPlans = async () => {
    await tenantPlanGroupPlanAutoSearch(user?.tenantId, planPayload);
  };

  const fetchRates = async () => {
    await autoSearchRate(ratePayload);
  };

  const isInitialPlanMount = useRef(true);
  const isInitialRateMount = useRef(true);

  useEffect(() => {
    if (isInitialPlanMount.current) {
      isInitialPlanMount.current = false;
    } else {
      fetchPlans();
    }
  }, [planPayload, user]);

  useEffect(() => {
    if (isInitialRateMount.current) {
      isInitialRateMount.current = false;
    } else {
      fetchRates();
    }
  }, [ratePayload]);

  useEffect(() => {
    getUser(userId);
  }, [userId]);

  useEffect(() => {
    console.log(user, "useruser");
    if (Object.keys(user).length != 0 && user.userName != "") {
      // setPlanPayload({
      //   ...planPayload,
      //   filters: [
      //     {
      //       fieldName: "tenantId",
      //       fieldValue: user?.tenant?.tenantId,
      //       op: "eq",
      //       dataType: "int",
      //       condition: "and",
      //       isJoin: true,
      //     },
      //   ],
      // });
      const initialValues = {
        plan: {
          value: user.planId,
          label: user.planName,
        },
        rate: {
          value: user.rateId,
          label: user.rateName,
        },
        scheduleDate: "",
        scheduleDateStr: "",
      };
      reset(initialValues);
      setOldPlanId(user.planId);
      setTenantId(user.tenantId);
    } else {
      setPlanOptions([]);
    }
  }, [user]);

  const selectedPlan = watch("plan");

  console.log(selectedPlan, "seelllllelll");

  const isPlanMounted = useRef(false);

  useEffect(() => {
    console.log(selectedPlan, "selectedPlanselectedPlanselectedPlan");
    if (selectedPlan && Object.keys(selectedPlan).length != 0) {
      if (isPlanMounted.current) {
        resetField("rate", { defaultValue: "" });
      } else {
        isPlanMounted.current = true;
      }
      setRateOptions([]);
      setRatePayload({
        ...ratePayload,
        filters: [
          {
            fieldName: "planId",
            fieldValue: selectedPlan?.value,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
          },
        ],
      });
    } else {
      setRateOptions([]);
    }
  }, [selectedPlan]);

  const onSubmit = async (data) => {
    console.log(data, "kjskskks");
    try {
      const payload = {
        plan: {
          planId: data.plan.value,
          planName: data.plan.label,
        },
        rate: {
          rateId: data.rate.value,
          rateName: data.rate.label,
        },
        radUserId: userId,
        scheduleDateStr: data.scheduleDateStr,
        scheduleType: "MANUAL",
      };
      console.log(payload, "jkkkk");
      await addScheduleUser(payload);
      dispatch(showSuccessSnackbar("Scheduled successfully!"));
      unSetDialog("schedule");
    } catch (ex) {
      dispatch(showErrorSnackbar(ex.message));
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} className="row" pt={8}>
            <Grid item md={6}>
              <SearchableAutoComplete
                control={control}
                data={planOptions}
                label={t("select_plan")}
                payload={planPayload}
                setPayload={setPlanPayload}
                loading={isLoadingAutoSearch}
                name="plan"
                multiple={false}
                defaultValue={""}
                hasNextPage={false}
                fields={validationSchema.fields}
              />
            </Grid>
            <Grid item md={6}>
              <SearchableAutoComplete
                control={control}
                data={rateOptions}
                label={t("select_rate")}
                payload={ratePayload}
                setPayload={setRatePayload}
                loading={rateLoading}
                name="rate"
                multiple={false}
                defaultValue={""}
                hasNextPage={false}
                fields={validationSchema.fields}
              />
            </Grid>
            <Grid item md={6}>
              <CustomDatePicker
                name="scheduleDate"
                nameTemp="scheduleDateStr"
                label={t("schedule_date")}
                range={false}
                control={control}
                fields={validationSchema.fields}
                timePicker={true}
                format="DD-MM-YYYY HH:mm"
                hideSeconds={true}
                conversionFormat="YYYY-MM-DD HH:mm:ss"
                minDate={new Date()}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<ScheduleIcon />}
              endIcon={isRenewSubmitting ? <CircularProgress size={24} /> : null}
              disabled={false}
            >
              Schedule
            </Button>

            <Button variant="text" onClick={() => unSetDialog("schedule")} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default ScheduleRenewalScreen;
