//Users
import AgreementTrackerList from "../pages/agreement_tracker/index.js";
import AddAgreementTracker from "../pages/agreement_tracker/add.js";
import EditAgreementTracker from "../pages/agreement_tracker/edit.js";

//Composers
import { AgreementTrackerComposer } from "../composers/AgreementTrackerComposer.js";
import { DashboardComposer } from "../composers/DashboardComposer.js";

// Guards
import AuthGuard from "../components/guards/AuthGuard.js";
import DashboardLayout from "../layouts/Dashboard.js";
import ProtectedLayout from "../layouts/ProtectedLayout";
import AgTrackDetails from "../pages/agreement_tracker/agTrackDetails.js";
// import AgreementTrackerClosed from "../pages/agreementTracker/agreementTrackerclosed.js";

const AgreementTrackerRoutes = {
  path: "agreement-tracker",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  roles: ["SUPER_ADMIN"],
  children: [
    {
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <AgreementTrackerComposer>
                <AddAgreementTracker />
              </AgreementTrackerComposer>
            </ProtectedLayout>
          ),
          roles: ["SUPER_ADMIN"],
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <AgreementTrackerComposer>
                <EditAgreementTracker />
              </AgreementTrackerComposer>
            </ProtectedLayout>
          ),
          roles: ["SUPER_ADMIN"],
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <AgreementTrackerComposer>
                <AgreementTrackerList />
              </AgreementTrackerComposer>
            </ProtectedLayout>
          ),
          roles: ["SUPER_ADMIN"],
        },
        {
          path: "details",
          element: (
            <ProtectedLayout>
              <AgreementTrackerComposer>
                <AgTrackDetails loadingFrom="normalPage" />
              </AgreementTrackerComposer>
            </ProtectedLayout>
          ),
          roles: ["SUPER_ADMIN"],
        },
      ],
    },
  ],
};

export default AgreementTrackerRoutes;
