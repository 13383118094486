import { useContext } from "react";

import { MaterialContext } from "../contexts/MaterialContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const useMaterial = () => {
  const context = useContext(MaterialContext);

  if (!context) throw new Error("MaterialContext must be placed within MaterialProvider");

  return context;
};

export default useMaterial;
