import React, { useState, useEffect, useRef } from "react";
import { Grid, MenuItem } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import useIpTv from "../../../../hooks/useIpTv";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import { searchPayload } from "../../../../utils/common";

const currentIpTvPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const currentRatePayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

function IpTvSettings(props) {
  const { autoSearchIpTv, iptvAutoSearchResults } = useIpTv();
  const { t } = useTranslation();

  const { validationFields } = props;
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const [ipTvPayload, setIpTvPayload] = useState(currentIpTvPayload);
  const [ipTvOptions, setIpTvOptions] = useState([]);

  const fetchIpTvs = async () => {
    await autoSearchIpTv(ipTvPayload);
  };

  useEffect(() => {
    fetchIpTvs();
  }, [ipTvPayload]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (iptvAutoSearchResults.data.length !== 0) {
      const results = [...ipTvOptions, ...iptvAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      console.log(filteredResults, "filteredResults");
      setIpTvOptions(filteredResults);
    }
  }, [iptvAutoSearchResults]);

  return (
    <Grid container spacing={12} className="row" pt={8}>
      <Grid item xs={10} sm={12} md={6} lg={6} xl={4}>
        <SearchableAutoComplete
          control={control}
          data={ipTvOptions}
          label={t("select_iptv")}
          payload={ipTvPayload}
          setPayload={setIpTvPayload}
          loading={false}
          name="ipTV"
          multiple={false}
          defaultValue={""}
          hasNextPage={false}
          fields={validationFields}
        />
      </Grid>
    </Grid>
  );
}

export default IpTvSettings;
