//Users
import EmailLogComposer from "../composers/EmailLogComposer.js";
import Composer, { SmsToEmployeeComposer } from "../composers/SmsToEmployeeComposer.js";

//Composers
import { DashboardComposer } from "../composers/DashboardComposer.js";
// Guards
import AuthGuard from "../components/guards/AuthGuard.js";
import DashboardLayout from "../layouts/Dashboard.js";
import EmailLog from "../pages/logs/email_logs/index.js";
import SmsToEmployee from "../pages/sms_and_email/send_sms_to_employee/index.js";
import SmsToTenantComposer from "../composers/SmsToTenantComposer.js";
import EmailToUserComposer from "../composers/EmailToUserComposer";
import EmailToTenantComposer from "../composers/EmailToTenantComposer";
import SmsToUserComposer from "../composers/SmsToUserComposer.js";
import EmailToTenant from "../pages/sms_and_email/send_email_to_tenants/index.js";
import EmailToUser from "../pages/sms_and_email/send_email_to_users/index.js";
import SmsToUser from "../pages/sms_and_email/send_sms_to_users/index.js";
import SmsToTenant from "../pages/sms_and_email/send_sms_to_tenants/index.js";
import ProtectedLayout from "../layouts/ProtectedLayout";

// import LeadClosed from "../pages/lead/leadclosed.js";

const SmsAndEmailRoutes = {
  path: "sms-and-email",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  roles: ["SUPER_ADMIN"],
  children: [
    {
      path: "email",
      element: (
        <ProtectedLayout>
          <EmailLogComposer>
            <EmailLog />
          </EmailLogComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "sms-employee",
      element: (
        <ProtectedLayout>
          <SmsToEmployeeComposer>
            <SmsToEmployee />
          </SmsToEmployeeComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "sms-tenant",
      element: (
        <ProtectedLayout>
          <SmsToTenantComposer>
            <SmsToTenant />
          </SmsToTenantComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "sms-user",
      element: (
        <ProtectedLayout>
          <SmsToUserComposer>
            <SmsToUser />
          </SmsToUserComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "email-user",
      element: (
        <ProtectedLayout>
          <EmailToUserComposer>
            <EmailToUser />
          </EmailToUserComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "email-Tenant",
      element: (
        <ProtectedLayout>
          <EmailToTenantComposer>
            <EmailToTenant />
          </EmailToTenantComposer>
        </ProtectedLayout>
      ),
    },
  ],
};

export default SmsAndEmailRoutes;
