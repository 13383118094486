import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { UserProvider } from "../contexts/UserContext";
import { DialogProvider } from "../contexts/DialogContext";
import { TenantProvider } from "../contexts/TenantContext";
import { StateMasterProvider } from "../contexts/StateMasterContext";
import { DistrictProvider } from "../contexts/DistrictContext";
import { CountryProvider } from "../contexts/CountryContext";
import { TalukProvider } from "../contexts/TalukContext";
import { CityProvider } from "../contexts/CityContext";
import { VillageProvider } from "../contexts/VillageContext";
import { PincodeProvider } from "../contexts/PincodeContext";
import { RateProvider } from "../contexts/RateContext";
import { BranchProvider } from "../contexts/BranchContext";
import { PlanProvider } from "../contexts/PlanContext";
import { PlanGroupProvider } from "../contexts/PlanGroupContext";
import { UnpaidInvoiceProvider } from "../contexts/UnpaidInvoiceContext";
import { PaidInvoiceProvider } from "../contexts/PaidInvoiceContext";
import { CancelledInvoiceProvider } from "../contexts/CancelledInvoiceContext";
import { PlanHistoryProvider } from "../contexts/PlanHistoryContext";
import { KycDocumentProvider } from "../contexts/KycDocumentContext";
import { ComplaintProvider } from "../contexts/ComplaintContext";
import { ScheduleUserProvider } from "../contexts/ScheduleUserContext";
import { RadUserWalletProvider } from "../contexts/RadUserWalletContext";
import { HotelManagementProvider } from "../contexts/HotelManagementContext";
import { SessionHistoryProvider } from "../contexts/SessionHistoryContext";
import { AuthenticationLogProvider } from "../contexts/AuthenticationLogContext";
import { TrafficReportProvider } from "../contexts/TrafficReportContext";
import { ProformaInvoiceProvider } from "../contexts/ProformaInvoiceContext";
import { RadUserPlanProvider } from "../contexts/RadUserPlanContext";
import { EmployeeProvider } from "../contexts/EmployeeContext";

export const UserComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <UserProvider key={0} />,
        <TenantProvider key={1} />,
        <PincodeProvider key={2} />,
        <VillageProvider key={3} />,
        <CityProvider key={4} />,
        <TalukProvider key={5} />,
        <DistrictProvider key={6} />,
        <CountryProvider key={7} />,
        <StateMasterProvider key={8} />,
        <RateProvider key={9} />,
        <BranchProvider key={10} />,
        <PlanProvider key={11} />,
        <PlanGroupProvider key={12} />,
        <UnpaidInvoiceProvider key={13} />,
        <PaidInvoiceProvider key={14} />,
        <CancelledInvoiceProvider key={15} />,
        <PlanHistoryProvider key={16} />,
        <KycDocumentProvider key={17} />,
        <ComplaintProvider key={18} />,
        <ScheduleUserProvider key={19} />,
        <RadUserWalletProvider key={20} />,
        <HotelManagementProvider key={21} />,
        <SessionHistoryProvider key={22} />,
        <AuthenticationLogProvider key={23} />,
        <TrafficReportProvider key={24} />,
        <RadUserPlanProvider key={25} />,
        <ProformaInvoiceProvider key={26} />,
        <EmployeeProvider key={27} />,
        <DialogProvider key={28} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
