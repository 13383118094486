import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";
import { useFormContext } from "react-hook-form";

import CustomTextField from "../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";

function MaterialFields(props) {
  const { t } = useTranslation();
  const { fields } = props;
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <Grid container spacing={6} className="row">
      <Grid item md={6}>
        <CustomTextField
          label={t("product_name")}
          name="materialName"
          control={control}
          fullWidth
          variant="outlined"
          fields={fields}
          errors={errors}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("unit_of_measurement")}
          name="uom"
          control={control}
          fullWidth
          variant="outlined"
          fields={fields}
          errors={errors}
        />
      </Grid>
      <Grid item xs={7} sm={12} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          name="status"
          label={t("Status")}
          control={control}
          fullWidth
          variant="outlined"
          fields={fields}
          errors={errors}
        >
          <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
          <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
}

export default MaterialFields;
