import { PaymentGatewayConstants } from "../constants/payment_gateway.constants";

const PaymentGatewayReducer = (state, action) => {
  switch (action.type) {
    case PaymentGatewayConstants.FETCH_PAYMENT_GATEWAY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PaymentGatewayConstants.FETCH_PAYMENT_GATEWAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentGateways: action.payload.data,
        paging: action.payload.paging,
      };
    case PaymentGatewayConstants.FETCH_PAYMENT_GATEWAY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PaymentGatewayConstants.PAYMENT_GATEWAY_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PaymentGatewayConstants.PAYMENT_GATEWAY_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentGatewaySearchResults: action.payload,
      };
    case PaymentGatewayConstants.PAYMENT_GATEWAY_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PaymentGatewayConstants.ADD_PAYMENT_GATEWAY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PaymentGatewayConstants.ADD_PAYMENT_GATEWAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case PaymentGatewayConstants.ADD_PAYMENT_GATEWAY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };

    case PaymentGatewayConstants.GET_PAYMENT_GATEWAY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PaymentGatewayConstants.GET_PAYMENT_GATEWAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentGateway: action.payload,
      };
    case PaymentGatewayConstants.GET_PAYMENT_GATEWAY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PaymentGatewayConstants.UPDATE_PAYMENT_GATEWAY_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PaymentGatewayConstants.UPDATE_PAYMENT_GATEWAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentGateway: action.payload,
      };
    case PaymentGatewayConstants.UPDATE_PAYMENT_GATEWAY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case PaymentGatewayConstants.DOWNLOAD_PAYMENT_GATEWAY_REQUEST:
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case PaymentGatewayConstants.DOWNLOAD_PAYMENT_GATEWAY_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case PaymentGatewayConstants.DOWNLOAD_PAYMENT_GATEWAY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case PaymentGatewayConstants.UPLOAD_PAYMENT_GATEWAY_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case PaymentGatewayConstants.UPLOAD_PAYMENT_GATEWAY_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case PaymentGatewayConstants.UPLOAD_PAYMENT_GATEWAY_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    case PaymentGatewayConstants.PAYMENT_GATEWAY_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        autoSearchLoading: true,
        hasError: false,
      };
    case PaymentGatewayConstants.PAYMENT_GATEWAY_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        autoSearchLoading: false,
        paymentGatewayAutoSearchResults: action.payload,
      };
    case PaymentGatewayConstants.PAYMENT_GATEWAY_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        autoSearchLoading: false,
      };

    default:
      return state;
  }
};

export default PaymentGatewayReducer;
