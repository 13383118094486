export const saveFile = async (blob, fileName) => {
  const a = document.createElement("a");
  a.download = fileName;
  a.href = URL.createObjectURL(blob);
  a.addEventListener("click", (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();
};

export const getTimeStamp = () => {
  return new Date().getTime();
};

export const convertedValue = (values, key) => {
  if (Object.prototype.toString.call(values) === "[object Array]") {
    return values.map(function (data) {
      return data[key];
    });
  } else {
    return [values[key]];
  }
};

export const roundOff = (num) => {
  if (num == null) {
    return 0.0;
  } else {
    return (Math.round(num * 100) / 100).toFixed(2);
  }
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const convertMBToBytes = (mb) => {
  if (!+mb) return 0;

  const k = 1024;

  console.log(mb, "mbmb");

  const i = mb * Math.pow(1024, 2);

  return i;
};

export const convertBytesToMB = (bytes) => {
  if (!+bytes) return 0; // If the value is not a number or is 0, return 0
  const k = 1024;
  const mb = bytes / Math.pow(k, 2); // Divide bytes by 1024^2 to convert to MB
  console.log(bytes, "bytes in MB:", mb);
  return mb;
};

export const searchPayload = () => {
  return {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };
};

export const searchPayloadEqInt = (fieldName, fieldValue, isJoin = true) => {
  return {
    filters: [
      {
        fieldName: fieldName,
        fieldValue: fieldValue,
        op: "eq",
        dataType: "int",
        condition: "and",
        isJoin: isJoin,
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };
};

export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const convertToDateString = (dateString) => {
  const dateStringConverted = dateString.split(" ");
  const date = dateStringConverted[0];
  const time = dateStringConverted[1].split(".")[0];
  var hours = parseInt(time.substring(0, 2), 10),
    minutes = time.substring(3, 5),
    ampm = "AM";

  if (hours == 12) {
    ampm = "PM";
  } else if (hours == 0) {
    hours = 12;
  } else if (hours > 12) {
    hours -= 12;
    ampm = "PM";
  }
  return date.split("-").reverse().join("-") + " " + hours + ":" + minutes + " " + ampm;
};

export const invoiceStatusOptions = () => {
  return [
    {
      label: "Payment Pending",
      value: "PP",
    },
    {
      label: "Un Paid",
      value: "UP",
    },
    {
      label: "Paid",
      value: "PA",
    },
    {
      label: "Canelled",
      value: "CA",
    },
  ];
};

export const complaintStatusOptions = () => {
  return [
    {
      label: "Open",
      value: "OP",
    },
    {
      label: "Assigned",
      value: "AS",
    },
    {
      label: "Reassigned",
      value: "RE",
    },
    {
      label: "Resolved",
      value: "RES",
    },
    {
      label: "In Progress",
      value: "IP",
    },
    {
      label: "Closed",
      value: "CL",
    },
  ];
};

export const formatDateTime = (date) => {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const dd = String(date.getDate()).padStart(2, "0");
  const hh = String(date.getHours()).padStart(2, "0");
  const min = String(date.getMinutes()).padStart(2, "0");
  const ss = String(date.getSeconds()).padStart(2, "0");

  return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
};
export const formatDate = (date) => {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const dd = String(date.getDate()).padStart(2, "0");

  return `${yyyy}-${mm}-${dd}`;
};

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};
export const convertSecondToDays = (seconds) => {
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${days} day${days !== 1 ? "s" : ""} ${hours} 
  hour${hours !== 1 ? "s" : ""} ${minutes} min${minutes !== 1 ? "s" : ""}`;
};
