export const HotelManagementConstants = {
  FETCH_HOTEL_MANAGEMENT_REQUEST: "FETCH_HOTEL_MANAGEMENT_REQUEST",
  FETCH_HOTEL_MANAGEMENT_SUCCESS: "FETCH_HOTEL_MANAGEMENT_SUCCESS",
  FETCH_HOTEL_MANAGEMENT_FAILURE: "FETCH_HOTEL_MANAGEMENT_FAILURE",
  FETCH_HOTEL_ACTIVATION_REPORT_REQUEST: "FETCH_HOTEL_ACTIVATION_REPORT_REQUEST",
  FETCH_HOTEL_ACTIVATION_REPORT_SUCCESS: "FETCH_HOTEL_ACTIVATION_REPORT_SUCCESS",
  FETCH_HOTEL_ACTIVATION_REPORT_FAILURE: "FETCH_HOTEL_ACTIVATION_REPORT_FAILURE",
  ADD_HOTEL_MANAGEMENT_REQUEST: "ADD_HOTEL_MANAGEMENT_REQUEST",
  ADD_HOTEL_MANAGEMENT_SUCCESS: "ADD_HOTEL_MANAGEMENT_SUCCESS",
  ADD_HOTEL_MANAGEMENT_FAILURE: "ADD_HOTEL_MANAGEMENT_FAILURE",
  ADD_HOTEL_MANAGEMENT_ACTIVATE_USER_REQUEST: "ADD_HOTEL_MANAGEMENT_ACTIVATE_USER_REQUEST",
  ADD_HOTEL_MANAGEMENT_ACTIVATE_USER_SUCCESS: "ADD_HOTEL_MANAGEMENT_ACTIVATE_USER_SUCCESS",
  ADD_HOTEL_MANAGEMENT_ACTIVATE_USER_FAILURE: "ADD_HOTEL_MANAGEMENT_ACTIVATE_USER_FAILURE",
  ADD_HOTEL_MANAGEMENT_DEACTIVATE_USER_REQUEST: "ADD_HOTEL_MANAGEMENT_DEACTIVATE_USER_REQUEST",
  ADD_HOTEL_MANAGEMENT_DEACTIVATE_USER_SUCCESS: "ADD_HOTEL_MANAGEMENT_DEACTIVATE_USER_SUCCESS",
  ADD_HOTEL_MANAGEMENT_DEACTIVATE_USER_FAILURE: "ADD_HOTEL_MANAGEMENT_DEACTIVATE_USER_FAILURE",
  DISCONNECT_USER_SESSION_REQUEST: "DISCONNECT_USER_SESSION_REQUEST",
  DISCONNECT_USER_SESSION_SUCCESS: "DISCONNECT_USER_SESSION_SUCCESS",
  DISCONNECT_USER_SESSION_FAILURE: "DISCONNECT_USER_SESSION_FAILURE",
  HOTEL_MANAGEMENT_SEARCH_REQUEST: "HOTEL_MANAGEMENT_SEARCH_REQUEST",
  HOTEL_MANAGEMENT_SEARCH_SUCCESS: "HOTEL_MANAGEMENT_SEARCH_SUCCESS",
  HOTEL_MANAGEMENT_SEARCH_FAILURE: "HOTEL_MANAGEMENT_SEARCH_FAILURE",
  HOTEL_MANAGEMENT_AUTO_SEARCH_REQUEST: "HOTEL_MANAGEMENT_AUTO_SEARCH_REQUEST",
  HOTEL_MANAGEMENT_AUTO_SEARCH_SUCCESS: "HOTEL_MANAGEMENT_AUTO_SEARCH_SUCCESS",
  HOTEL_MANAGEMENT_AUTO_SEARCH_FAILURE: "HOTEL_MANAGEMENT_AUTO_SEARCH_FAILURE",
  GET_HOTEL_MANAGEMENT_REQUEST: "GET_HOTEL_MANAGEMENT_REQUEST",
  GET_HOTEL_MANAGEMENT_SUCCESS: "GET_HOTEL_MANAGEMENT_SUCCESS",
  GET_HOTEL_MANAGEMENT_FAILURE: "GET_HOTEL_MANAGEMENT_FAILURE",
  UPDATE_HOTEL_MANAGEMENT_REQUEST: "UPDATE_HOTEL_MANAGEMENT_REQUEST",
  UPDATE_HOTEL_MANAGEMENT_SUCCESS: "UPDATE_HOTEL_MANAGEMENT_SUCCESS",
  UPDATE_HOTEL_MANAGEMENT_FAILURE: "UPDATE_HOTEL_MANAGEMENT_FAILURE",
  DOWNLOAD_HOTEL_MANAGEMENT_REQUEST: "DOWNLOAD_HOTEL_MANAGEMENT_REQUEST",
  DOWNLOAD_HOTEL_MANAGEMENT_SUCCESS: "DOWNLOAD_HOTEL_MANAGEMENT_SUCCESS",
  DOWNLOAD_HOTEL_MANAGEMENT_FAILURE: "DOWNLOAD_HOTEL_MANAGEMENT_FAILURE",
  UPLOAD_HOTEL_MANAGEMENT_REQUEST: "UPLOAD_HOTEL_MANAGEMENT_REQUEST",
  UPLOAD_HOTEL_MANAGEMENT_SUCCESS: "UPLOAD_HOTEL_MANAGEMENT_SUCCESS",
  UPLOAD_HOTEL_MANAGEMENT_FAILURE: "UPLOAD_HOTEL_MANAGEMENT_FAILURE",
};
