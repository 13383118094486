import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, MenuItem } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import useTenant from "../../../../hooks/useTenant";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import FilterReset from "../../../../components/FilterReset";
import { invoiceStatusOptions, convertedValue } from "../../../../utils/common";
import { OperatorConstants } from "../../../../constants/operator.constants";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import CustomTextField from "../../../../components/CustomTextField";
import { useDialog } from "../../../../contexts/DialogContext";
import usePlan from "../../../../hooks/usePlan";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

function AdvanceFilter(props) {
  const { unSetDialog } = useDialog();
  const initialValues = {
    userNameOperator: "eq",
    customerNameOperator: "eq",
    resellerNameOperator: "eq",
    userName: "",
    customerName: "",
    resellerName: "",
  };

  const { fields, payload, setPayload, setFilterResetFunc } = props;

  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = methods;

  useEffect(() => {
    setFilterResetFunc(() => reset);
  }, [reset]);

  const onSubmit = async (data) => {
    try {
      let filters = [];
      if (data.userName != null && Object.keys(data.userName).length != 0) {
        filters.push({
          fieldName: "userName",
          fieldValue: data.userName,
          op: data.userNameOperator,
          dataType: "string",
          condition: "and",
          isJoin: true,
          entityName: "RadUserEntity",
          attributeName: "radUser",
        });
      }
      if (data.customerName != null && Object.keys(data.customerName).length != 0) {
        filters.push({
          fieldName: "customerName",
          fieldValue: data.customerName,
          op: data.customerNameOperator,
          dataType: "string",
          condition: "and",
          isJoin: true,
          entityName: "RadUserEntity",
          attributeName: "radUser",
        });
      }
      if (data.resellerName != null && Object.keys(data.resellerName).length != 0) {
        filters.push({
          fieldName: "resellerName",
          fieldValue: data.resellerName,
          op: data.resellerNameOperator,
          dataType: "string",
          condition: "and",
          isJoin: true,
          entityName: "RadUserEntity",
          attributeName: "radUser",
        });
      }
      setPayload({
        ...payload,
        filters: filters,
      });
      unSetDialog("advance_filter");
    } catch (err) {
      console.error(err, "errerrerrerr");
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} pt={8}>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="userNameOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.string).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <CustomTextField
                label={t("user_name")}
                name="userName"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="customerNameOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <CustomTextField
                label={t("customer_name")}
                name="customerName"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} pt={8}>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="resellerNameOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <CustomTextField
                label={t("reseller_name")}
                name="resellerName"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
          </Grid>

          <FilterReset resetForm={reset} />
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default AdvanceFilter;
