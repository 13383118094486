//Users
import EditCardSystem from "../pages/card_system/generate_card/edit.js";
import CardSystem from "../pages/card_system/generate_card/index.js";
import AddCardSystem from "../pages/card_system/generate_card/add.js";
import OnlineCards from "../pages/card_system/online_cards/index.js";
import PrepaidCards from "../pages/card_system/list_prepaid_cards/index.js";
import RefillCards from "../pages/card_system/list_refill_cards/index.js";
import CardStats from "../pages/card_system/card_statistics/cardstats.js";
import CardSessionHistory from "../pages/card_system/card_session_history/index.js";

//Composers

import { DashboardComposer } from "../composers/DashboardComposer.js";
import { CardSystemComposer } from "../composers/CardSystemComposer";

// Guards
import AuthGuard from "../components/guards/AuthGuard.js";
import DashboardLayout from "../layouts/Dashboard.js";
import ProtectedLayout from "../layouts/ProtectedLayout";
// import EditcardSystemType from "../pages/cardSystem_type/edit.js";

const CardSystemRoutes = {
  path: "card-system",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  roles: ["SUPER_ADMIN"],
  children: [
    {
      path: "online-cards",
      element: (
        <ProtectedLayout>
          <CardSystemComposer>
            <OnlineCards />
          </CardSystemComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "prepaid-cards",
      element: (
        <ProtectedLayout>
          <CardSystemComposer>
            <PrepaidCards />
          </CardSystemComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "refill-cards",
      element: (
        <ProtectedLayout>
          <CardSystemComposer>
            <RefillCards />
          </CardSystemComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "card-statistics",
      element: (
        <ProtectedLayout>
          <CardSystemComposer>
            <CardStats />
          </CardSystemComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "card-session-history",
      element: (
        <ProtectedLayout>
          <CardSystemComposer>
            <CardSessionHistory />
          </CardSystemComposer>
        </ProtectedLayout>
      ),
    },

    {
      path: "generate-card",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <CardSystemComposer>
                <AddCardSystem />
              </CardSystemComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <CardSystemComposer>
                <EditCardSystem />
              </CardSystemComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <CardSystemComposer>
                <CardSystem />
              </CardSystemComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
  ],
};

export default CardSystemRoutes;
