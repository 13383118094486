import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import IpAddressReducer from "../reducers/IpAddressReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { IpAddressConstants } from "../constants/ipaddress.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  ipAddresses: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  ipAddressSearchResults: [],
  ipAddress: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  ipAddressAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const IpAddressContext = createContext({
  ...initialState,
  getAllIpAddress: () => {},
  addIpAddress: () => {},
  searchIpAddress: () => {},
  autoSearchIpAddress: () => {},
  getIpAddress: () => {},
  updateIpAddress: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function IpAddressProvider({ children }) {
  const [state, dispatch] = useReducer(IpAddressReducer, initialState);

  const getAllIpAddress = async (payload) => {
    try {
      dispatch({
        type: IpAddressConstants.FETCH_IP_ADDRESS_REQUEST,
      });
      const response = await axios.post("/ip-address/list", payload);
      dispatch({
        type: IpAddressConstants.FETCH_IP_ADDRESS_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: IpAddressConstants.FETCH_IP_ADDRESS_FAILURE,
      });
    }
  };
  const searchIpAddress = async (payload) => {
    try {
      const response = await axios.post("/ip-address/search", payload);
      dispatch({
        type: IpAddressConstants.IP_ADDRESS_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: IpAddressConstants.IP_ADDRESS_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: IpAddressConstants.IP_ADDRESS_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchIpAddress = async (payload) => {
    try {
      const response = await axios.post("/ip-address/auto-search", payload);
      dispatch({
        type: IpAddressConstants.IP_ADDRESS_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: IpAddressConstants.IP_ADDRESS_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: IpAddressConstants.IP_ADDRESS_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addIpAddress = async (payload) => {
    const response = await axios.post("/ip-address/add", payload);
    dispatch({
      type: IpAddressConstants.ADD_IP_ADDRESS_REQUEST,
    });
    dispatch({
      type: IpAddressConstants.ADD_IP_ADDRESS_SUCCESS,
      payload: response.data,
    });
  };

  const getIpAddress = async (id) => {
    const response = await axios.get("/ip-address/" + id);
    dispatch({
      type: IpAddressConstants.GET_IP_ADDRESS_REQUEST,
    });
    dispatch({
      type: IpAddressConstants.GET_IP_ADDRESS_SUCCESS,
      payload: response.data,
    });
  };

  const updateIpAddress = async (payload) => {
    const response = await axios.put("/ip-address/update/" + payload.ipAddressId, payload);
    dispatch({
      type: IpAddressConstants.UPDATE_IP_ADDRESS_REQUEST,
    });
    dispatch({
      type: IpAddressConstants.UPDATE_IP_ADDRESS_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: IpAddressConstants.DOWNLOAD_IP_ADDRESS_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/ip-address/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "ipAddress." + fileType);
    dispatch({
      type: IpAddressConstants.DOWNLOAD_IP_ADDRESS_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/ip-address/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_ipAddress." + fileType);
    dispatch({
      type: IpAddressConstants.DOWNLOAD_IP_ADDRESS_REQUEST,
    });
    dispatch({
      type: IpAddressConstants.DOWNLOAD_IP_ADDRESS_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: IpAddressConstants.UPLOAD_IP_ADDRESS_REQUEST,
      });
      const response = await axios({
        url: "/ip-address/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: IpAddressConstants.UPLOAD_IP_ADDRESS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: IpAddressConstants.UPLOAD_IP_ADDRESS_FAILURE,
      });
    }
  };

  return (
    <IpAddressContext.Provider
      value={{
        ...state,
        getAllIpAddress,
        addIpAddress,
        searchIpAddress,
        autoSearchIpAddress,
        getIpAddress,
        updateIpAddress,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </IpAddressContext.Provider>
  );
}

export { IpAddressContext, IpAddressProvider };
