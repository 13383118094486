import { ScheduleUserConstants } from "../constants/scheduleuser.constants";

const ScheduleUserReducer = (state, action) => {
  switch (action.type) {
    case ScheduleUserConstants.FETCH_SCHEDULE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ScheduleUserConstants.FETCH_SCHEDULE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        scheduleUsers: action.payload.data,
        paging: action.payload.paging,
      };
    case ScheduleUserConstants.FETCH_SCHEDULE_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case ScheduleUserConstants.SCHEDULE_USER_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ScheduleUserConstants.SCHEDULE_USER_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        scheduleUserSearchResults: action.payload,
      };
    case ScheduleUserConstants.SCHEDULE_USER_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case ScheduleUserConstants.SCHEDULE_USER_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ScheduleUserConstants.SCHEDULE_USER_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        scheduleUserAutoSearchResults: action.payload,
      };
    case ScheduleUserConstants.SCHEDULE_USER_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case ScheduleUserConstants.ADD_SCHEDULE_USER_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case ScheduleUserConstants.ADD_SCHEDULE_USER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case ScheduleUserConstants.ADD_SCHEDULE_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case ScheduleUserConstants.ADD_CANCEL_USER_SCHEDULE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case ScheduleUserConstants.ADD_CANCEL_USER_SCHEDULE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case ScheduleUserConstants.ADD_CANCEL_USER_SCHEDULE_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case ScheduleUserConstants.GET_SCHEDULE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ScheduleUserConstants.GET_SCHEDULE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        scheduleUser: action.payload,
      };
    case ScheduleUserConstants.GET_SCHEDULE_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case ScheduleUserConstants.GET_ACTIVE_SCHEDULE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ScheduleUserConstants.GET_ACTIVE_SCHEDULE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activeScheduleUser: action.payload,
      };
    case ScheduleUserConstants.GET_ACTIVE_SCHEDULE_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case ScheduleUserConstants.UPDATE_SCHEDULE_USER_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case ScheduleUserConstants.UPDATE_SCHEDULE_USER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        scheduleUser: action.payload,
      };
    case ScheduleUserConstants.UPDATE_SCHEDULE_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case ScheduleUserConstants.DOWNLOAD_SCHEDULE_USER_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case ScheduleUserConstants.DOWNLOAD_SCHEDULE_USER_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case ScheduleUserConstants.DOWNLOAD_SCHEDULE_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case ScheduleUserConstants.UPLOAD_SCHEDULE_USER_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case ScheduleUserConstants.UPLOAD_SCHEDULE_USER_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case ScheduleUserConstants.UPLOAD_SCHEDULE_USER_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default ScheduleUserReducer;
