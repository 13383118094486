import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import ReportReducer from "../reducers/ReportReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { ReportConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  paymentGateways: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  depositPaymentGateways: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  dueReports: {
    dueReportSum: {
      discount: 0,
      serviceTax: 0,
      planAmount: 0,
      finalInvoiceAmount: 0,
    },
    paging: {
      totalNumberOfRecords: 0,
      totalNumberOfPages: 0,
      pageNumber: 0,
      pageSize: 0,
    },
    data: [],
  },
  renewalReports: {
    paging: {
      totalNumberOfRecords: 0,
      totalNumberOfPages: 0,
      pageNumber: 0,
      pageSize: 0,
    },
    data: [],
  },
  collectionReports: {
    collectionReportSum: {
      discount: 0,
      serviceTax: 0,
      planAmount: 0,
      finalInvoiceAmount: 0,
    },
    paging: {
      totalNumberOfRecords: 0,
      totalNumberOfPages: 0,
      pageNumber: 0,
      pageSize: 0,
    },
    data: [],
  },
  walletReports: {
    paging: {
      totalNumberOfRecords: 0,
      totalNumberOfPages: 0,
      pageNumber: 0,
      pageSize: 0,
    },
    data: [],
  },
  sharingReports: {
    sharingReportSum: {
      renewalAmount: 0,
      msoShareAmount: 0,
      lcoShareAmount: 0,
      distShareAmount: 0,
      taxAmount: 0,
    },
    paging: {
      totalNumberOfRecords: 0,
      totalNumberOfPages: 0,
      pageNumber: 0,
      pageSize: 0,
    },
    data: [],
  },
  autoSearchLoading: false,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const ReportContext = createContext({
  ...initialState,
  downloadFile: () => {},
  getRenewalReports: () => {},
  getDueReports: () => {},
  getCollectionReports: () => {},
  getAllWallet: () => {},
  getSharingReports: () => {},
  downloadRenewalReport: () => {},
  getPaymentGatewayReport: () => {},
  getDepositPaymentGatewayReport: () => {},
  downloadDuesReport: () => {},
  downloadWalletReport: () => {},
  downloadSharingReport: () => {},
});

function ReportProvider({ children }) {
  const [state, dispatch] = useReducer(ReportReducer, initialState);

  const getRenewalReports = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/renew-reports", payload);
      dispatch({
        type: ReportConstants.FETCH_RENEWAL_REPORT_REQUEST,
      });
      dispatch({
        type: ReportConstants.FETCH_RENEWAL_REPORT_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: ReportConstants.FETCH_RENEWAL_REPORT_FAILURE,
      });
    }
  };

  const getPaymentGatewayReport = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/renew-reports", payload);
      dispatch({
        type: ReportConstants.FETCH_PAYMENT_GATEWAY_REPORT_REQUEST,
      });
      dispatch({
        type: ReportConstants.FETCH_PAYMENT_GATEWAY_REPORT_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: ReportConstants.FETCH_PAYMENT_GATEWAY_REPORT_FAILURE,
      });
    }
  };

  const getDepositPaymentGatewayReport = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/renew-reports", payload);
      dispatch({
        type: ReportConstants.FETCH_DEPOSIT_PAYMENT_GATEWAY_REPORT_REQUEST,
      });
      dispatch({
        type: ReportConstants.FETCH_DEPOSIT_PAYMENT_GATEWAY_REPORT_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: ReportConstants.FETCH_DEPOSIT_PAYMENT_GATEWAY_REPORT_FAILURE,
      });
    }
  };

  const getDueReports = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/due-reports", payload);
      dispatch({
        type: ReportConstants.FETCH_DUE_REPORT_REQUEST,
      });
      dispatch({
        type: ReportConstants.FETCH_DUE_REPORT_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: ReportConstants.FETCH_DUE_REPORT_FAILURE,
      });
    }
  };

  const getCollectionReports = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/collection-reports", payload);
      dispatch({
        type: ReportConstants.FETCH_COLLECTION_REPORT_REQUEST,
      });
      dispatch({
        type: ReportConstants.FETCH_COLLECTION_REPORT_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: ReportConstants.FETCH_COLLECTION_REPORT_FAILURE,
      });
    }
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: ReportConstants.DOWNLOAD_USER_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/radius/raduser/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "user." + fileType);

    dispatch({
      type: ReportConstants.DOWNLOAD_USER_SUCCESS,
      payload: response.data,
    });
  };

  const downloadRenewalReport = async (fileType, payload) => {
    dispatch({
      type: ReportConstants.DOWNLOAD_USER_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/radius/raduser/renewal/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "renewal_report." + fileType);

    dispatch({
      type: ReportConstants.DOWNLOAD_USER_SUCCESS,
      payload: response.data,
    });
  };

  const downloadDuesReport = async (fileType, payload) => {
    dispatch({
      type: ReportConstants.DOWNLOAD_USER_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/radius/raduser/dues/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "dues_report." + fileType);

    dispatch({
      type: ReportConstants.DOWNLOAD_USER_SUCCESS,
      payload: response.data,
    });
  };

  const downloadWalletReport = async (fileType, payload) => {
    dispatch({
      type: ReportConstants.DOWNLOAD_USER_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/cpanel/wallet/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "wallet_report." + fileType);

    dispatch({
      type: ReportConstants.DOWNLOAD_USER_SUCCESS,
      payload: response.data,
    });
  };

  const downloadSharingReport = async (fileType, payload) => {
    dispatch({
      type: ReportConstants.DOWNLOAD_USER_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/radius/raduser/sharing/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "sharing_report." + fileType);

    dispatch({
      type: ReportConstants.DOWNLOAD_USER_SUCCESS,
      payload: response.data,
    });
  };

  const getAllWallet = async (payload) => {
    try {
      const response = await axios.post("/cpanel/wallet/list", payload);
      dispatch({
        type: ReportConstants.FETCH_WALLET_REQUEST,
      });
      dispatch({
        type: ReportConstants.FETCH_WALLET_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: ReportConstants.FETCH_WALLET_FAILURE,
      });
    }
  };
  const getSharingReports = async (payload) => {
    try {
      const response = await axios.post("/radius/raduser/sharing-reports", payload);
      dispatch({
        type: ReportConstants.FETCH_SHARING_REPORT_REQUEST,
      });
      dispatch({
        type: ReportConstants.FETCH_SHARING_REPORT_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: ReportConstants.FETCH_SHARING_REPORT_FAILURE,
      });
    }
  };

  return (
    <ReportContext.Provider
      value={{
        ...state,
        downloadFile,
        getRenewalReports,
        getDueReports,
        getCollectionReports,
        getPaymentGatewayReport,
        getDepositPaymentGatewayReport,
        getAllWallet,
        getSharingReports,
        downloadRenewalReport,
        downloadDuesReport,
        downloadWalletReport,
        downloadSharingReport,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
}

export { ReportContext, ReportProvider };
