import React from "react";
import styled from "@emotion/styled";
import { Chart } from "react-chartjs-2";
import { MoreVertical } from "react-feather";
import { useTheme } from "@mui/material/styles";

import { green, red, orange } from "@mui/material/colors";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import { formatBytes, convertMBToBytes } from "../../../utils/common";
import usePlan from "../../../hooks/usePlan";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(2)};
  }
`;

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;

  &:last-child th,
  &:last-child td {
    border-bottom: 0;
  }
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const PlanDetails = ({ user }) => {
  const theme = useTheme();
  const CardContent = styled(MuiCardContent)`
    &:last-child {
      padding-bottom: ${theme.spacing(2)};
    }
  `;
  const { getPlan, plan, updatePlan, isSubmitting } = usePlan();
  const data = {
    labels: ["Social", "Search Engines", "Direct", "Other"],
    datasets: [
      {
        data: [260, 125, 164],
        backgroundColor: [theme.palette.secondary.main, red[500], orange[500]],
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "80%",
  };

  return (
    <Card mb={6} sx={{ backgroundColor: "sss" }}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="Plan Details"
      />

      <CardContent theme={theme}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Plan Name:
              </TableCell>
              <TableCell align="right">{user.planName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Validatity:
              </TableCell>
              <TableCell align="right">{user.validity}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Rate:
              </TableCell>
              <TableCell align="right">{user.rateName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Monthly Total:
              </TableCell>
              <TableCell align="right">{formatBytes(user.quotaLimit)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Used Data:
              </TableCell>
              <TableCell align="right">{formatBytes(user.usedQuotaLimit)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Balance:
              </TableCell>
              <TableCell align="right">{formatBytes(user.balanceQuotaLimit)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default PlanDetails;
