export const ScheduleUserConstants = {
  FETCH_SCHEDULE_USER_REQUEST: "FETCH_SCHEDULE_USER_REQUEST",
  FETCH_SCHEDULE_USER_SUCCESS: "FETCH_SCHEDULE_USER_SUCCESS",
  FETCH_SCHEDULE_USER_FAILURE: "FETCH_SCHEDULE_USER_FAILURE",
  ADD_SCHEDULE_USER_REQUEST: "ADD_SCHEDULE_USER_REQUEST",
  ADD_SCHEDULE_USER_SUCCESS: "ADD_SCHEDULE_USER_SUCCESS",
  ADD_SCHEDULE_USER_FAILURE: "ADD_SCHEDULE_USER_FAILURE",
  ADD_CANCEL_USER_SCHEDULE_REQUEST: "ADD_CANCEL_USER_SCHEDULE_REQUEST",
  ADD_CANCEL_USER_SCHEDULE_SUCCESS: "ADD_CANCEL_USER_SCHEDULE_SUCCESS",
  ADD_CANCEL_USER_SCHEDULE_FAILURE: "ADD_CANCEL_USER_SCHEDULE_FAILURE",
  SCHEDULE_USER_SEARCH_REQUEST: "SCHEDULE_USER_SEARCH_REQUEST",
  SCHEDULE_USER_SEARCH_SUCCESS: "SCHEDULE_USER_SEARCH_SUCCESS",
  SCHEDULE_USER_SEARCH_FAILURE: "SCHEDULE_USER_SEARCH_FAILURE",
  SCHEDULE_USER_AUTO_SEARCH_REQUEST: "SCHEDULE_USER_AUTO_SEARCH_REQUEST",
  SCHEDULE_USER_AUTO_SEARCH_SUCCESS: "SCHEDULE_USER_AUTO_SEARCH_SUCCESS",
  SCHEDULE_USER_AUTO_SEARCH_FAILURE: "SCHEDULE_USER_AUTO_SEARCH_FAILURE",
  GET_SCHEDULE_USER_REQUEST: "GET_SCHEDULE_USER_REQUEST",
  GET_SCHEDULE_USER_SUCCESS: "GET_SCHEDULE_USER_SUCCESS",
  GET_SCHEDULE_USER_FAILURE: "GET_SCHEDULE_USER_FAILURE",
  GET_ACTIVE_SCHEDULE_USER_REQUEST: "GET_ACTIVE_SCHEDULE_USER_REQUEST",
  GET_ACTIVE_SCHEDULE_USER_SUCCESS: "GET_ACTIVE_SCHEDULE_USER_SUCCESS",
  GET_ACTIVE_SCHEDULE_USER_FAILURE: "GET_ACTIVE_SCHEDULE_USER_FAILURE",
  UPDATE_SCHEDULE_USER_REQUEST: "UPDATE_SCHEDULE_USER_REQUEST",
  UPDATE_SCHEDULE_USER_SUCCESS: "UPDATE_SCHEDULE_USER_SUCCESS",
  UPDATE_SCHEDULE_USER_FAILURE: "UPDATE_SCHEDULE_USER_FAILURE",
  DOWNLOAD_SCHEDULE_USER_REQUEST: "DOWNLOAD_SCHEDULE_USER_REQUEST",
  DOWNLOAD_SCHEDULE_USER_SUCCESS: "DOWNLOAD_SCHEDULE_USER_SUCCESS",
  DOWNLOAD_SCHEDULE_USER_FAILURE: "DOWNLOAD_SCHEDULE_USER_FAILURE",
  UPLOAD_SCHEDULE_USER_REQUEST: "UPLOAD_SCHEDULE_USER_REQUEST",
  UPLOAD_SCHEDULE_USER_SUCCESS: "UPLOAD_SCHEDULE_USER_SUCCESS",
  UPLOAD_SCHEDULE_USER_FAILURE: "UPLOAD_SCHEDULE_USER_FAILURE",
};
