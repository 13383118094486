import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import ScheduleUserReducer from "../reducers/ScheduleUserReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { ScheduleUserConstants } from "../constants/scheduleuser.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  scheduleUsers: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  scheduleUserSearchResults: [],
  scheduleUser: {},
  activeScheduleUser: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  scheduleUserAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const ScheduleUserContext = createContext({
  ...initialState,
  getAllScheduleUser: () => {},
  addScheduleUser: () => {},
  searchScheduleUser: () => {},
  autoSearchScheduleUser: () => {},
  getScheduleUser: () => {},
  cancelUserSchedule: () => {},
  getActiveScheduleUser: () => {},
  updateScheduleUser: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function ScheduleUserProvider({ children }) {
  const [state, dispatch] = useReducer(ScheduleUserReducer, initialState);

  const getAllScheduleUser = async (payload) => {
    try {
      dispatch({
        type: ScheduleUserConstants.FETCH_SCHEDULE_USER_REQUEST,
      });
      const response = await axios.post("/schedule-user/list", payload);
      dispatch({
        type: ScheduleUserConstants.FETCH_SCHEDULE_USER_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: ScheduleUserConstants.FETCH_SCHEDULE_USER_FAILURE,
      });
    }
  };
  const searchScheduleUser = async (payload) => {
    try {
      const response = await axios.post("/schedule-user/search", payload);
      dispatch({
        type: ScheduleUserConstants.SCHEDULE_USER_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: ScheduleUserConstants.SCHEDULE_USER_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: ScheduleUserConstants.SCHEDULE_USER_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchScheduleUser = async (payload) => {
    try {
      const response = await axios.post("/schedule-user/auto-search", payload);
      dispatch({
        type: ScheduleUserConstants.SCHEDULE_USER_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: ScheduleUserConstants.SCHEDULE_USER_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: ScheduleUserConstants.SCHEDULE_USER_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addScheduleUser = async (payload) => {
    const response = await axios.post("/schedule-user/add", payload);
    dispatch({
      type: ScheduleUserConstants.ADD_SCHEDULE_USER_REQUEST,
    });
    dispatch({
      type: ScheduleUserConstants.ADD_SCHEDULE_USER_SUCCESS,
      payload: response.data,
    });
  };

  const cancelUserSchedule = async (payload) => {
    const response = await axios.post("/schedule-user/cancel-schedule", payload);
    dispatch({
      type: ScheduleUserConstants.ADD_CANCEL_USER_SCHEDULE_REQUEST,
    });
    dispatch({
      type: ScheduleUserConstants.ADD_CANCEL_USER_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  };

  const getScheduleUser = async (id) => {
    const response = await axios.get("/schedule-user/" + id);
    dispatch({
      type: ScheduleUserConstants.GET_SCHEDULE_USER_REQUEST,
    });
    dispatch({
      type: ScheduleUserConstants.GET_SCHEDULE_USER_SUCCESS,
      payload: response.data,
    });
  };

  const getActiveScheduleUser = async (radUserId) => {
    const response = await axios.get("/schedule-user/active/" + radUserId);
    dispatch({
      type: ScheduleUserConstants.GET_ACTIVE_SCHEDULE_USER_REQUEST,
    });
    dispatch({
      type: ScheduleUserConstants.GET_ACTIVE_SCHEDULE_USER_SUCCESS,
      payload: response.data,
    });
  };

  const updateScheduleUser = async (payload) => {
    const response = await axios.put("/schedule-user/update/" + payload.scheduleUserId, payload);
    dispatch({
      type: ScheduleUserConstants.UPDATE_SCHEDULE_USER_REQUEST,
    });
    dispatch({
      type: ScheduleUserConstants.UPDATE_SCHEDULE_USER_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: ScheduleUserConstants.DOWNLOAD_SCHEDULE_USER_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/schedule-user/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "scheduleUser." + fileType);
    dispatch({
      type: ScheduleUserConstants.DOWNLOAD_SCHEDULE_USER_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/schedule-user/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_scheduleUser." + fileType);
    dispatch({
      type: ScheduleUserConstants.DOWNLOAD_SCHEDULE_USER_REQUEST,
    });
    dispatch({
      type: ScheduleUserConstants.DOWNLOAD_SCHEDULE_USER_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: ScheduleUserConstants.UPLOAD_SCHEDULE_USER_REQUEST,
      });
      const response = await axios({
        url: "/schedule-user/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: ScheduleUserConstants.UPLOAD_SCHEDULE_USER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: ScheduleUserConstants.UPLOAD_SCHEDULE_USER_FAILURE,
      });
    }
  };

  return (
    <ScheduleUserContext.Provider
      value={{
        ...state,
        getAllScheduleUser,
        addScheduleUser,
        searchScheduleUser,
        autoSearchScheduleUser,
        getActiveScheduleUser,
        cancelUserSchedule,
        getScheduleUser,
        updateScheduleUser,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </ScheduleUserContext.Provider>
  );
}

export { ScheduleUserContext, ScheduleUserProvider };
