import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";
import { useFormContext } from "react-hook-form";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";

import CustomTextField from "../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import useNas from "../../../../hooks/useNas";
import useInterface from "../../../../hooks/useInterface";
import usePppProfile from "../../../../hooks/usePppProfile";
import CustomCheckBox from "../../../../components/CustomCheckBox";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

function PppServerFields(props) {
  const { t } = useTranslation();
  const { fields } = props;

  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext();

  const { autoSearchNas, nasAutoSearchResults, getNas, nas } = useNas();

  const [nasOptions, setNasOptions] = useState([]);
  const [nasPayload, setNasPayload] = useState(currentPayload);

  const { autoSearchInterface, interfaceAutoSearchResults } = useInterface();

  const [interfaceOptions, setInterfaceOptions] = useState([]);
  const [interfacePayload, setInterfacePayload] = useState(currentPayload);

  const { autoSearchPppProfile, pppProfileAutoSearchResults, getPppProfile, pppProfile } =
    usePppProfile();

  const [pppProfileOptions, setPppProfileOptions] = useState([]);
  const [pppProfilePayload, setPppProfilePayload] = useState(currentPayload);

  const fetchNas = async () => {
    await autoSearchNas(nasPayload);
  };
  useEffect(() => {
    fetchNas();
  }, [nasPayload]);

  const selectedNas = watch("nas");

  useEffect(() => {
    console.log(selectedNas, "selectedNasselectedNasselectedNas");
    if (selectedNas != null && Object.keys(selectedNas).length != 0) {
      const payload = {
        ...interfacePayload,
        filters: [
          {
            fieldName: "nasId",
            fieldValue: selectedNas?.value,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
            attributeName: "nas",
            entityName: "InterfaceEntity",
          },
        ],
      };
      setInterfaceOptions([]);
      setInterfacePayload(payload);
    }
  }, [selectedNas]);

  const fetchInterface = async () => {
    await autoSearchInterface(interfacePayload);
  };
  useEffect(() => {
    fetchInterface();
  }, [interfacePayload]);

  useEffect(() => {
    console.log(nasAutoSearchResults, "nasAutoSearchResults");
    if (nasAutoSearchResults && nasAutoSearchResults.data.length !== 0) {
      const results = [...nasOptions, ...nasAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setNasOptions(filteredResults);
    }
  }, [nasAutoSearchResults]);

  useEffect(() => {
    console.log(interfaceAutoSearchResults, "interfaceAutoSearchResults");
    if (interfaceAutoSearchResults && interfaceAutoSearchResults.data.length !== 0) {
      const results = [...interfaceOptions, ...interfaceAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setInterfaceOptions(filteredResults);
    }
  }, [interfaceAutoSearchResults]);

  const fetchPppProfile = async () => {
    await autoSearchPppProfile(pppProfilePayload);
  };
  useEffect(() => {
    fetchPppProfile();
  }, [pppProfilePayload]);

  useEffect(() => {
    console.log(pppProfileAutoSearchResults, "pppProfileAutoSearchResults");
    if (pppProfileAutoSearchResults && pppProfileAutoSearchResults.data.length !== 0) {
      const results = [...pppProfileOptions, ...pppProfileAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setPppProfileOptions(filteredResults);
    }
  }, [pppProfileAutoSearchResults]);

  return (
    <Grid container spacing={6} className="row">
      <Grid item xs={7.5} sm={6} md={6} lg={6} xl={4}>
        <SearchableAutoComplete
          name="nas"
          label={t("nas")}
          control={control}
          data={nasOptions}
          payload={nasPayload}
          setPayload={setNasPayload}
          loading={false}
          multiple={false}
          defaultValue={""}
          hasNextPage={false}
          fields={fields}
          fullWidth
          variant="outlined"
          errors={errors}
        ></SearchableAutoComplete>
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("service_name")}
          name="serviceName"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item xs={7.5} sm={6} md={6} lg={6} xl={4}>
        <SearchableAutoComplete
          name="mkInterface"
          label={t("interfaces_name")}
          control={control}
          data={interfaceOptions}
          payload={interfacePayload}
          setPayload={setInterfacePayload}
          loading={false}
          multiple={false}
          defaultValue={""}
          hasNextPage={false}
          fields={fields}
          fullWidth
          variant="outlined"
          errors={errors}
        ></SearchableAutoComplete>
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("max_mtu")}
          name="maxMtu"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("max_mru")}
          name="maxMru"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("mrru")}
          name="mrRu"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("keepalive_timeout")}
          name="keepAliveTimeout"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item xs={7.5} sm={6} md={6} lg={6} xl={4}>
        <SearchableAutoComplete
          name="pppProfile"
          label={t("default_profile")}
          control={control}
          data={pppProfileOptions}
          payload={pppProfilePayload}
          setPayload={setPppProfilePayload}
          loading={false}
          multiple={false}
          defaultValue={""}
          hasNextPage={false}
          fields={fields}
          fullWidth
          variant="outlined"
          errors={errors}
        ></SearchableAutoComplete>
      </Grid>
      <Grid item xs={7.5} sm={6} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          name="status"
          label={t("Status")}
          control={control}
          fullWidth
          variant="outlined"
          fields={fields}
          errors={errors}
        >
          <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
          <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      <Grid item xs={7.5} sm={6} md={6} lg={6} xl={4}>
        <i>Authentication</i>
        <CustomCheckBox
          label="PAP"
          name="pap"
          type="checkbox"
          my={2}
          fullWidth
          errors={errors}
          fields={fields}
        />
      </Grid>
    </Grid>
  );
}

export default PppServerFields;
