//Users
import MaterialList from "../pages/inventory_management/materials/index.js";
import AddMaterials from "../pages/inventory_management/materials/add.js";
import EditMaterial from "../pages/inventory_management/materials/edit.js";
//Composers
import MaterialComposer from "../composers/MaterialComposer.js";
import { DashboardComposer } from "../composers/DashboardComposer.js";
// Guards
import AuthGuard from "../components/guards/AuthGuard.js";
import DashboardLayout from "../layouts/Dashboard.js";
import ProtectedLayout from "../layouts/ProtectedLayout.js";

const MaterialRoutes = {
  path: "inventory-mgnt",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  roles: ["SUPER_ADMIN"],
  children: [
    {
      path: "materials",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <MaterialComposer>
                <AddMaterials />
              </MaterialComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <MaterialComposer>
                <EditMaterial />
              </MaterialComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <MaterialComposer>
                <MaterialList />
              </MaterialComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
  ],
};

export default MaterialRoutes;
