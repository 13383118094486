import React, { useEffect, useState, useRef } from "react";
import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  DialogActions,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  Alert,
  CardHeader,
  Typography,
  MenuItem,
  Button,
} from "@mui/material";
import SaveCancel from "../../../components/SaveCancel";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";
import CustomTextField from "../../../components/CustomTextField";
import ReactHookFormSelect from "../../../components/ReactHookFormSelect";
import { useDialog } from "../../../contexts/DialogContext";
import useUser from "../../../hooks/useUser";
import SearchableAutoComplete from "../../../components/SearchableAutoComplete";
import useTenant from "../../../hooks/useTenant";
import useRate from "../../../hooks/useRate";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import useEmployee from "../../../hooks/useEmployee";
import useComplaint from "../../../hooks/useComplaint";
import { useNavigate, useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const validationSchema = Yup.object().shape({
  employee: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),

  comments: Yup.string().required("Required"),
});

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const AssignUser = (props) => {
  const { unSetDialog } = useDialog();

  const { t } = useTranslation();
  const { getComplaint, complaint, assignComplaint, isSubmitting } = useComplaint();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [employeePayload, setEmployeePayload] = useState(currentPayload);
  const { autoSearchEmployee, employeeAutoSearchResults } = useEmployee();

  const { getUser, user, isRenewSubmitting, renewUser } = useUser();
  const { autoSearchRate, rateAutoSearchResults, isLoadingAutoSearch: rateLoading } = useRate();
  const { complaintId, fetchData } = props;

  console.log(complaintId, "complaintId");

  React.useEffect(() => {
    if (Object.keys(complaint).length !== 0) {
      console.log(complaint.status, "complaint.status");
      const initialValues = {
        complaintId: complaint.complaintId,
        employee: {
          label: complaint.employee.employeeName,
          value: complaint.employee.employeeId,
        },
      };
      reset(initialValues);
    }
  }, [complaint]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [status] = useState({
    sent: false,
  });

  const { state } = useLocation();
  console.log(state, "location");

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;
  const [employeeOptions, setEmployeeOptions] = useState([]);

  const onSubmit = async (data) => {
    try {
      data["employee"] = {
        employeeId: data.employee.value,
      };
      await assignComplaint(data);

      dispatch(showSuccessSnackbar("Assigned successfully!"));
      unSetDialog("assign");
      fetchData();
    } catch (err) {
      console.log(err, "ddddddddddddd");
      dispatch(showErrorSnackbar("Failed to assign "));
    }
  };
  const cancelBtn = () => {
    navigate("/complaints/list");
  };

  // employee

  const fetchEmployee = async () => {
    await autoSearchEmployee(employeePayload);
  };
  useEffect(() => {
    fetchEmployee();
  }, [employeePayload]);

  const fetch = async () => {
    await getComplaint(complaintId);
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    console.log(employeeAutoSearchResults, "employeeAutoSearchResults");
    if (employeeAutoSearchResults && employeeAutoSearchResults.data.length !== 0) {
      const results = [...employeeOptions, ...employeeAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setEmployeeOptions(filteredResults);
    }
  }, [employeeAutoSearchResults]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} className="row" pt={8}>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={6}>
              <SearchableAutoComplete
                label={t("assign_to_employee")}
                name="employee"
                data={employeeOptions}
                control={control}
                payload={employeePayload}
                setPayload={setEmployeePayload}
                loading={false}
                fullWidth
                variant="outlined"
                errors={errors}
                multiple={false}
                defaultValue={""}
                hasNextPage={false}
                fields={validationSchema.fields}
              />
            </Grid>

            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={6}>
              <CustomTextField
                label={t("comments")}
                name="comments"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
                rows={1}
                multiline={true}
                fields={validationSchema.fields}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              endIcon={isSubmitting ? <CircularProgress size={24} /> : null}
            >
              Assign
            </Button>

            <Button variant="text" onClick={() => unSetDialog("map_nas_modal")} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default AssignUser;
