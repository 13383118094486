import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import { useDialog } from "../../../contexts/DialogContext";
import SaveIcon from "@mui/icons-material/Save";

import {
  Grid,
  Divider as MuiDivider,
  Box,
  DialogActions,
  Button,
  CircularProgress,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import TextField from "@mui/material/TextField";
import { useForm, FormProvider } from "react-hook-form";
import useHotelManagement from "../../../hooks/useHotelManagement";

function DeactivateUser(props) {
  const { selectedRows, refreshPage } = props;
  const { deactivateUser, isRenewSubmitting } = useHotelManagement();
  const dispatch = useDispatch();
  const { unSetDialog, openDialog } = useDialog();

  const methods = useForm();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      console.log(selectedRows, "selectedRows");
      if (selectedRows == 0) {
        return dispatch(showErrorSnackbar("Please select the user"));
      }

      await deactivateUser(selectedRows);
      dispatch(showSuccessSnackbar("User Deactivated Successfully!"));
      refreshPage();
      unSetDialog("deactivate_user");
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to Deactivate user"));
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} className="row" pt={8}>
            <Grid item md={6}>
              <i>Do you want to deactivate the user?</i>
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              endIcon={isRenewSubmitting ? <CircularProgress size={24} /> : null}
              disabled={false}
            >
              Yes
            </Button>

            <Button variant="text" onClick={() => unSetDialog("deactivate_user")} color="primary">
              No
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default DeactivateUser;
