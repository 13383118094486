import React from "react";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "../layouts/Auth";
import DashboardLayout from "../layouts/Dashboard";
import EmployeeDashboard from "../pages/dashboards/Default/EmployeeDashboard";
import DocLayout from "../layouts/Doc";
import PresentationLayout from "../layouts/Presentation";
import WalletList from "../pages/cpanel/wallet";
import AddWallet from "../pages/cpanel/wallet/add";
import EditWallet from "../pages/cpanel/wallet/edit";

import { WalletComposer } from "../composers/WalletComposer";
import { EmailLogComposer } from "../composers/EmailLogComposer";

// Guards
import AuthGuard from "../components/guards/AuthGuard";

// Auth components
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Page components
import Blank from "../pages/pages/Blank";
import Pricing from "../pages/pages/Pricing";
import Settings from "../pages/pages/Settings";
import Projects from "../pages/pages/Projects";
import Chat from "../pages/pages/Chat";
import Challenge from "../pages/pages/Challenge";

// Landing
import Landing from "../pages/presentation/Landing";

// Protected routes
import ProtectedPage from "../pages/protected/ProtectedPage";

import MasterRoutes from "./master_routes";
import RadiusRoutes from "./radius_routes";
import CpanelRoutes from "./cpanel_routes";
import UserRoutes from "./user_routes";
import SettingsRoutes from "./settings_routes";
import ReportRoutes from "./report_routes";
import ComplaintRoutes from "./complaint_routes";
import LeadRoutes from "./lead_routes";
import { DashboardComposer } from "../composers/DashboardComposer";
import LogRoutes from "../routes/logs_routes";
import InvoiceRoutes from "./invoices_routes";
import SmsAndEmailRoutes from "./sms_and_email_routes";
import HotelDashboard from "../pages/dashboards/Default/HotelDashboard";
import CardSystemRoutes from "./card_system_routes";
import MaterialRoutes from "./material_routes";
import HotelManagementRoutes from "./hms_routes";
import MikrotikMgntRoutes from "./mikrotik_mgnt_routes";

// Dashboard components
import Default from "../pages/dashboards/Default";
import Analytics from "../pages/dashboards/Analytics";
import SaaS from "../pages/dashboards/SaaS";
import AgreementTrackerRoutes from "./agreement_tracker_routes";
import ProtectedLayout from "../layouts/ProtectedLayout";

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardComposer>
          <DashboardLayout />
        </DashboardComposer>
      </AuthGuard>
    ),
    roles: ["SUPER_ADMIN"],
    children: [
      {
        path: "",
        element: (
          <DashboardComposer>
            <Default />
          </DashboardComposer>
        ),
        menuPermission: ["dashboard", "default"],
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardComposer>
          <DashboardLayout />
        </DashboardComposer>
      </AuthGuard>
    ),
    children: [
      {
        path: "default",
        element: (
          <ProtectedLayout>
            <Default />
          </ProtectedLayout>
        ),
        menuPermission: ["dashboard", "default"],
      },
      {
        path: "analytics",
        element: <Analytics />,
        menuPermission: ["dashboard", "default"],
      },
      {
        path: "saas",
        element: <SaaS />,
        menuPermission: ["dashboard", "default"],
      },
    ],
  },

  {
    path: "hotel-dashboard",
    element: (
      <AuthGuard>
        <DashboardComposer>
          <DashboardLayout />
        </DashboardComposer>
      </AuthGuard>
    ),
    roles: ["HOTEL"],
    children: [
      {
        path: "default",
        element: <HotelDashboard />,
        roles: ["HOTEL"],
      },
    ],
  },
  {
    path: "employee-dashboard",
    element: (
      <AuthGuard>
        <DashboardComposer>
          <DashboardLayout />
        </DashboardComposer>
      </AuthGuard>
    ),
    roles: ["EMPLOYEE"],
    children: [
      {
        path: "default",
        element: <EmployeeDashboard />,
        roles: ["EMPLOYEE"],
      },
    ],
  },
  {
    path: "pages",
    element: <DashboardLayout />,
    children: [
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "chat",
        element: <Chat />,
      },
      {
        path: "blank",
        element: <Blank />,
      },
    ],
  },
  {
    path: "projects",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Projects />,
      },
    ],
  },
  {
    path: ".well-known/acme-challenge/:challengeid",
    element: <Challenge />,
  },

  {
    path: "unauthorized",
    element: <ProtectedPage />,
    roles: ["USER", "SUPER_ADMIN"],
  },

  {
    path: "auth",
    element: <AuthLayout />,
    roles: ["SUPER_ADMIN", "USER"],
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
        roles: ["SUPER_ADMIN", "USER"],
      },
      {
        path: "sign-up",
        element: <SignUp />,
        roles: ["SUPER_ADMIN", "USER"],
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
        roles: ["SUPER_ADMIN", "USER"],
      },
      {
        path: "404",
        element: <Page404 />,
        roles: ["SUPER_ADMIN", "USER"],
      },
      {
        path: "500",
        element: <Page500 />,
        roles: ["SUPER_ADMIN", "USER"],
      },
    ],
  },

  {
    path: "user",
    element: <AuthLayout />,
    roles: ["SUPER_ADMIN", "USER"],
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
        roles: ["SUPER_ADMIN", "USER"],
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
        roles: ["SUPER_ADMIN", "USER"],
      },
    ],
  },

  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    roles: ["SUPER_ADMIN"],
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    roles: ["SUPER_ADMIN", "USER"],
    children: [
      {
        path: "*",
        element: <Page404 />,
        roles: ["SUPER_ADMIN", "USER"],
      },
    ],
  },
  {
    path: "wallet",
    element: (
      <AuthGuard>
        <DashboardComposer>
          <DashboardLayout />
        </DashboardComposer>
      </AuthGuard>
    ),
    roles: ["SUPER_ADMIN"],
    children: [
      {
        path: "add",
        element: (
          <WalletComposer>
            <AddWallet />
          </WalletComposer>
        ),
        roles: ["SUPER_ADMIN"],
      },
      {
        path: "edit",
        element: (
          <WalletComposer>
            <EditWallet />
          </WalletComposer>
        ),
        roles: ["SUPER_ADMIN"],
      },
      {
        path: "list",
        element: (
          <WalletComposer>
            <WalletList />
          </WalletComposer>
        ),
        roles: ["SUPER_ADMIN"],
      },
    ],
  },

  // {
  //   path: "emaillog",
  //   element: (
  //     <AuthGuard>
  //       <DashboardComposer>
  //         <DashboardLayout />
  //       </DashboardComposer>
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "add",
  //       element: (
  //         <EmailLogComposer>
  //           <AddEmailLog />
  //         </EmailLogComposer>
  //       ),
  //     },
  //     {
  //       path: "edit",
  //       element: (
  //         <EmailLogComposer>
  //           <EditWallet />
  //         </EmailLogComposer>
  //       ),
  //     },
  //     {
  //       path: "list",
  //       element: (
  //         <EmailLogComposer>
  //           <EmailLog />
  //         </EmailLogComposer>
  //       ),
  //     },
  //   ],
  // },
  MasterRoutes,
  RadiusRoutes,
  CpanelRoutes,
  UserRoutes,
  SettingsRoutes,
  ReportRoutes,
  ComplaintRoutes,
  LeadRoutes,
  LogRoutes,
  InvoiceRoutes,
  SmsAndEmailRoutes,
  CardSystemRoutes,
  MaterialRoutes,
  HotelManagementRoutes,
  MikrotikMgntRoutes,
  AgreementTrackerRoutes,
];

export default routes;
