import React, { useEffect, useState, useRef } from "react";
import {
  Alert as MuiAlert,
  Box,
  Grid,
  DialogActions,
  MenuItem,
  Button,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";

import { useDialog } from "../../../../contexts/DialogContext";
import useUser from "../../../../hooks/useUser";
import CustomTextField from "../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";
import useRadUserWallet from "../../../../hooks/useRadUserWallet";

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  amount: Yup.string().required("Required"),
  walletType: Yup.string().required("Required"),
  comments: Yup.string().required("Required"),
});

const AddWallet = (props) => {
  const dispatch = useDispatch();
  const { getUser, user, isRenewSubmitting } = useUser();

  const { addRadUserWallet } = useRadUserWallet();
  const { userId } = props;

  const { t } = useTranslation();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {
    reset,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
    control,
  } = methods;
  const { unSetDialog, openDialog } = useDialog();

  useEffect(() => {
    getUser(userId);
  }, [userId]);

  const onSubmit = async (data) => {
    try {
      const payload = {
        radUser: {
          radUserId: userId,
          userName: "",
        },
        amount: data.amount,
        walletType: data.walletType,
        comments: data.comments,
      };
      await addRadUserWallet(payload);
      dispatch(showSuccessSnackbar("Wallet Added successfully!"));
      unSetDialog("add_wallet");
    } catch (ex) {
      dispatch(showErrorSnackbar(ex.message));
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} className="row" pt={8}>
            <Grid item md={6}>
              <CustomTextField
                name="amount"
                label={t("amount")}
                control={control}
                fields={validationSchema.fields}
                fullWidth
                variant="outlined"
                errors={errors}
              ></CustomTextField>
            </Grid>
            <Grid item md={6}>
              <ReactHookFormSelect
                id="walletType"
                name="walletType"
                label={t("wallet_type")}
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
                fields={validationSchema.fields}
              >
                <MenuItem value={"RECHARGE"}>Recharge</MenuItem>
                <MenuItem value={"WRONG_RENEWAL"}>Wrong Renewal</MenuItem>
                <MenuItem value={"ONLINE_RENEWAL_CUST"}>Online Renewal Cust</MenuItem>
                <MenuItem value={"ONLINE_RENEWAL_TENANT"}>Online Renewal Tenant</MenuItem>
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                name="comments"
                label={t("comments")}
                control={control}
                fields={validationSchema.fields}
                fullWidth
                variant="outlined"
                errors={errors}
              ></CustomTextField>
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<ReplayIcon />}
              endIcon={isRenewSubmitting ? <CircularProgress size={24} /> : null}
              disabled={false}
            >
              Save
            </Button>

            <Button variant="text" onClick={() => unSetDialog("add_wallet")} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default AddWallet;
