import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Grid, FormControlLabel, Checkbox } from "@mui/material";
import { useFormContext } from "react-hook-form";

import CustomTextField from "../../../../../components/CustomTextField";
import CustomCheckBox from "../../../../../components/CustomCheckBox";
import useTenant from "../../../../../hooks/useTenant";
import useAuth from "../../../../../hooks/useAuth";
import { useTranslation } from "react-i18next";

const MailSetting = (props) => {
  const { user } = useAuth();
  const { validationFields } = props;
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  const { t } = useTranslation();

  return (
    <Grid container spacing={12} className="row" pt={8}>
      <Grid item md={6}>
        <CustomTextField
          label={t("mail_from")}
          name="mailFrom"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>

      <Grid item md={6}>
        <CustomTextField
          label={t("host_name")}
          name="hostName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("port")}
          name="port"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>

      <Grid item md={6}>
        <CustomCheckBox
          label={t("ssl_enabled")}
          name="isSslEnabled"
          type="checkbox"
          my={2}
          fullWidth
          errors={errors}
          fields={validationFields}
        />
        <CustomCheckBox
          label={t("tls_enabled")}
          name="isTlsEnabled"
          type="checkbox"
          my={2}
          fullWidth
          errors={errors}
          fields={validationFields}
        />
      </Grid>

      <Grid item md={6}>
        <CustomTextField
          label={t("userName")}
          name="mailUserName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("password")}
          name="mailPassword"
          type="password"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
    </Grid>
  );
};

export default MailSetting;
