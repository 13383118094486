import React, { useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";

import SaveCancel from "../../../components/SaveCancel";
import useNas from "../../../hooks/useNas";
import NasFields from "./components/NasFields";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const initialValues = {
  nasName: "",
  secret: "",
  nasType: "Mikrotik",
  apiUserName: "",
  apiPassword: "",
  mkApiPort: 8728,
  shortName: "",
  status: "ACTIVE",
  notes: "",
};

const validationSchema = Yup.object().shape({
  nasName: Yup.string().required("Required"),
  secret: Yup.string().required("Required"),
  nasType: Yup.string().required("Required"),
  apiUserName: Yup.string().when("nasType", {
    is: (val) => (val && val == "Mikrotik" ? true : false),
    then: () => Yup.string().required("Api username is required"),
  }),
  apiPassword: Yup.string().when("nasType", {
    is: (val) => (val && val == "Mikrotik" ? true : false),
    then: () => Yup.string().required("Api password is required"),
  }),
  mkApiPort: Yup.string().when("nasType", {
    is: (val) => (val && val == "Mikrotik" ? true : false),
    then: () => Yup.string().required("Api port is required"),
  }),
  shortName: Yup.string()
    .required("Required")
    .matches(
      /^[a-zA-Z0-9_]*$/g,
      "Nas name should be alpha numberic and underscore, there is no space allowed",
    ),
  status: Yup.string().required("Required"),
});

function AddNas() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const { addNas, hasError, errorMessage, isSubmitting } = useNas();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      await addNas(data);
      reset();
      dispatch(showSuccessSnackbar("Nas added successfully!"));
    } catch (error) {
      console.log(error);
      dispatch(showErrorSnackbar(error.message));
    }
  };
  const cancelBtn = () => {
    navigate("/radius/nas/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Radius</Typography>
        <Typography>Nas</Typography>
        <Typography>Add</Typography>
      </Breadcrumbs>
    );
  };
  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_nas")} subheader={breadCrump()} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <NasFields fields={validationSchema.fields} />
                <br></br>
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default AddNas;
