import { useContext } from "react";

import { PaymentGatewayContext } from "../contexts/PaymentGatewayContext";

const usePaymentGateway = () => {
  const context = useContext(PaymentGatewayContext);

  if (!context)
    throw new Error("PaymentGatewayContext must be placed within Payment gateway Provider");

  return context;
};

export default usePaymentGateway;
