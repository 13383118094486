import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, MenuItem } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import useComplaintType from "../../../hooks/useComplaintType";
import SearchableAutoComplete from "../../../components/SearchableAutoComplete";
import FilterReset from "../../../components/FilterReset";
import { searchPayload, convertedValue } from "../../../utils/common";
import { OperatorConstants } from "../../../constants/operator.constants";
import ReactHookFormSelect from "../../../components/ReactHookFormSelect";
import CustomTextField from "../../../components/CustomTextField";
import { useDialog } from "../../../contexts/DialogContext";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const statusOptions = [
  {
    label: "Active",
    value: "A",
  },
  {
    label: "InActive",
    value: "INA",
  },
];

function AdvanceFilter(props) {
  const { unSetDialog } = useDialog();
  const initialValues = {
    complaintTypeOperator: "eq",
    statusOperator: "eq",
    complaintType: [],
    status: [],
  };

  const { fields, payload, setPayload, setFilterResetFunc } = props;

  const { autoSearchComplaintType, complaintTypeAutoSearchResults, isLoadingAutoSearch } =
    useComplaintType();

  const [complaintTypePayload, setComplaintTypePayload] = useState(currentPayload);
  const [complaintTypeOptions, setComplaintTypeOptions] = useState([]);
  const [statusMultiValue, setStatusMultiValue] = useState(false);

  const [complaintTypeMultiValue, setComplaintTypeMultiValue] = useState(false);
  const [defaultComplaintType, setDefaultComplaintType] = useState([]);
  const [defaultStatus, setDefaultStatus] = useState([]);

  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = methods;

  useEffect(() => {
    setFilterResetFunc(() => reset);
  }, [reset]);

  const complaintTypeOpValue = watch("complaintTypeOperator");
  const statusOpValue = watch("statusOperator");

  const onSubmit = async (data) => {
    try {
      let filters = [];
      if (data.complaintType != null && Object.keys(data.complaintType).length != 0) {
        filters.push({
          fieldName: "complaintTypeId",
          fieldValue: convertedValue(data.complaintType, "value").join(","),
          op: data.complaintTypeOperator,
          dataType: "int",
          condition: "and",
          isJoin: true,
          entityName: "ComplaintTypeEntity",
        });
      }
      if (data.status != "" && data.status != null) {
        filters.push({
          fieldName: "status",
          fieldValue: convertedValue(data.status, "value").join(","),
          op: data.statusOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }
      setPayload({
        ...payload,
        filters: filters,
      });
      unSetDialog("advance_filter");
    } catch (err) {
      console.log(err, "errerrerrerr");
    }
  };

  useEffect(() => {
    if (complaintTypeOpValue == "in" || complaintTypeOpValue == "nin") {
      setDefaultComplaintType([]);
      setValue("complaintType", []);
      setComplaintTypeMultiValue(true);
    } else {
      setDefaultComplaintType({});
      setValue("complaintTypeNae", {});
      setComplaintTypeMultiValue(false);
    }
  }, [complaintTypeOpValue]);

  useEffect(() => {
    if (statusOpValue == "in" || statusOpValue == "nin") {
      setDefaultStatus([]);
      setValue("status", []);
      setStatusMultiValue(true);
    } else {
      setDefaultStatus({});
      setValue("status", {});
      setStatusMultiValue(false);
    }
  }, [statusOpValue]);

  const fetchComplaintType = async () => {
    await autoSearchComplaintType(complaintTypePayload);
  };
  useEffect(() => {
    fetchComplaintType();
  }, [complaintTypePayload]);

  useEffect(() => {
    if (complaintTypeAutoSearchResults.data.length !== 0) {
      setComplaintTypeOptions([...complaintTypeOptions, ...complaintTypeAutoSearchResults.data]);
    }
  }, [complaintTypeAutoSearchResults]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} pt={8}>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <ReactHookFormSelect
                name="complaintTypeOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <SearchableAutoComplete
                control={control}
                data={complaintTypeOptions}
                label="Complaint Type"
                payload={complaintTypePayload}
                setPayload={setComplaintTypePayload}
                loading={isLoadingAutoSearch}
                name="complaintType"
                multiple={complaintTypeMultiValue}
                defaultValue={defaultComplaintType}
              />
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <ReactHookFormSelect
                name="statusOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((t, k) => (
                  <MenuItem key={k} value={t[0]}>
                    {t[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <SearchableAutoComplete
                control={control}
                data={statusOptions}
                label="Status"
                payload={null}
                setPayload={null}
                loading={false}
                name="status"
                multiple={statusMultiValue}
                defaultValue={defaultStatus}
              />
            </Grid>
          </Grid>
          <FilterReset resetForm={reset} />
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default AdvanceFilter;
