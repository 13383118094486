import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, ButtonBase, Typography, Button, Tabs, Tab, Box, Paper, Avatar } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import {
  Refresh,
  Edit as EditIcon,
  PublishedWithChanges as PublishedWithChangesIcon,
  Password as PasswordIcon,
  WifiPassword as WifiPasswordIcon,
  Call as CallIcon,
  Mail as MailIcon,
  MobileFriendly as MobileFriendlyIcon,
  Person as PersonIcon,
  CurrencyExchangeRounded as CurrencyExchangeRoundedIcon,
  PendingActions as PendingActionsIcon,
  Payments as PaymentsIcon,
  CreditScore as CreditScoreIcon,
} from "@mui/icons-material";
import { a11yProps } from "../../../utils/common";
import TabPanel from "../../../components/TabPanel";
import useUser from "../../../hooks/useUser";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import BasicDetails from "./BasicDetails";
import PlanDetails from "./PlanDetails";
import ProfileImg from "../../icons/profile_img.png";
import ChangeAppPassword from "../userlist/components/ChangeAppPass";
import useScheduleUser from "../../../hooks/useScheduleUser";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import ActivateUser from "./ActivateUser";
import DeactiveUser from "./DeactivateUser";
import HotelBasicDetails from "./HotelBasicDetails";
import HotelPlanDetails from "./HotelPlanDetails";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShowPassword from "../userlist/components/ShowPass";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [
    {
      field: "createdAt",
      sort: "DESC",
    },
  ],
};

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const useTabStyles = styled({
  root: {
    justifyContent: "center",
    width: "1000px",
  },
  scroller: {
    flexGrow: "0",
  },
});

function HotelUser() {
  const classes = useTabStyles();
  let [searchParams, setSearchParams] = useSearchParams();
  const { getUser, user, getUserDetailsInvoice, userDetailsInvoice } = useUser();
  const { getActiveScheduleUser, scheduleUsers } = useScheduleUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openDialog } = useDialog();

  const [payload, setPayload] = useState(currentPayload);

  const [selectedRows, setSelectedRows] = useState([]);

  console.log(scheduleUsers, "scheduleuserrrss");

  const fetchData = async () => {
    await getUser(searchParams.get("id"));
  };

  const fetchScheduleData = async () => {
    await getActiveScheduleUser(searchParams.get("id"));
  };

  const [filterResetFunc, setFilterResetFunc] = useState(
    () => () => console.log("Refresh initialized"),
  );

  const refreshPage = () => {
    setPayload(currentPayload);
    fetchData();
    filterResetFunc();
  };

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (searchParams && searchParams.get("id")) {
      const currentPayload = {
        filters: [
          {
            fieldName: "radUserId",
            fieldValue: searchParams.get("id"),
            op: "eq",
            dataType: "string",
            condition: "and",
            isJoin: false,
          },
        ],
        pagination: {
          pageNumber: 1,
          pageSize: 100,
        },
        sortFields: [],
      };
      fetchData();
      fetchScheduleData(currentPayload);
      // fetchUserDetailInvoice(currentPayload);
    }
  }, [searchParams]);

  const showPass = (id) => {
    return openDialog(
      "show_password",
      "Show Password",
      <ShowPassword key={getTimeStamp()} radUserId={searchParams.get("id")} />,
    );
  };

  const addActive = (id) => {
    return openDialog(
      "dective_user",
      "Active User",
      <ActivateUser
        key={getTimeStamp()}
        userId={searchParams.get("id")}
        selectedRows={selectedRows}
        refreshPage={refreshPage}
      />,
    );
  };

  const deActivateUser = () => {
    return openDialog(
      "deactive_user",
      "Deactive User",
      <DeactiveUser
        key={getTimeStamp()}
        selectedRows={[searchParams.get("id")]}
        refreshPage={refreshPage}
      />,
    );
  };

  return (
    <React.Fragment>
      {/* <Paper
        sx={{
          p: 2,
          margin: "auto",
          flexGrow: 1,
          backgroundColor: (theme) => (theme.palette.mode === "dark" ? "#1A2027" : "#fff"),
        }}
      > */}
      <Grid container spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="row" spacing={1}>
              <Grid item>
                <Button variant="contained" startIcon={<Refresh />} onClick={() => refreshPage()}>
                  Refresh
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<VisibilityIcon />}
                  onClick={() => showPass()}
                >
                  Show Password
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" startIcon={<DoneAllIcon />} onClick={() => addActive()}>
                  Active
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<RemoveDoneIcon />}
                  onClick={() => deActivateUser()}
                >
                  DeActive
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            flexGrow: 1,

            marginTop: 4,
            width: "100%",
          }}
        >
          <Tabs
            classes={{ scroller: classes.scroller }}
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            aria-label="Company"
            scrollButtons
            allowScrollButtonsMobile
            sx={{
              justifyContent: "center",
              width: "1000px",
            }}
          >
            <Tab label="Basic Details" {...a11yProps(0)} />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl>
                <HotelBasicDetails user={user} />
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
      </Grid>
      {/* </Paper> */}
    </React.Fragment>
  );
}

export default HotelUser;
