import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import SettingReducer from "../reducers/SettingReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { SettingConstants } from "../constants/setting.constants";
import { saveFile } from "../utils/common";
const initialState = {
  settingEntries: [],
  tenantEmailTemplates: [],
  tenantSmsTemplates: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  settingSearchResults: [],
  tenantSetting: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  paymentTrans: {
    actionUrl: "",
  },
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const SettingContext = createContext({
  ...initialState,
  addSetting: () => {},
  searchSetting: () => {},
  getSetting: () => {},
  updateSetting: () => {},
  getAllTenantEmailTemplates: () => {},
  getAllTenantSmsTemplates: () => {},
  getTenantSetting: () => {},
  updateEmailSetting: () => {},
  updateSmsSetting: () => {},
  updateTenantSetting: () => {},
});

function SettingProvider({ children }) {
  const [state, dispatch] = useReducer(SettingReducer, initialState);

  const searchSetting = async () => {
    try {
      const response = await axios.post("/settings/general/search", initPayload);
      dispatch({
        type: SettingConstants.WALLET_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: SettingConstants.WALLET_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: SettingConstants.WALLET_SEARCH_FAILURE,
      });
    }
  };
  const getAllTenantEmailTemplates = async (payload) => {
    try {
      const response = await axios.post("/cpanel/tenant/email-templates", payload);
      dispatch({
        type: SettingConstants.GET_EMAIL_SETTING_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: SettingConstants.GET_EMAIL_SETTING_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: SettingConstants.GET_EMAIL_SETTING_FAILURE,
      });
    }
  };
  const getAllTenantSmsTemplates = async (payload) => {
    try {
      const response = await axios.post("/cpanel/tenant/sms-templates", payload);
      dispatch({
        type: SettingConstants.GET_SMS_SETTING_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: SettingConstants.GET_SMS_SETTING_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: SettingConstants.GET_SMS_SETTING_FAILURE,
      });
    }
  };
  const addSetting = async (payload) => {
    const response = await axios.post("/settings/general/add", payload);
    dispatch({
      type: SettingConstants.ADD_WALLET_REQUEST,
    });
    dispatch({
      type: SettingConstants.ADD_WALLET_SUCCESS,
      payload: response.data,
    });
  };

  const getSetting = async (id) => {
    const response = await axios.get("/settings/general/" + id);
    dispatch({
      type: SettingConstants.GET_WALLET_REQUEST,
    });
    dispatch({
      type: SettingConstants.GET_WALLET_SUCCESS,
      payload: response.data,
    });
  };

  const updateTenantSetting = async (payload) => {
    const response = await axios.put("/cpanel/tenant/settings/update", payload);
    dispatch({
      type: SettingConstants.UPDATE_FRANCHISE_SETTING_REQUEST,
    });
    dispatch({
      type: SettingConstants.UPDATE_FRANCHISE_SETTING_SUCCESS,
      payload: response.data,
    });
  };

  const updateEmailSetting = async (payload) => {
    const response = await axios.put("/cpanel/tenant/email-templates/update", payload);
    dispatch({
      type: SettingConstants.UPDATE_EMAIL_TEMPLATE_REQUEST,
    });
    dispatch({
      type: SettingConstants.UPDATE_EMAIL_TEMPLATE_SUCCESS,
      payload: response.data,
    });
  };
  const updateSmsSetting = async (payload) => {
    const response = await axios.put("/cpanel/tenant/sms-templates/update", payload);
    dispatch({
      type: SettingConstants.UPDATE_SMS_TEMPLATE_REQUEST,
    });
    dispatch({
      type: SettingConstants.UPDATE_SMS_TEMPLATE_SUCCESS,
      payload: response.data,
    });
  };
  const getTenantSetting = async (tenantId) => {
    const response = await axios.get("/cpanel/tenant/settings/" + tenantId);
    dispatch({
      type: SettingConstants.GET_FRANCHISE_SETTING_REQUEST,
    });
    dispatch({
      type: SettingConstants.GET_FRANCHISE_SETTING_SUCCESS,
      payload: response.data,
    });
  };

  return (
    <SettingContext.Provider
      value={{
        ...state,
        addSetting,
        searchSetting,
        getSetting,
        getAllTenantEmailTemplates,
        getAllTenantSmsTemplates,
        updateEmailSetting,
        updateSmsSetting,
        getTenantSetting,
        updateTenantSetting,
      }}
    >
      {children}
    </SettingContext.Provider>
  );
}

export { SettingContext, SettingProvider };
