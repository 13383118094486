import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomTextField from "../../../../components/CustomTextField";
import CustomCheckBox from "../../../../components/CustomCheckBox";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";

const AddonDetail = (props) => {
  const { t } = useTranslation();

  const { validationFields } = props;
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();
  return (
    <Grid container spacing={12} className="row" pt={8}>
      <Grid item md={6}>
        <CustomTextField
          label={t("addon_name")}
          name="addOnName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("total_limit")}
          name="totalLimit"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("price")}
          name="price"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>

      <Grid item md={6}>
        <CustomCheckBox
          label={t("tax_enabled")}
          name="taxEnabled"
          type="checkbox"
          my={2}
          control={control}
          fullWidth
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item xs={9.5} sm={12} md={6} lg={6} xl={6}>
        <ReactHookFormSelect
          id="taxType"
          name="taxType"
          label={t("tax_type")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          <MenuItem value={"INCLUDING_TAX"}>Including Tax</MenuItem>
          <MenuItem value={"EXCLUDING_TAX"}>Excluding Tax</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      <Grid item xs={9.5} sm={12} md={6} lg={6} xl={6}>
        <ReactHookFormSelect
          id="status"
          name="status"
          label={t("Status")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          <MenuItem value={"ACTIVE"}>Active</MenuItem>
          <MenuItem value={"INACTIVE"}>Inactive</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          type={"text"}
          id="notes"
          name="notes"
          label={t("notes")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
    </Grid>
  );
};

export default AddonDetail;
