import React, { useEffect, useState, useCallback } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";

import CustomTextField from "./CustomTextField";
import ReactHookFormSelect from "./ReactHookFormSelect";
import { useTranslation } from "react-i18next";
import useVillage from "../hooks/useVillage";
import usePincode from "../hooks/usePincode";
import useCity from "../hooks/useCity";
import useTaluk from "../hooks/useTaluk";
import useDistrict from "../hooks/useDistrict";
import useCountry from "../hooks/useCountry";
import useStateMaster from "../hooks/useStateMaster";
import { searchPayloadEqInt } from "../utils/common";
import CustomCheckBox from "../components/CustomCheckBox";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const InstallationAddressDetail = ({ presentAddressFields, validationFields, isEdit }) => {
  const {
    register,
    watch,
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  const sameAsPresentAddress = watch("sameAsPresentAddress");

  const { t } = useTranslation();

  const { searchTaluk, talukSearchResults } = useTaluk();
  const { searchDistrict, districtSearchResults } = useDistrict();
  const { searchState, stateSearchResults } = useStateMaster();
  const { searchCountry, countrySearchResults } = useCountry();

  useEffect(() => {
    if (!isEdit) {
      if (sameAsPresentAddress) {
        setValue("insDoorNo", presentAddressFields[0]);
        setValue("insStreetName", presentAddressFields[1]);
        setValue("insPinCode", presentAddressFields[2]);
        setValue("insVillageName", presentAddressFields[3]);
        setValue("insTownName", presentAddressFields[4]);
        setValue("insTalukId", presentAddressFields[5]);
        setValue("insDistrictId", presentAddressFields[6]);
        setValue("insStateId", presentAddressFields[7]);
        setValue("insCountryId", presentAddressFields[8]);
      } else {
        setValue("insDoorNo", "");
        setValue("insStreetName", "");
        setValue("insPinCode", "");
        setValue("insVillageName", "");
        setValue("insTownName", "");
        setValue("insTalukId", "");
        setValue("insDistrictId", "");
        setValue("insStateId", "");
        setValue("insCountryId", "");
      }
    }
  }, [sameAsPresentAddress]);

  const fetchData = async () => {
    await searchTaluk(currentPayload);
    await searchCountry(276);
  };

  const handleDataFetching = (id, data, callback) => {
    const value = data.find((item) => item.id === id);
    if (value) {
      callback(value);
    }
  };

  const fetchDistricts = async (selectedDistrictId) => {
    if (selectedDistrictId) {
      searchPayloadValue = searchPayloadEqInt("districtId", selectedDistrictId, false);
      await searchDistrict(searchPayloadValue);
    }
  };

  const fetchState = async (selectedStateId) => {
    if (selectedStateId) {
      searchPayloadValue = searchPayloadEqInt("stateId", selectedStateId, false);
      await searchState(searchPayloadValue);
    }
  };

  const fetchCountry = async (selectedCountryId) => {
    if (selectedCountryId) {
      searchPayloadValue = searchPayloadEqInt("countryId", selectedCountryId, false);
      await searchCountry(searchPayloadValue);
    }
  };

  useEffect(() => {
    handleDataFetching(watch("talukId"), talukSearchResults, (value) => {
      fetchDistricts(value.districtId);
    });
  }, [watch("talukId"), talukSearchResults]);

  useEffect(() => {
    handleDataFetching(watch("districtId"), districtSearchResults, (value) => {
      fetchState(value.stateId);
    });
  }, [watch("districtId"), districtSearchResults]);

  useEffect(() => {
    handleDataFetching(watch("stateId"), stateSearchResults, (value) => {
      fetchCountry(value.countryId);
    });
  }, [watch("stateId"), stateSearchResults]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid container spacing={12} className="row" pt={8}>
      {!isEdit && (
        <Grid item md={12}>
          <CustomCheckBox
            label="Same as present address"
            name="sameAsPresentAddress"
            type="checkbox"
            my={2}
            fullWidth
            errors={errors}
            fields={validationFields}
          />
        </Grid>
      )}

      <Grid item md={6}>
        <CustomTextField
          label={t("door_no")}
          name="doorNo"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("street_name")}
          name="streetName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("pincode_name")}
          name="pinCode"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
        {/* <ReactHookFormSelect
          id="pinCode"
          name="pinCode"
          label="Pincode"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        >
          {pincodeSearchResults &&
            pincodeSearchResults.map((pinCode) => (
              <MenuItem key={pinCode.pincodeName} value={pinCode.pincodeId}>
                {pinCode.pincodeName}
              </MenuItem>
            ))}
        </ReactHookFormSelect> */}
      </Grid>

      <Grid item md={6}>
        {/* <ReactHookFormSelect
          id="villageId"
          name="villageId"
          label="Village"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        >
          {villages &&
            villages.map((village) => (
              <MenuItem key={village.villageId} value={village.villageId}>
                {village.villageName}
              </MenuItem>
            ))}
        </ReactHookFormSelect> */}
        <CustomTextField
          label={t("village_name")}
          name="villageName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        {/* <ReactHookFormSelect
          id="cityId"
          name="cityId"
          label="City"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        >
          {citySearchResults &&
            citySearchResults.map((city) => (
              <MenuItem key={city.cityId} value={city.cityId}>
                {city.cityName}
              </MenuItem>
            ))}
        </ReactHookFormSelect> */}
        <CustomTextField
          label={t("town_name")}
          name="townName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item xs={10} sm={12} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          id="talukId"
          name="talukId"
          label={t("taluk_name")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          {talukSearchResults &&
            talukSearchResults.map((taluk) => (
              <MenuItem key={taluk.talukId} value={taluk.talukId}>
                {taluk.talukName}
              </MenuItem>
            ))}
        </ReactHookFormSelect>
      </Grid>
      <Grid item xs={10} sm={12} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          id="districtId"
          name="districtId"
          label={t("district_name")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          {districtSearchResults &&
            districtSearchResults.map((district) => (
              <MenuItem key={district.districtId} value={district.districtId}>
                {district.districtName}
              </MenuItem>
            ))}
        </ReactHookFormSelect>
      </Grid>
      <Grid item xs={10} sm={12} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          id="stateId"
          name="stateId"
          label={t("state_name")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          {stateSearchResults &&
            stateSearchResults.map((state) => (
              <MenuItem key={state.stateId} value={state.stateId}>
                {state.stateName}
              </MenuItem>
            ))}
        </ReactHookFormSelect>
      </Grid>
      <Grid item xs={10} sm={12} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          id="countryId"
          name="countryId"
          label={t("country_name")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          {countrySearchResults &&
            countrySearchResults.map((country) => (
              <MenuItem key={country.countryId} value={country.countryId}>
                {country.countryName}
              </MenuItem>
            ))}
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
};

export default InstallationAddressDetail;
