import { ReportConstants } from "../constants/report.constants";

const ReportReducer = (state, action) => {
  switch (action.type) {
    case ReportConstants.DOWNLOAD_USER_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case ReportConstants.DOWNLOAD_USER_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case ReportConstants.DOWNLOAD_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };

    case ReportConstants.FETCH_RENEWAL_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ReportConstants.FETCH_RENEWAL_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        renewalReports: action.payload,
      };
    case ReportConstants.FETCH_RENEWAL_REPORT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ReportConstants.FETCH_PAYMENT_GATEWAY_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ReportConstants.FETCH_PAYMENT_GATEWAY_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentGateways: action.payload,
      };
    case ReportConstants.FETCH_PAYMENT_GATEWAY_REPORT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ReportConstants.FETCH_DEPOSIT_PAYMENT_GATEWAY_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ReportConstants.FETCH_DEPOSIT_PAYMENT_GATEWAY_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        depositPaymentGateways: action.payload,
      };
    case ReportConstants.FETCH_DEPOSIT_PAYMENT_GATEWAY_REPORT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ReportConstants.FETCH_DUE_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ReportConstants.FETCH_DUE_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dueReports: action.payload,
      };
    case ReportConstants.FETCH_DUE_REPORT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case ReportConstants.FETCH_COLLECTION_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ReportConstants.FETCH_COLLECTION_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        collectionReports: action.payload,
      };
    case ReportConstants.FETCH_COLLECTION_REPORT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case ReportConstants.FETCH_WALLET_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ReportConstants.FETCH_WALLET_SUCCESS:
      console.log(action.payload, "action.payload");
      return {
        ...state,
        isLoading: false,
        walletReports: action.payload,
      };
    case ReportConstants.FETCH_WALLET_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case ReportConstants.FETCH_SHARING_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case ReportConstants.FETCH_SHARING_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sharingReports: action.payload,
      };
    case ReportConstants.FETCH_SHARING_REPORT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default ReportReducer;
