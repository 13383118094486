import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";

import SaveCancel from "../../../components/SaveCancel";
import SelectAsyncPaginate from "../../../components/SelectAsyncPaginate";
import useAddon from "../../../hooks/useAddon";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import AddonDetail from "./components/AddonDetail";
import AllowedTenants from "./components/AllowedTenants";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const validationSchema = Yup.object().shape({
  addOnName: Yup.string().required("Required"),
  totalLimit: Yup.number()
    .typeError("Total limit must be a number")
    .required("ERROR: The number is required!")
    .test("Is positive?", "ERROR: The number must be greater than 0!", (value) => value > 0),
  price: Yup.number()
    .typeError("Total limit must be a number")
    .required("ERROR: The number is required!")
    .test("Is positive?", "ERROR: The number must be greater than 0!", (value) => value > 0),
  taxEnabled: Yup.boolean().required("Required"),
  taxType: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

function BasicForm(props) {
  const form = React.useRef();
  const dispatch = useDispatch();

  const { getAddon, addon, updateAddon, isSubmitting } = useAddon();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  React.useEffect(() => {
    if (Object.keys(addon).length !== 0) {
      const initialValues = {
        addOnId: addon.addOnId,
        addOnName: addon.addOnName,
        totalLimit: addon.totalLimit,
        price: addon.price,
        taxEnabled: addon.taxEnabled,
        taxType: addon.taxType,
        notes: addon.notes,
        status: addon.status,
      };
      reset(initialValues);
    }
  }, [addon]);

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const [value, setValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const { state } = useLocation();
  console.log(state, "location");
  const { t } = props;

  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      await updateAddon(data);
      dispatch(showSuccessSnackbar("Addon updated successfully!"));
      cancelBtn();
    } catch (error) {
      dispatch(showErrorSnackbar("Failed to update addon"));
    }
  };
  const cancelBtn = () => {
    navigate("/radius/addon/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Radius</Typography>
        <Typography>Addon</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getAddon(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_addon")} subheader={breadCrump()} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={value} onChange={handleTabChange} aria-label="Addon">
                    <Tab label="Addon Details" {...a11yProps(0)} />
                    <Tab label="Allowed Tenants" {...a11yProps(1)} />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <AddonDetail validationFields={validationSchema.fields} />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <AllowedTenants addOnId={state?.id} />
                  </TabPanel>
                </Box>

                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

function EditAddon() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <BasicForm t={t} />
    </React.Fragment>
  );
}

export default EditAddon;
