import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Grid, FormControlLabel, Checkbox } from "@mui/material";
import { useFormContext, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import useAuth from "../../../../../hooks/useAuth";
import CustomCheckBox from "../../../../../components/CustomCheckBox";

const Common = (props) => {
  const { user } = useAuth();
  const { validationFields } = props;
  const {
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  return (
    <Grid container spacing={12} className="row" pt={8} sx={{ paddingLeft: "16px" }}>
      <Grid item md={4}>
        <CustomCheckBox
          label={t("email_notification")}
          name="isEmailEnabled"
          type="checkbox"
          my={2}
          fullWidth
          errors={errors}
          fields={validationFields}
        />
      </Grid>

      <Grid item md={4}>
        <CustomCheckBox
          label="Sms Notification"
          name="isSmsEnabled"
          type="checkbox"
          my={2}
          fullWidth
          errors={errors}
          fields={validationFields}
        />
      </Grid>
    </Grid>
  );
};

export default Common;
