import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import SendIcon from "@mui/icons-material/Send";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import { useDialog } from "../../../../contexts/DialogContext";
import CustomTextField from "../../../../components/CustomTextField";
import { useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import {
  Grid,
  Divider as MuiDivider,
  Box,
  DialogActions,
  Button,
  CircularProgress,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

import TextField from "@mui/material/TextField";
import { useForm, FormProvider } from "react-hook-form";
import useEmailToUser from "../../../../hooks/useEmailToUser";
const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const validationSchema = Yup.object().shape({
  tenantName: Yup.string().required("Required"),
  subject: Yup.string().required("Required"),
  mail: Yup.string().required("Required"),
});

function SendMail(props) {
  const { selectedRows } = props;

  console.log(selectedRows, "sellllll");

  const { addEmailToUser, isRenewSubmitting } = useEmailToUser();
  const dispatch = useDispatch();
  const { unSetDialog, openDialog } = useDialog();

  console.log(selectedRows, "selecttrroowwww");

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    try {
      const tenantIds = selectedRows.map(function (data) {
        return data["tenantId"];
      });
      data["tenantId"] = tenantIds.join(",");
      await addEmailToUser(data);
      dispatch(showSuccessSnackbar("Message Sent successfully!"));
    } catch (err) {
      dispatch(showErrorSnackbar("Failed Message Send"));
    }
  };

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  useEffect(() => {
    if (selectedRows.length != 0) {
      const tenantNames = selectedRows.map(function (data) {
        return data["tenantName"];
      });

      const initialValues = {
        tenantName: tenantNames.join(","),
      };
      reset(initialValues);
      console.log(tenantNames, "selectedRowsselectedRowsselectedRows");
    }
  }, [selectedRows]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} className="row" pt={8}>
            <Grid item md={6}>
              <CustomTextField
                label="Franchise Name"
                name="tenantName"
                control={control}
                disabled={true}
                fields={validationSchema.fields}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                name="subject"
                label="Subject"
                control={control}
                fullWidth
                fields={validationSchema.fields}
                variant="outlined"
                errors={errors}
              ></CustomTextField>
            </Grid>
            <Grid item md={12}>
              <CustomTextField
                label="Mail here"
                name="mail"
                control={control}
                fields={validationSchema.fields}
                fullWidth
                variant="outlined"
                errors={errors}
                rows={6}
                multiline={true}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SendIcon />}
              endIcon={isRenewSubmitting ? <CircularProgress size={24} /> : null}
              disabled={false}
            >
              Send
            </Button>

            <Button variant="text" onClick={() => unSetDialog("send_mail")} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default SendMail;
