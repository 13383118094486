import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  MenuItem,
  Tabs,
  Tab,
  Button,
} from "@mui/material";

import CustomDataGrid from "./CustomDataGrid";
import useUser from "../../../hooks/useUser";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import CustomFileField from "../../../components/CustomFileField";
import SaveReset from "../../../components/SaveReset";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const validationSchema = Yup.object().shape({
  file: Yup.mixed()
    .required("A file is required")
    .test("fileFormat", "Only support .xlsx file", (value) => {
      console.log(value);
      return (
        value &&
        ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].includes(value.type)
      );
    }),
});
const initialValues = {
  file: null,
};

function Migration() {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { uploadMessage, uploadFile, isUploading, isLoadingDownload, sampleFile } = useUser();
  const navigate = useNavigate();
  const { openDialog } = useDialog();

  const { t } = useTranslation();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = methods;

  console.log(errors, "errorserrors1111");

  const currentPayload = {
    filters: [
      {
        fieldName: "acctStopTime",
        fieldValue: null,
        op: "eq",
        dataType: "date",
        condition: "and",
        isJoin: false,
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);

  const [payload, setPayload] = useState(currentPayload);
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async (data) => {
    try {
      let formData = new FormData();
      formData.append("file", data.file);
      await uploadFile(formData);
      if (uploadMessage == "") {
        dispatch(showSuccessSnackbar("User uploaded successfully!"));
      }
      fileInputRef.current.value = null;
      reset();
    } catch (err) {
      console.log(err, "errerrerr");
      fileInputRef.current.value = null;
      reset();
      setErrorMessage(err.message);
    }
  };
  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Migration</Typography>

        <Typography>Users</Typography>
      </Breadcrumbs>
    );
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("migration")} subheader={breadCrump()} />
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={6}>
                <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
                  <CustomFileField
                    label={t("upload")}
                    name="file"
                    control={control}
                    fullWidth
                    variant="outlined"
                    errors={errors}
                    fields={validationSchema.fields}
                    ref={fileInputRef}
                  />
                </Grid>
                <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
                  <Button
                    disabled={isLoadingDownload}
                    variant="contained"
                    color="primary"
                    onClick={() => sampleFile("xlsx")}
                    endIcon={isLoadingDownload ? <CircularProgress size={24} /> : null}
                  >
                    Download Sample File
                  </Button>
                </Grid>
              </Grid>
              <br></br>

              <SaveReset resetForm={reset} loading={isUploading} />
            </form>
          </CardContent>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Typography>{uploadMessage}</Typography>
          </Box>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default Migration;
