import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { BulkOperationProvider } from "../contexts/BulkOpeationContext";
import { DialogProvider } from "../contexts/DialogContext";
import { UserProvider } from "../contexts/UserContext";

export const BulkOperationComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <BulkOperationProvider key={0} />,
        <UserProvider key={1} />,
        <DialogProvider key={2} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
