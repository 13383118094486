//Users
import EditComplaint from "../pages/complaint/edit.js";
import ComplaintType from "../pages/complaint_type/index.js";
import AddComplaintType from "../pages/complaint_type/add.js";
import Complaint from "../pages/complaint/index.js";
import AddComplaint from "../pages/complaint/add.js";
import ComplaintStats from "../pages/complaint/complaintstats.js";
import ComplaintClosed from "../pages/complaint/complaint_closed.js";
import ComplaintDetails from "../pages/complaint/complaintdetails.js";
import EditcomplaintType from "../pages/complaint_type/edit.js";
//Composers

import { ComplaintTypeComposer } from "../composers/ComplaintTypeComposer";
import { DashboardComposer } from "../composers/DashboardComposer.js";
import { ComplaintComposer } from "../composers/ComplaintComposer";

// Guards
import AuthGuard from "../components/guards/AuthGuard.js";
import DashboardLayout from "../layouts/Dashboard.js";
import ProtectedLayout from "../layouts/ProtectedLayout";
// import EditcomplaintType from "../pages/complaint_type/edit.js";

const ComplaintRoutes = {
  path: "complaints",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  children: [
    {
      children: [
        {
          path: "add",
          element: (
            <ComplaintComposer>
              <ProtectedLayout>
                <AddComplaint />
              </ProtectedLayout>
            </ComplaintComposer>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <ComplaintComposer>
                <EditComplaint />
              </ComplaintComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <ComplaintComposer>
                <Complaint />
              </ComplaintComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "stats",
          element: (
            <ProtectedLayout>
              <ComplaintComposer>
                <ComplaintStats />
              </ComplaintComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "closed",
          element: (
            <ProtectedLayout>
              <ComplaintComposer>
                <ComplaintClosed />
              </ComplaintComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "details",
          element: (
            <ProtectedLayout>
              <ComplaintComposer>
                <ComplaintDetails />
              </ComplaintComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "type",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <ComplaintTypeComposer>
                <AddComplaintType />
              </ComplaintTypeComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <ComplaintTypeComposer>
                <EditcomplaintType />
              </ComplaintTypeComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <ComplaintTypeComposer>
                <ComplaintType />
              </ComplaintTypeComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "type",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <ComplaintComposer>
                <AddComplaint />
              </ComplaintComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <ComplaintComposer>
                <EditcomplaintType />
              </ComplaintComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <ComplaintComposer>
                <Complaint />
              </ComplaintComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
  ],
};

export default ComplaintRoutes;
