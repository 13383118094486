import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { TenantProvider } from "../contexts/TenantContext";
import { DialogProvider } from "../contexts/DialogContext";
import { FormProvider } from "../contexts/TenantFormContext";
import { StateMasterProvider } from "../contexts/StateMasterContext";
import { DistrictProvider } from "../contexts/DistrictContext";
import { CountryProvider } from "../contexts/CountryContext";
import { TalukProvider } from "../contexts/TalukContext";
import { CityProvider } from "../contexts/CityContext";
import { VillageProvider } from "../contexts/VillageContext";
import { PincodeProvider } from "../contexts/PincodeContext";
import { PlanProvider } from "../contexts/PlanContext";
import { IppoolProvider } from "../contexts/IppoolContext";
import { IspProvider } from "../contexts/IspContext";
import { IpTvProvider } from "../contexts/IpTvContext";
import { PlanGroupProvider } from "../contexts/PlanGroupContext";
import { PaymentGatewayProvider } from "../contexts/PaymentGatewayContext";

export const TenantComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <TenantProvider key={0} />,
        <FormProvider key={1} />,
        <PincodeProvider key={2} />,
        <VillageProvider key={3} />,
        <CityProvider key={4} />,
        <TalukProvider key={5} />,
        <DistrictProvider key={6} />,
        <CountryProvider key={7} />,
        <StateMasterProvider key={8} />,
        <PlanProvider key={9} />,
        <IppoolProvider key={10} />,
        <IspProvider key={11} />,
        <IpTvProvider key={12} />,
        <PlanGroupProvider key={13} />,
        <PaymentGatewayProvider key={14} />,
        <DialogProvider key={15} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
