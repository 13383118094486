//Users
import UnpaidInvoice from "../pages/invoices/unpaid_invoices/index.js";
import PaidInvoice from "../pages/invoices/paid_invoices/index.js";
//Composers
import UnpaidInvoiceComposer from "../composers/UnpaidInvoiceComposer.js";
import PaidInvoiceComposer from "../composers/PaidInvoiceComposer.js";
import { DashboardComposer } from "../composers/DashboardComposer.js";
// Guards
import AuthGuard from "../components/guards/AuthGuard.js";
import DashboardLayout from "../layouts/Dashboard.js";
import ProtectedLayout from "../layouts/ProtectedLayout";

const InvoiceRoutes = {
  path: "invoices",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  roles: ["SUPER_ADMIN"],
  children: [
    {
      path: "paid",
      element: (
        <ProtectedLayout>
          <PaidInvoiceComposer>
            <PaidInvoice />
          </PaidInvoiceComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "unpaid",
      element: (
        <ProtectedLayout>
          <UnpaidInvoiceComposer>
            <UnpaidInvoice />
          </UnpaidInvoiceComposer>
        </ProtectedLayout>
      ),
    },
  ],
};

export default InvoiceRoutes;
