import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";
import { useFormContext } from "react-hook-form";
import CustomDatePicker from "../../../components/CustomDatePicker";

import CustomTextField from "../../../components/CustomTextField";
import ReactHookFormSelect from "../../../components/ReactHookFormSelect";
import SearchableAutoComplete from "../../../components/SearchableAutoComplete";
import useTenant from "../../../hooks/useTenant";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

function AgreementTrackerFields(props) {
  const { t } = useTranslation();
  const { fields } = props;
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { autoSearchTenant, tenantAutoSearchResults } = useTenant();

  const [tenantOptions, setTenantOptions] = useState([]);
  const [tenantPayload, setTenantPayload] = useState(currentPayload);

  const fetchTenant = async () => {
    await autoSearchTenant(tenantPayload);
  };
  useEffect(() => {
    fetchTenant();
  }, [tenantPayload]);

  useEffect(() => {
    console.log(tenantAutoSearchResults, "tenantAutoSearchResults");
    if (tenantAutoSearchResults && tenantAutoSearchResults.data.length !== 0) {
      const results = [...tenantOptions, ...tenantAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setTenantOptions(filteredResults);
    }
  }, [tenantAutoSearchResults]);

  return (
    <Grid container spacing={6} className="row">
      <Grid item xs={7.5} sm={6} md={6} lg={6} xl={4}>
        <SearchableAutoComplete
          name="tenant"
          label={t("franchise_name")}
          control={control}
          data={tenantOptions}
          payload={tenantPayload}
          setPayload={setTenantPayload}
          loading={false}
          multiple={false}
          defaultValue={""}
          hasNextPage={false}
          fields={fields}
          fullWidth
          variant="outlined"
          errors={errors}
        ></SearchableAutoComplete>
      </Grid>
      <Grid item md={6}>
        <CustomDatePicker
          name="dateOfAgreement"
          nameTemp="dateOfAgreementStr"
          label={t("date_of_agreement")}
          range={false}
          control={control}
          fields={fields}
          timePicker={true}
          format="DD-MM-YYYY"
          conversionFormat="YYYY-MM-DD"
          minDate={new Date()}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("docket_number")}
          name="docketNumber"
          control={control}
          fullWidth
          variant="outlined"
          fields={fields}
          errors={errors}
        />
      </Grid>
      <Grid item xs={7.5} sm={6} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          name="courierType"
          label={t("courier_type")}
          control={control}
          fullWidth
          variant="outlined"
          fields={fields}
          errors={errors}
        >
          <MenuItem value={"Courier"}>Courier</MenuItem>
          <MenuItem value={"Register Post"}>Register Post</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      <Grid item xs={7.5} sm={6} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          name="courierName"
          label={t("courier_name")}
          control={control}
          fullWidth
          variant="outlined"
          fields={fields}
          errors={errors}
        >
          <MenuItem value={"The Professional Courier"}>The Professional Courier</MenuItem>
          <MenuItem value={"First Flight Courier"}>First Flight Courier</MenuItem>
          <MenuItem value={"Blue Dart Express"}>Blue Dart Express</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      <Grid item md={6}>
        <CustomDatePicker
          name="dateOfSend"
          nameTemp="dateOfSendStr"
          label={t("date_of_send")}
          range={false}
          control={control}
          fields={fields}
          timePicker={true}
          format="DD-MM-YYYY"
          conversionFormat="YYYY-MM-DD"
          minDate={new Date()}
        />
      </Grid>
      <Grid item md={6}>
        <CustomDatePicker
          name="dateOfExpiry"
          nameTemp="dateOfExpiryStr"
          label={t("expiry_date")}
          range={false}
          control={control}
          fields={fields}
          timePicker={true}
          format="DD-MM-YYYY"
          conversionFormat="YYYY-MM-DD"
          minDate={new Date()}
        />
      </Grid>
    </Grid>
  );
}

export default AgreementTrackerFields;
