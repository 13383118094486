import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, MenuItem } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import useAddon from "../../../../hooks/useAddon";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import FilterReset from "../../../../components/FilterReset";
import { searchPayload, convertedValue } from "../../../../utils/common";
import { OperatorConstants } from "../../../../constants/operator.constants";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import CustomTextField from "../../../../components/CustomTextField";
import { useDialog } from "../../../../contexts/DialogContext";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const statusOptions = [
  {
    label: "Active",
    value: "A",
  },
  {
    label: "InActive",
    value: "INA",
  },
];

function AdvanceFilter(props) {
  const { unSetDialog } = useDialog();
  const initialValues = {
    addOnOperator: "eq",
    taxTypeOperator: "eq",
    statusOperator: "eq",
    addOnName: [],
    taxType: "",
    status: [],
  };

  const { fields, payload, setPayload, setFilterResetFunc } = props;

  const { autoSearchAddOn, addOnAutoSearchResults, isLoadingAutoSearch } = useAddon();

  const [addOnPayload, setAddonPayload] = useState(currentPayload);
  const [addOnOptions, setAddonOptions] = useState([]);
  const [addOnMultiValue, setAddonMultiValue] = useState(false);
  const [statusMultiValue, setStatusMultiValue] = useState(false);
  const [defaultAddon, setDefaultAddon] = useState([]);

  const [defaultStatus, setDefaultStatus] = useState([]);

  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = methods;

  useEffect(() => {
    setFilterResetFunc(() => reset);
  }, [reset]);

  const addOnopValue = watch("addOnOperator");
  const statusOpValue = watch("statusOperator");

  const onSubmit = async (data) => {
    try {
      let filters = [];

      if (data.addOnName != null && Object.keys(data.addOnName).length != 0) {
        filters.push({
          fieldName: "addOnId",
          fieldValue: convertedValue(data.addOnName, "value").join(","),
          op: data.addOnOperator,
          dataType: "int",
          condition: "and",
          isJoin: false,
        });
      }
      if (data.taxType != "" && data.taxType != null) {
        filters.push({
          fieldName: "taxType",
          fieldValue: data.taxType,
          op: data.taxTypeOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }
      if (data.status != null && Object.keys(data.status).length != 0) {
        filters.push({
          fieldName: "status",
          fieldValue: convertedValue(data.status, "value").join(","),
          op: data.statusOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }
      setPayload({
        ...payload,
        filters: filters,
      });
      unSetDialog("advance_filter");
    } catch (err) {
      console.log(err, "errerrerrerr");
    }
  };

  useEffect(() => {
    if (addOnopValue == "in" || addOnopValue == "nin") {
      setDefaultAddon([]);
      setValue("addOn", []);
      setAddonMultiValue(true);
    } else {
      setDefaultAddon({});
      setValue("addOn", {});
      setAddonMultiValue(false);
    }
  }, [addOnopValue]);

  useEffect(() => {
    if (statusOpValue == "in" || statusOpValue == "nin") {
      setDefaultStatus([]);
      setValue("status", []);
      setStatusMultiValue(true);
    } else {
      setDefaultStatus({});
      setValue("status", {});
      setStatusMultiValue(false);
    }
  }, [statusOpValue]);

  const fetchAddon = async () => {
    await autoSearchAddOn(addOnPayload);
  };
  useEffect(() => {
    fetchAddon();
  }, [addOnPayload]);

  console.log(addOnAutoSearchResults, "addOnAutoSearchResultsaddOnAutoSearchResults");

  useEffect(() => {
    if (addOnAutoSearchResults.data.length !== 0) {
      setAddonOptions([...addOnOptions, ...addOnAutoSearchResults.data]);
    }
  }, [addOnAutoSearchResults]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} pt={8}>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <ReactHookFormSelect
                name="addOnOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <SearchableAutoComplete
                control={control}
                data={addOnOptions}
                label="Addon Name"
                payload={addOnPayload}
                setPayload={setAddonPayload}
                loading={isLoadingAutoSearch}
                name="addOnName"
                multiple={addOnMultiValue}
                defaultValue={defaultAddon}
              />
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <ReactHookFormSelect
                name="taxTypeOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.string).map((t, k) => (
                  <MenuItem key={k} value={t[0]}>
                    {t[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                label={t("tax_type")}
                name="taxType"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <ReactHookFormSelect
                name="statusOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((t, k) => (
                  <MenuItem key={k} value={t[0]}>
                    {t[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <SearchableAutoComplete
                control={control}
                data={statusOptions}
                label="Status"
                payload={null}
                setPayload={null}
                loading={false}
                name="status"
                multiple={statusMultiValue}
                defaultValue={defaultStatus}
              />
            </Grid>
          </Grid>
          <FilterReset resetForm={reset} />
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default AdvanceFilter;
