import { useContext } from "react";

import { PaymentProcessContext } from "../contexts/PaymentProcessContext";
// import { AuthContext } from "../contexts/FirebaseAuthContext";
// import { AuthContext } from "../contexts/Auth0Context";
// import { AuthContext } from "../contexts/CognitoContext";

const usePaymentProcess = () => {
  const context = useContext(PaymentProcessContext);

  if (!context)
    throw new Error("PaymentProcessContext must be placed within PaymentProcessProvider");

  return context;
};

export default usePaymentProcess;
