import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import { useTranslation } from "react-i18next";
import useMainContentDimensions from "../../../utils/mainContentDimensions";

import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  Button,
  Typography,
  CardContent,
  Chip,
  Box,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import Toolbar from "../../../components/Toolbar";
import useWindowDimensions from "../../../utils/windowDimensions";
import useHotelManagement from "../../../hooks/useHotelManagement";
import useAuth from "../../../hooks/useAuth";
import AdvanceFilter from "./components/AdvanceFilter";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const userStatus = (status, sessionCount) => {
  if (status == "active") {
    return <Chip label="Active" color="primary" />;
  } else if (status == "online") {
    return <CustomNotificationBadge count={sessionCount} />;
  } else if (status == "expired") {
    return <Chip label="Expired" color="warning" />;
  } else if (status == "offline") {
    return <Chip label="Offline" sx={{ backgroundColor: "grey.500", color: "white" }} />;
  }
};

function HotelActivationReports(props) {
  const { t } = useTranslation();
  const {
    getHotelActivationReports,
    hotelActivationReports,
    paging,
    downloadFile,
    isLoadingDownload,
    downloadFileType,
  } = useHotelManagement();
  const { user } = useAuth();

  const { width: mainContentWidth } = useMainContentDimensions();
  const { openDialog } = useDialog();

  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "createdAt",
        sort: "DESC",
      },
    ],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height } = useWindowDimensions();

  const [payload, setPayload] = useState(currentPayload);

  const [filterResetFunc, setFilterResetFunc] = useState(
    () => () => console.log("Refresh initialized"),
  );

  const fetchData = async () => {
    await getHotelActivationReports(payload);
  };

  const refreshPage = () => {
    setPayload(currentPayload);
    filterResetFunc();
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: currentPayload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Reports</Typography>
        <Typography>Hotel Activation Reports</Typography>
      </Breadcrumbs>
    );
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const columns = [
    {
      field: "userName",
      headerName: "User Name",
      width: 200,
    },
    {
      field: "activationDate",
      headerName: "Activation Date",
      width: 200,
    },
    {
      field: "simUse",
      headerName: "Sim Use",
      width: 200,
    },

    {
      field: "plan",
      headerName: "Plan Name",
      valueGetter: (params) => params?.planName,
      width: 200,
    },
  ];

  const openFilterDialog = () => {
    return openDialog(
      "advance_filter",
      "Advance Filter",
      <AdvanceFilter
        payload={payload}
        setPayload={setPayload}
        setFilterResetFunc={setFilterResetFunc}
      />,
    );
  };

  return (
    <Card sx={{ width: "100%", maxWidth: mainContentWidth - 64 }}>
      <CardHeader
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // Stack on small screens
          justifyContent: "space-between",
          alignItems: "flex-start", // Adjust alignment as needed
        }}
        action={
          <Toolbar
            downloadFile={downloadFile}
            refreshPage={refreshPage}
            openFilterDialog={openFilterDialog}
            isLoadingDownload={isLoadingDownload}
            downloadFileType={downloadFileType}
          />
        }
        title={
          <Typography variant="h6" sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem" } }}>
            {t("hotel_activation_report")}
          </Typography>
        }
        subheader={<Box sx={{ display: "flex", flexWrap: "wrap" }}>{breadCrump()}</Box>}
      />
      <CardContent sx={{ height: height - 180, display: "flex", flexDirection: "column" }}>
        <Box sx={{ flex: 1, overflow: "auto" }}>
          <DataGrid
            columns={columns}
            rows={hotelActivationReports}
            // refreshPage={fetchData}
            getRowId={(row) => row["hotelActivationHistoryId"]}
            rowCount={paging.totalNumberOfRecords}
            loading={false}
            rowsPerPageOptions={[5]}
            pagination
            page={paging.pageNumber}
            pageSize={paging.pageSize}
            paginationMode="server"
            onPageChange={(page) => onPageChange(page)}
            onPageSizeChange={(pageSize) => onPageChange(pageSize)}
            checkboxSelection
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
          />
        </Box>
      </CardContent>
    </Card>
  );
}

export default HotelActivationReports;
