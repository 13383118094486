//Users
import HotelUserList from "../pages/hms/hotel_users/index.js";
import AddHotelUser from "../pages/hms/hotel_users/add.js";
import EditHotelUser from "../pages/hms/hotel_users/edit.js";
import ActivateUser from "../pages/hms/activate_users/index.js";
import DeactivateUser from "../pages/hms/deactivate_users/index.js";

//Composers
import HotelManagementComposer from "../composers/HotelManagementComposer.js";
import { DashboardComposer } from "../composers/DashboardComposer.js";
// Guards
import AuthGuard from "../components/guards/AuthGuard.js";
import DashboardLayout from "../layouts/Dashboard.js";
import ProtectedLayout from "../layouts/ProtectedLayout";

const HotelManagementRoutes = {
  path: "hms",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  children: [
    {
      path: "hotel-user",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <HotelManagementComposer>
                <AddHotelUser />
              </HotelManagementComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <HotelManagementComposer>
                <EditHotelUser />
              </HotelManagementComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <HotelManagementComposer>
                <HotelUserList />
              </HotelManagementComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "activate-user",
      element: (
        <ProtectedLayout>
          <HotelManagementComposer>
            <ActivateUser />
          </HotelManagementComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "deactivate-user",
      element: (
        <ProtectedLayout>
          <HotelManagementComposer>
            <DeactivateUser />
          </HotelManagementComposer>
        </ProtectedLayout>
      ),
    },
  ],
};

export default HotelManagementRoutes;
