import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert as MuiAlert, Grid, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Formik } from "formik";
import useCountry from "../../../../../hooks/useCountry";
import SearchableAutoComplete from "../../../../../components/SearchableAutoComplete";
import CustomTextField from "../../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../../components/ReactHookFormSelect";
import { searchPayload } from "../../../../../utils/common";
import useStateMaster from "../../../../../hooks/useStateMaster";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const Alert = styled(MuiAlert)(spacing);

const Localization = (props) => {
  const { validationFields } = props;

  const searchPayloadValue = searchPayload();
  const { autoSearchCountry, countryAutoSearchResults, isLoadingAutoSearch } = useCountry();
  const {
    autoSearchState,
    stateAutoSearchResults,
    isLoadingAutoSearch: isLoadingCountryAutoSearch,
  } = useStateMaster();

  const [payload, setPayload] = useState(currentPayload);
  const [statePayload, setStatePayload] = useState(currentPayload);
  const [countryOptions, setCountryOptions] = useState([]);
  useEffect(() => {
    autoSearchCountry(payload);
  }, [payload]);

  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext();

  const country = watch("country");
  const stateMaster = watch("state");

  console.log(country, "country");

  useEffect(() => {
    if (country) {
      fetchStates(country.value);
      setValue("state", "");
    }
  }, [country]);

  useEffect(() => {
    if (countryAutoSearchResults.data.length !== 0) {
      setCountryOptions([...countryOptions, ...countryAutoSearchResults.data]);
    }
  }, [countryAutoSearchResults]);

  const fetchStates = async (countryId) => {
    if (countryId) {
      searchPayloadValue["filters"] = [
        {
          fieldName: "countryId",
          fieldValue: countryId,
          op: "eq",
          dataType: "int",
          condition: "and",
          isJoin: true,
        },
      ];
      await autoSearchState(searchPayloadValue);
    }
  };

  return (
    <Grid container spacing={6} className="row">
      <Grid item md={6}>
        <SearchableAutoComplete
          control={control}
          data={countryOptions}
          label={t("country_name")}
          payload={payload}
          setPayload={setPayload}
          loading={isLoadingAutoSearch}
          name="country"
          multiple={false}
          defaultValue={country}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("time_zone")}
          name="timeZone"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
    </Grid>
  );
};
export default Localization;
