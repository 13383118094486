import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import { useTranslation } from "react-i18next";
import CustomTextField from "../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import useTenant from "../../../../hooks/useTenant";
import useBranch from "../../../../hooks/useBranch";
import useEmployee from "../../../../hooks/useEmployee";
import useAuth from "../../../../hooks/useAuth";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

function EmployeeFields(props) {
  const { fields, disableUserName } = props;
  const { user } = useAuth();

  const { autoSearchEmployee, employeeAutoSearchResults } = useEmployee();
  const { autoSearchTenant, tenantAutoSearchResults } = useTenant();
  const { autoSearchBranch, branchAutoSearchResults } = useBranch();

  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext();

  const tenant = watch("tenant");

  const [tenantOptions, setTenantOptions] = useState([]);
  const [tenantPayload, setTenantPayload] = useState(currentPayload);
  const [branchOptions, setBranchOptions] = useState([]);
  const [branchPayload, setBranchPayload] = useState(currentPayload);

  // Tenant
  const fetchTenant = async () => {
    await autoSearchTenant(tenantPayload);
  };
  useEffect(() => {
    fetchTenant();
  }, [tenantPayload]);

  useEffect(() => {
    console.log(tenant, "tenanttenanttenant");
    if (tenant != null && Object.keys(tenant).length != 0) {
      const payload = {
        ...branchPayload,
        filters: [
          {
            fieldName: "tenantId",
            fieldValue: tenant.value,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
          },
        ],
      };
      setBranchOptions([]);
      fetchBranch(payload);
    }
  }, [tenant]);

  useEffect(() => {
    console.log(tenantAutoSearchResults, "tenantAutoSearchResults");
    if (tenantAutoSearchResults && tenantAutoSearchResults.data.length !== 0) {
      const results = [...tenantOptions, ...tenantAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setTenantOptions(filteredResults);
    }
  }, [tenantAutoSearchResults]);

  // Branch
  const fetchBranch = async (payload) => {
    await autoSearchBranch(payload);
  };

  useEffect(() => {
    console.log(branchAutoSearchResults, "branchAutoSearchResults");
    if (branchAutoSearchResults && branchAutoSearchResults.data.length !== 0) {
      const results = [...branchOptions, ...branchAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setBranchOptions(filteredResults);
    }
  }, [branchAutoSearchResults]);

  return (
    <Grid container spacing={6} className="row">
      {user &&
        (user.businessType === "SUPER_ADMIN" ||
          user.businessType == "DISTRIBUTOR" ||
          user.businessType == "MULTI_SERVICE_OPERATOR") && (
          <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
            <SearchableAutoComplete
              name="tenant"
              label={t("franchise_name")}
              control={control}
              data={tenantOptions}
              payload={tenantPayload}
              setPayload={setTenantPayload}
              loading={false}
              multiple={false}
              defaultValue={""}
              hasNextPage={false}
              fields={fields}
              fullWidth
              variant="outlined"
              errors={errors}
            ></SearchableAutoComplete>
          </Grid>
        )}
      <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
        <SearchableAutoComplete
          name="branchName"
          label={t("branch_name")}
          control={control}
          data={branchOptions}
          payload={branchPayload}
          setPayload={setBranchPayload}
          loading={false}
          multiple={false}
          defaultValue={""}
          hasNextPage={false}
          fields={fields}
          fullWidth
          variant="outlined"
          errors={errors}
        ></SearchableAutoComplete>
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("first_name")}
          name="firstName"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("last_name")}
          name="lastName"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("mobile_number")}
          name="mobileNo"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("email_id")}
          name="emailId"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("user_name")}
          name="userName"
          control={control}
          fullWidth
          variant="outlined"
          disabled={disableUserName}
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("password")}
          name="password"
          type="password"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("confirm_password")}
          name="confirmPassword"
          type="password"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("address")}
          name="address"
          control={control}
          fullWidth
          variant="outlined"
          fields={fields}
          errors={errors}
        />
      </Grid>
      <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          id="status"
          name="status"
          label={t("Status")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        >
          <MenuItem value={"ACTIVE"}>Active</MenuItem>
          <MenuItem value={"INACTIVE"}>Inactive</MenuItem>
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
}

export default EmployeeFields;
