import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import ListItemText from "@mui/material/ListItemText";
import { useDialog } from "../../contexts/DialogContext";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

function GlobalCreateModal(props) {
  const { unSetDialog } = useDialog();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const addTenant = () => {
    navigate("/cpanel/tenant/add");
    unSetDialog("global_creation");
  };
  const addUser = () => {
    navigate("/user/userlist/add");
    unSetDialog("global_creation");
  };

  return (
    <React.Fragment>
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={addTenant}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Create new Tenant" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <DraftsIcon />
            </ListItemIcon>
            <ListItemText primary="Create Router Setup" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={addUser}>
            <ListItemIcon>
              <DraftsIcon />
            </ListItemIcon>
            <ListItemText primary="Create new User" />
          </ListItemButton>
        </ListItem>
      </List>
    </React.Fragment>
  );
}

export default GlobalCreateModal;
