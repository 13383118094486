import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

const useStyles = styled((theme) => ({
  text: {
    color: "#d32f2f",
  },
}));

// const CustomFileField = ({
//   name,
//   label,
//   control,
//   type,
//   errors,
//   fields,
//   disabled,
//   ref,
//   ...props
// }) => {
//   const { t } = useTranslation();
//   const classes = useStyles();
//   const labelId = `${name}-label`;
//   //console.log(errors, name, "errorserrors");

//   const nameSplit = name.split(".");

//   console.log(errors, name, nameSplit, fields, "errorserrors");

//   let errorName = errors[name];

//   if (nameSplit.length == 3 && Object.keys(errors).length != 0) {
//     errorName = errors[nameSplit[0]]?.[Number(nameSplit[1])]?.[nameSplit[2]];
//   }

//   let required = false;
//   //console.log(props, "requiredrequiredrequiredrrr");
//   if (
//     fields?.[name]?.["exclusiveTests"]?.["required"] ||
//     (fields?.[name]?.deps != undefined && fields?.[name]?.deps.length != 0)
//   ) {
//     required = true;
//   }

//   return (
//     <FormControl {...props}>
//       <Controller
//         render={({ field: { value, onChange, ...field } }) => (
//           <TextField
//             name={props.id || name}
//             value={value?.fileName}
//             label={t(label)}
//             type="file"
//             {...field}
//             onChange={(event) => {
//               onChange(event.target.files[0]);
//             }}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             error={errorName ? true : false}
//             helperText={errorName !== undefined ? errorName?.message : ""}
//             inputRef={ref}
//           />
//         )}
//         name={name}
//         control={control}
//       />
//     </FormControl>
//   );
// };
// Forward the ref to the actual file input element

//   disabled,
const CustomFileField = React.forwardRef(({ name, label, control, type, errors, fields }, ref) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const labelId = `${name}-label`;
  //console.log(errors, name, "errorserrors");

  const nameSplit = name.split(".");

  console.log(errors, name, nameSplit, fields, "errorserrors");

  let errorName = errors[name];

  if (nameSplit.length == 3 && Object.keys(errors).length != 0) {
    errorName = errors[nameSplit[0]]?.[Number(nameSplit[1])]?.[nameSplit[2]];
  }

  let required = false;
  //console.log(props, "requiredrequiredrequiredrrr");
  if (
    fields?.[name]?.["exclusiveTests"]?.["required"] ||
    (fields?.[name]?.deps != undefined && fields?.[name]?.deps.length != 0)
  ) {
    required = true;
  }
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ field: { onChange } }) => (
        <TextField
          label={label}
          type="file"
          inputRef={ref} // Attach the ref here
          onChange={(event) => {
            onChange(event.target.files[0]); // Send the file to react-hook-form
          }}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errorName}
          helperText={errorName ? errorName.message : ""}
        />
      )}
    />
  );
});
export default CustomFileField;
