import React, { useEffect, useState, useRef } from "react";
import {
  Alert as MuiAlert,
  Box,
  Grid,
  DialogActions,
  Button,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { useDialog } from "../../../../contexts/DialogContext";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";
import CustomFileField from "../../../../components/CustomFileField";
import useTenant from "../../../../hooks/useTenant";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const validationSchema = Yup.object().shape({
  file: Yup.mixed()
    .required("A file is required")
    .test("fileFormat", "Only support image file", (value) => {
      console.log(value, "ffffffffffff");
      return value && ["image/jpeg", "image/jpg", "image/png"].includes(value.type);
    }),
});

const Alert = styled(MuiAlert)(spacing);

const UploadLogo = (props) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [payload, setPayload] = useState(currentPayload);
  let [searchParams, setSearchParams] = useSearchParams();
  const { uploadLogo, tenant, getTenant } = useTenant();
  // const tenantId = searchParams.get("id");

  const { tenantId } = props;

  console.log(tenantId, "ttttt");

  const { isSubmitting } = useTenant();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {
    reset,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
    control,
  } = methods;
  const { unSetDialog, openDialog } = useDialog();

  const onSubmit = async (data, props) => {
    try {
      console.log(data, "datadata");
      const params = {
        tenantId: tenantId,
        file: data.file,
      };
      await uploadLogo(params);
      dispatch(showSuccessSnackbar("Invoice Logo added successfully!"));
      unSetDialog("upload_logo");
    } catch (ex) {
      dispatch(showErrorSnackbar(ex.message));
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} className="row" pt={8}>
            <Grid item md={6}>
              <CustomFileField
                label="Upload"
                name="file"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
                fields={validationSchema.fields}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<UploadFileIcon />}
              endIcon={isSubmitting ? <CircularProgress size={24} /> : null}
              disabled={false}
            >
              Upload
            </Button>

            <Button variant="text" onClick={() => unSetDialog("upload_logo")} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default UploadLogo;
