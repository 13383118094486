import { UserConstants } from "../constants/user.constants";

const UserReducer = (state, action) => {
  switch (action.type) {
    case UserConstants.FETCH_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case UserConstants.FETCH_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: action.payload.data,
        paging: action.payload.paging,
      };
    case UserConstants.FETCH_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case UserConstants.ADD_MATERIAL_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case UserConstants.ADD_MATERIAL_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case UserConstants.ADD_MATERIAL_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };
    case UserConstants.ADD_DISCONNECT_USER_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case UserConstants.ADD_DISCONNECT_USER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case UserConstants.ADD_DISCONNECT_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };
    case UserConstants.ADD_CLOSE_USER_SESSION_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case UserConstants.ADD_CLOSE_USER_SESSION_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case UserConstants.ADD_CLOSE_USER_SESSION_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };
    case UserConstants.FETCH_MATERIAL_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case UserConstants.FETCH_MATERIAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userMaterials: action.payload.data,
        paging: action.payload.paging,
      };
    case UserConstants.FETCH_MATERIAL_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case UserConstants.GET_MATERIAL_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case UserConstants.GET_MATERIAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userMaterial: action.payload,
      };
    case UserConstants.GET_MATERIAL_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case UserConstants.GET_SHOW_ROUTER_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case UserConstants.GET_SHOW_ROUTER_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        showPass: action.payload,
      };
    case UserConstants.GET_SHOW_ROUTER_PASSWORD_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case UserConstants.GET_SHOW_APP_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case UserConstants.GET_SHOW_APP_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        showAppPass: action.payload,
      };
    case UserConstants.GET_SHOW_APP_PASSWORD_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case UserConstants.UPDATE_MATERIAL_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case UserConstants.UPDATE_MATERIAL_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        userMaterial: action.payload,
      };
    case UserConstants.UPDATE_MATERIAL_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };

    case UserConstants.UPDATE_SUSPEND_USER_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case UserConstants.UPDATE_SUSPEND_USER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        suspendUser: action.payload,
      };
    case UserConstants.UPDATE_SUSPEND_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };

    case UserConstants.ADD_AUT0_RENEWAL_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case UserConstants.ADD_AUT0_RENEWAL_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case UserConstants.ADD_AUT0_RENEWAL_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case UserConstants.USER_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case UserConstants.USER_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userSearchResults: action.payload,
      };
    case UserConstants.USER_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case UserConstants.USER_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case UserConstants.USER_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userAutoSearchResults: action.payload,
      };
    case UserConstants.USER_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case UserConstants.GLOBAL_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case UserConstants.GLOBAL_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        globalAutoSearchResults: action.payload,
      };
    case UserConstants.GLOBAL_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case UserConstants.ADD_USER_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case UserConstants.ADD_USER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case UserConstants.ADD_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case UserConstants.RENEW_USER_REQUEST:
      return {
        ...state,
        isRenewSubmitting: true,
        hasError: false,
      };
    case UserConstants.RENEW_USER_SUCCESS:
      return {
        ...state,
        isRenewSubmitting: false,
      };
    case UserConstants.RENEW_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isRenewSubmitting: false,
        errorMessage: action.payload.message,
      };
    case UserConstants.CHANGE_VALIDITY_REQUEST:
      return {
        ...state,
        isRenewSubmitting: true,
        hasError: false,
      };
    case UserConstants.CHANGE_VALIDITY_SUCCESS:
      return {
        ...state,
        isRenewSubmitting: false,
      };
    case UserConstants.CHANGE_VALIDITY_FAILURE:
      return {
        ...state,
        hasError: true,
        isRenewSubmitting: false,
        errorMessage: action.payload.message,
      };

    case UserConstants.GET_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case UserConstants.GET_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
    case UserConstants.GET_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case UserConstants.GET_RENEWAL_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case UserConstants.GET_RENEWAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        renewal: action.payload,
      };
    case UserConstants.GET_RENEWAL_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case UserConstants.UPDATE_USER_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case UserConstants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        user: action.payload,
      };
    case UserConstants.UPDATE_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };
    case UserConstants.DOWNLOAD_USER_REQUEST:
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action?.payload?.fileType,
      };
    case UserConstants.DOWNLOAD_USER_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case UserConstants.DOWNLOAD_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case UserConstants.UPLOAD_USER_REQUEST:
      return {
        ...state,
        isUploading: true,
        uploadError: false,
      };
    case UserConstants.UPLOAD_USER_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUploading: false,
        uploadMessage: "",
      };
    case UserConstants.UPLOAD_USER_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUploading: false,
        uploadMessage: action.payload,
      };

    case UserConstants.ONLINE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case UserConstants.ONLINE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        onlineUsers: action.payload.data,
        paging: action.payload.paging,
      };
    case UserConstants.ONLINE_USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case UserConstants.USER_DASHBOARD_COUNT_REQUEST:
      return {
        ...state,
        isUserDashboardLoading: true,
        hasError: false,
      };
    case UserConstants.USER_DASHBOARD_COUNT_SUCCESS:
      return {
        ...state,
        isUserDashboardLoading: false,
        dashboadUserCount: action.payload,
      };
    case UserConstants.USER_DASHBOARD_COUNT_FAILURE:
      return {
        ...state,
        hasError: true,
        isUserDashboardLoading: false,
      };

    case UserConstants.USER_DETAIL_INVOICE_REQUEST:
      return {
        ...state,
        isUserDetailInvoiceLoading: true,
        hasError: false,
      };
    case UserConstants.USER_DETAIL_INVOICE_SUCCESS:
      return {
        ...state,
        isUserDetailInvoiceLoading: false,
        userDetailsInvoice: action.payload,
      };
    case UserConstants.USER_DETAIL_INVOICE_FAILURE:
      return {
        ...state,
        hasError: true,
        isUserDetailInvoiceLoading: false,
      };

    case UserConstants.CREATE_ORDER_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case UserConstants.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        userOrderId: action.payload,
      };
    case UserConstants.CREATE_ORDER_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };

    case UserConstants.CANCEL_ORDER_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case UserConstants.CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case UserConstants.CANCEL_ORDER_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
      };

    default:
      return state;
  }
};

export default UserReducer;
