export const PaymentGatewayConstants = {
  FETCH_PAYMENT_GATEWAY_REQUEST: "FETCH_PAYMENT_GATEWAY_REQUEST",
  FETCH_PAYMENT_GATEWAY_SUCCESS: "FETCH_PAYMENT_GATEWAY_SUCCESS",
  FETCH_PAYMENT_GATEWAY_FAILURE: "FETCH_PAYMENT_GATEWAY_FAILURE",
  ADD_PAYMENT_GATEWAY_REQUEST: "ADD_PAYMENT_GATEWAY_REQUEST",
  ADD_PAYMENT_GATEWAY_SUCCESS: "ADD_PAYMENT_GATEWAY_SUCCESS",
  ADD_PAYMENT_GATEWAY_FAILURE: "ADD_PAYMENT_GATEWAY_FAILURE",
  PAYMENT_GATEWAY_SEARCH_REQUEST: "PAYMENT_GATEWAY_SEARCH_REQUEST",
  PAYMENT_GATEWAY_SEARCH_SUCCESS: "PAYMENT_GATEWAY_SEARCH_SUCCESS",
  PAYMENT_GATEWAY_SEARCH_FAILURE: "PAYMENT_GATEWAY_SEARCH_FAILURE",
  GET_PAYMENT_GATEWAY_REQUEST: "GET_PAYMENT_GATEWAY_REQUEST",
  GET_PAYMENT_GATEWAY_SUCCESS: "GET_PAYMENT_GATEWAY_SUCCESS",
  GET_PAYMENT_GATEWAY_FAILURE: "GET_PAYMENT_GATEWAY_FAILURE",
  UPDATE_PAYMENT_GATEWAY_REQUEST: "UPDATE_PAYMENT_GATEWAY_REQUEST",
  UPDATE_PAYMENT_GATEWAY_SUCCESS: "UPDATE_PAYMENT_GATEWAY_SUCCESS",
  UPDATE_PAYMENT_GATEWAY_FAILURE: "UPDATE_PAYMENT_GATEWAY_FAILURE",
  DOWNLOAD_PAYMENT_GATEWAY_REQUEST: "DOWNLOAD_PAYMENT_GATEWAY_REQUEST",
  DOWNLOAD_PAYMENT_GATEWAY_SUCCESS: "DOWNLOAD_PAYMENT_GATEWAY_SUCCESS",
  DOWNLOAD_PAYMENT_GATEWAY_FAILURE: "DOWNLOAD_PAYMENT_GATEWAY_FAILURE",
  UPLOAD_PAYMENT_GATEWAY_REQUEST: "UPLOAD_PAYMENT_GATEWAY_REQUEST",
  UPLOAD_PAYMENT_GATEWAY_SUCCESS: "UPLOAD_PAYMENT_GATEWAY_SUCCESS",
  UPLOAD_PAYMENT_GATEWAY_FAILURE: "UPLOAD_PAYMENT_GATEWAY_FAILURE",
  PAYMENT_GATEWAY_AUTO_SEARCH_REQUEST: "PAYMENT_GATEWAY_AUTO_SEARCH_REQUEST",
  PAYMENT_GATEWAY_AUTO_SEARCH_SUCCESS: "PAYMENT_GATEWAY_AUTO_SEARCH_SUCCESS",
  PAYMENT_GATEWAY_AUTO_SEARCH_FAILURE: "PAYMENT_GATEWAY_AUTO_SEARCH_FAILURE",
};
