import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Box, Button, Typography } from "@mui/material";
import { io } from "socket.io-client";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { convertBytesToMB } from "../../../utils/common";

const socketUrl = process.env.REACT_APP_SOCKET_URL;

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// const initialData = {
//   labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
//   datasets: [
//     {
//       label: "Download Speed",
//       data: [400, 300, 200, 278, 189, 239, 349],
//       borderColor: "#8884d8",
//       backgroundColor: "rgba(136, 132, 216, 0.5)",
//       tension: 0.4,
//       pointRadius: 5,
//       fill: true,
//     },
//     {
//       label: "Upload Speed",
//       data: [240, 139, 980, 390, 480, 200, 300],
//       borderColor: "#82ca9d",
//       backgroundColor: "rgba(130, 202, 157, 0.5)",
//       tension: 0.4,
//       pointRadius: 5,
//       fill: true,
//     },
//   ],
// };

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Download and Upload Speeds",
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  // Animation settings
  animation: {
    duration: 2000, // Animation duration in ms
    easing: "easeInOutQuart", // Easing function for animation
  },
};

const SpeedGraph = ({ userId }) => {
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);

  const [chartData, setChartData] = useState({
    labels: [], // This will be updated with time or data points
    datasets: [
      {
        label: "Download Speed (Mbps)",
        data: [], // Will store download speed values
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
      },
      {
        label: "Upload Speed (Mbps)",
        data: [], // Will store upload speed values
        borderColor: "rgba(153, 102, 255, 1)",
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        fill: true,
      },
    ],
  });

  useEffect(() => {
    //console.log(socketUrl, "socketUrl....");
    // Connect to the Socket.IO server (adjust the URL to your backend's WebSocket URL)
    const newSocket = io(socketUrl, {
      // Ensure this matches your backend's URL
      upgrade: true,
      transports: ["websocket"], // Use WebSocket transport to avoid polling fallback
      reconnection: true, // Enable reconnection attempts
      reconnectionDelay: 5000,
      query: {
        userName: userId, // If you need to send custom data (like a username)
      },
      cors: {
        origin: "https://crm.hyperradius.net", // Allow this origin to connect
        methods: ["GET", "POST"],
        credentials: true,
      },
    });

    // Store the socket instance in the state
    setSocket(newSocket);

    // Handle incoming messages
    newSocket.on("messageSendToUser", (data) => {
      console.log("Message received:", data);
      setMessages((prevMessages) => [...prevMessages, data]);
    });

    // Handle disconnection
    newSocket.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    newSocket.on("message", (data) => {
      console.log("Message received:", data);
      if (data.userName == userId) {
        updateData(data.downloadSpeed, data.uploadSpeed);
        setMessages((prevMessages) => [...prevMessages, data]);
      }
    });

    // Clean up the socket connection when the component is unmounted
    return () => {
      newSocket.close();
    };
  }, []);

  // Function to simulate data update
  // const updateData = () => {
  //   const newData = {
  //     ...chartData,
  //     datasets: chartData.datasets.map((dataset) => ({
  //       ...dataset,
  //       data: dataset.data.map((value) => value + Math.floor(Math.random() * 100 - 50)),
  //       // Add some randomness to simulate new data
  //     })),
  //   };
  //   setChartData(newData); // Update the state to trigger re-render
  // };
  const updateData = (downloadSpeed, uploadSpeed) => {
    const maxLabels = 6; // Limit to 30 labels (each representing 1 minute)

    setChartData((prevData) => {
      const newLabel = new Date().toLocaleTimeString(); // Current time as label

      // Trim labels and data arrays to the last 30 entries (if there are more than 30)
      const updatedLabels =
        prevData.labels.length >= maxLabels
          ? [...prevData.labels.slice(1), newLabel] // Remove oldest label and add new one
          : [...prevData.labels, newLabel]; // Add new label

      const updatedDatasets = prevData.datasets.map((dataset, index) => {
        const newData =
          index === 0
            ? convertBytesToMB(downloadSpeed) // Update download speed data
            : convertBytesToMB(uploadSpeed); // Update upload speed data

        // Trim data arrays to the last 30 entries (if there are more than 30)
        const updatedData =
          dataset.data.length >= maxLabels
            ? [...dataset.data.slice(1), newData] // Remove oldest data point and add new one
            : [...dataset.data, newData]; // Add new data point

        return { ...dataset, data: updatedData };
      });

      return {
        labels: updatedLabels,
        datasets: updatedDatasets,
      };
    });
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Download and Upload Speeds
      </Typography>
      <Line data={chartData} options={options} />
    </Box>
  );
};

export default SpeedGraph;
