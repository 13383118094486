import React, { useEffect, useState, useRef } from "react";
import {
  Alert as MuiAlert,
  Box,
  Grid,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useDialog } from "../../../../contexts/DialogContext";
import useUser from "../../../../hooks/useUser";
import CustomTextField from "../../../../components/CustomTextField";

import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";

const Alert = styled(MuiAlert)(spacing);

const ShowAppPassword = (props) => {
  const dispatch = useDispatch();
  const {
    getShowAppPassword,
    getShowPassword,
    showPass,
    showAppPass,
    isRenewSubmitting,
    updateAppPassword,
  } = useUser();
  const { radUserId } = props;

  const { t } = useTranslation();

  let [searchParams, setSearchParams] = useSearchParams();

  const methods = useForm({
    resolver: yupResolver(),
  });
  const {
    reset,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
    control,
  } = methods;

  const { unSetDialog, openDialog } = useDialog();

  const fetchData = async () => {
    await getShowAppPassword(searchParams.get("id"));
  };

  console.log(searchParams.get("id"), "searrcchhh");

  useEffect(() => {
    if (searchParams && searchParams.get("id")) {
      const currentPayload = {
        filters: [
          {
            fieldName: "radUserId",
            fieldValue: searchParams.get("id"),
            op: "eq",
            dataType: "string",
            condition: "and",
            isJoin: false,
          },
        ],
        pagination: {
          pageNumber: 1,
          pageSize: 100,
        },
        sortFields: [],
      };
      fetchData();
    }
  }, [searchParams]);

  React.useEffect(() => {
    const initialValues = {
      showAppPassword: showAppPass,
    };
    reset(initialValues);
  }, [showAppPass]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Grid container spacing={12} className="row" pt={8}>
          <Grid item md={6}>
            <CustomTextField
              label={t("password")}
              name="showAppPassword"
              control={control}
              disabled={true}
              fullWidth
              variant="outlined"
              errors={errors}
            />
          </Grid>
        </Grid>
      </FormProvider>
    </React.Fragment>
  );
};
export default ShowAppPassword;
