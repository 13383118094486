import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import PaymentGatewayReducer from "../reducers/PaymentGatewayReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { PaymentGatewayConstants } from "../constants/payment_gateway.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  paymentGateways: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  addonSearchResults: [],
  addon: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  addOnTenants: [],
  isLoading: true,
  isLoadingDownload: false,
  paymentGatewayAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoadingAutoSearch: true,
  downloadFileType: "",
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const PaymentGatewayContext = createContext({
  ...initialState,
  getAllPaymentGateway: () => {},
  addPaymentGateway: () => {},
  searchPaymentGateway: () => {},
  getPaymentGateway: () => {},
  updatePaymentGateway: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
  autoSearchPaymentGateway: () => {},
});

function PaymentGatewayProvider({ children }) {
  const [state, dispatch] = useReducer(PaymentGatewayReducer, initialState);

  const getAllPaymentGateway = async (payload) => {
    try {
      dispatch({
        type: PaymentGatewayConstants.FETCH_PAYMENT_GATEWAY_REQUEST,
      });
      const response = await axios.post("/payment-gateway/list", payload);

      dispatch({
        type: PaymentGatewayConstants.FETCH_PAYMENT_GATEWAY_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: PaymentGatewayConstants.FETCH_PAYMENT_GATEWAY_FAILURE,
      });
    }
  };
  const searchPaymentGateway = async () => {
    try {
      const response = await axios.post("/payment-gateway/search", initPayload);
      dispatch({
        type: PaymentGatewayConstants.PAYMENT_GATEWAY_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: PaymentGatewayConstants.PAYMENT_GATEWAY_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: PaymentGatewayConstants.PAYMENT_GATEWAY_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchPaymentGateway = async (payload) => {
    try {
      const response = await axios.post("/payment-gateway/auto-search", payload);
      dispatch({
        type: PaymentGatewayConstants.PAYMENT_GATEWAY_AUTO_SEARCH_REQUEST,
      });
      dispatch({
        type: PaymentGatewayConstants.PAYMENT_GATEWAY_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: PaymentGatewayConstants.PAYMENT_GATEWAY_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addPaymentGateway = async (payload) => {
    const response = await axios.post("/payment-gateway/add", payload);
    dispatch({
      type: PaymentGatewayConstants.ADD_PAYMENT_GATEWAY_REQUEST,
    });
    dispatch({
      type: PaymentGatewayConstants.ADD_PAYMENT_GATEWAY_SUCCESS,
      payload: response.data,
    });
  };

  const getPaymentGateway = async (id) => {
    const response = await axios.get("/payment-gateway/" + id);
    dispatch({
      type: PaymentGatewayConstants.GET_PAYMENT_GATEWAY_REQUEST,
    });
    dispatch({
      type: PaymentGatewayConstants.GET_PAYMENT_GATEWAY_SUCCESS,
      payload: response.data,
    });
  };

  const updatePaymentGateway = async (payload) => {
    const response = await axios.put("/payment-gateway/update/" + payload.addOnId, payload);
    dispatch({
      type: PaymentGatewayConstants.UPDATE_PAYMENT_GATEWAY_REQUEST,
    });
    dispatch({
      type: PaymentGatewayConstants.UPDATE_PAYMENT_GATEWAY_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: PaymentGatewayConstants.DOWNLOAD_PAYMENT_GATEWAY_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/payment-gateway/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "addon." + fileType);

    dispatch({
      type: PaymentGatewayConstants.DOWNLOAD_PAYMENT_GATEWAY_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/payment-gateway/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_addon." + fileType);
    dispatch({
      type: PaymentGatewayConstants.DOWNLOAD_PAYMENT_GATEWAY_REQUEST,
    });
    dispatch({
      type: PaymentGatewayConstants.DOWNLOAD_PAYMENT_GATEWAY_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: PaymentGatewayConstants.UPLOAD_PAYMENT_GATEWAY_REQUEST,
      });
      const response = await axios({
        url: "/payment-gateway/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: PaymentGatewayConstants.UPLOAD_PAYMENT_GATEWAY_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: PaymentGatewayConstants.UPLOAD_PAYMENT_GATEWAY_FAILURE,
      });
    }
  };

  return (
    <PaymentGatewayContext.Provider
      value={{
        ...state,
        getAllPaymentGateway,
        addPaymentGateway,
        searchPaymentGateway,
        getPaymentGateway,
        updatePaymentGateway,
        downloadFile,
        sampleFile,
        uploadFile,
        autoSearchPaymentGateway,
      }}
    >
      {children}
    </PaymentGatewayContext.Provider>
  );
}

export { PaymentGatewayContext, PaymentGatewayProvider };
