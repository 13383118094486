import React, { useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  CardHeader,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import PropTypes from "prop-types";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import SaveCancel from "../../../components/SaveCancel";
import useIppool from "../../../hooks/useIppool";
import IppoolFields from "./components/IppoolFields";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const initialValues = {
  poolName: "",
  fromIp: "",
  toIp: "",
  comments: "",
  status: "ACTIVE",
};

const validationSchema = Yup.object().shape({
  poolName: Yup.string().required("Required"),
  fromIp: Yup.string()
    .required("Required")
    .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
      message: "Invalid IP address",
      excludeEmptyString: true,
    }),
  toIp: Yup.string()
    .required("Required")
    .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
      message: "Invalid IP address",
      excludeEmptyString: true,
    }),
  comments: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

function AddForm(props) {
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const { addIppool, hasError, errorMessage, isSubmitting } = useIppool();
  const { t } = props;
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      await addIppool(data);
      reset();
      dispatch(showSuccessSnackbar("Ip pool added successfully!"));
    } catch (error) {
      console.log(error);
      dispatch(showErrorSnackbar(error.message));
    }
  };
  const cancelBtn = () => {
    navigate("/radius/ippool/list");
    //Ippool added successfully!
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Radius</Typography>
        <Typography>Ippool</Typography>
        <Typography>Add</Typography>
      </Breadcrumbs>
    );
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_ippool")} subheader={breadCrump()} />
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <IppoolFields fields={validationSchema.fields} />
                <br></br>
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

AddForm.propTypes = {
  t: PropTypes.func.isRequired,
};

function AddIppool() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <AddForm t={t} />
    </React.Fragment>
  );
}

export default AddIppool;
