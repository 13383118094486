import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert as MuiAlert, Grid, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Formik } from "formik";
import { useDialog } from "../../../../contexts/DialogContext";
import useDistrict from "../../../../hooks/useDistrict";
import useCountry from "../../../../hooks/useCountry";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import CustomTextField from "../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import { searchPayload } from "../../../../utils/common";
import useStateMaster from "../../../../hooks/useStateMaster";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const Alert = styled(MuiAlert)(spacing);

const StateFields = (props) => {
  const { fields } = props;
  const searchPayloadValue = searchPayload();
  const { autoSearchCountry, countryAutoSearchResults, isLoadingAutoSearch } = useCountry();
  const {
    autoSearchState,
    stateAutoSearchResults,
    isLoadingAutoSearch: isLoadingCountryAutoSearch,
  } = useStateMaster();

  const [payload, setPayload] = useState(currentPayload);
  const [statePayload, setStatePayload] = useState(currentPayload);
  const [countryOptions, setCountryOptions] = useState([]);
  useEffect(() => {
    autoSearchCountry(payload);
  }, [payload]);

  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext();

  const country = watch("country");
  const stateMaster = watch("state");

  console.log(country, "country");

  useEffect(() => {
    if (country) {
      fetchStates(country.value);
      setValue("state", "");
    }
  }, [country]);

  useEffect(() => {
    if (countryAutoSearchResults.data.length !== 0) {
      setCountryOptions([...countryOptions, ...countryAutoSearchResults.data]);
    }
  }, [countryAutoSearchResults]);

  const fetchStates = async (countryId) => {
    if (countryId) {
      searchPayloadValue["filters"] = [
        {
          fieldName: "countryId",
          fieldValue: countryId,
          op: "eq",
          dataType: "int",
          condition: "and",
          isJoin: true,
        },
      ];
      await autoSearchState(searchPayloadValue);
    }
  };

  return (
    <Grid container spacing={6} className="row">
      <Grid item xs={8.5} sm={12} md={6} lg={6} xl={4}>
        <SearchableAutoComplete
          control={control}
          data={countryOptions}
          label={t("country_name")}
          payload={payload}
          setPayload={setPayload}
          loading={isLoadingAutoSearch}
          name="country"
          multiple={false}
          fields={fields}
          defaultValue={country}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("state_name")}
          name="stateName"
          control={control}
          fullWidth
          variant="outlined"
          fields={fields}
          errors={errors}
        />
      </Grid>
      <Grid item xs={8.5} sm={12} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          name="status"
          label={t("Status")}
          control={control}
          fullWidth
          variant="outlined"
          fields={fields}
          errors={errors}
        >
          <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
          <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
};
export default StateFields;
