import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { spacing } from "@mui/system";

import SaveCancel from "../../../components/SaveCancel";
import useNasGroup from "../../../hooks/useNasGroup";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import BasicDetail from "./components/BasicDetail";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import AllowedNas from "./components/AllowedNas";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const validationSchema = Yup.object().shape({
  nasGroupName: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

function BasicForm(props) {
  const dispatch = useDispatch();
  const form = React.useRef();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { reset, handleSubmit, setValue } = methods;

  const { getNasGroup, nasGroup, updateNasGroup, isSubmitting } = useNasGroup();
  React.useEffect(() => {
    console.log(nasGroup, " values: formValues,");

    if (Object.keys(nasGroup).length !== 0) {
      const initialValues = {
        nasGroupId: nasGroup.nasGroupId,
        nasGroupName: nasGroup.nasGroupName,
        description: nasGroup.description,
        region: {
          label: nasGroup.region?.stateName,
          value: nasGroup.region?.stateId,
        },
        status: nasGroup.status,
      };
      //console.log(initialValues, "initialValues");
      reset(initialValues);
    }
  }, [nasGroup]);

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const { state } = useLocation();
  console.log(state, "location");
  const { t } = props;

  React.useEffect(() => {
    if (form.current) {
      const { values, errors } = form.current;
      console.log(errors, "i am here to update values");
      dispatch({
        type: "UPDATE_FORM",
        payload: {
          id: "branch",
          data: { values, errors },
        },
      });
    }
  }, [tabValue]);

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      if (data.region != null) {
        data["region"] = {
          stateId: data.region.value,
          stateName: data.region.label,
        };
      }
      await updateNasGroup(data);
      dispatch(showSuccessSnackbar("Nas group updated successfully!"));
      //setStatus({ sent: true });
      //reset();
      cancelBtn();
    } catch (err) {
      //setStatus({ sent: false });
      //setErrorSubmit({ submit: err.message });
      dispatch(showErrorSnackbar(err.message));
    }
  };

  const cancelBtn = () => {
    navigate("/radius/nas-group/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>C-Panal</Typography>
        <Typography>Nas Group</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getNasGroup(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_nas_group")} subheader={breadCrump()} />
          <CardContent>
            {/* {status && status.sent && (
              <Alert severity="success" my={3}>
                Branch updated successfully!
              </Alert>
            )} */}
            {/* {errorSubmit && errorSubmit.submit && (
              <Alert severity="error" my={3}>
                {errorSubmit.submit}
              </Alert>
            )} */}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={tabValue} onChange={handleTabChange} aria-label="Nas Group">
                    <Tab label="Basic Details" {...a11yProps(0)} />
                    <Tab label="Allowed Nas" {...a11yProps(1)} />
                  </Tabs>
                  <TabPanel value={tabValue} index={0}>
                    <BasicDetail validationFields={validationSchema.fields} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <AllowedNas nasGroupId={state?.id} fields={validationSchema.fields} />
                  </TabPanel>
                </Box>

                <SaveCancel cancelBtn={cancelBtn} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

function EditNasGroup() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <BasicForm t={t} />
    </React.Fragment>
  );
}

export default EditNasGroup;
