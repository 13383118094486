import React from "react";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { DialogActions, Grid, Button } from "@mui/material";

import CustomTextField from "../../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../../components/ReactHookFormSelect";
import { useDialog } from "../../../../../contexts/DialogContext";
import SaveCancel from "../../../../../components/SaveCancel";
import useRate from "../../../../../hooks/useRate";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../../redux/slices/snackbar";
import AddIcon from "@mui/icons-material/Add";

const AddRate = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { unSetDialog } = useDialog();
  const { addRate, getAllRate, isSubmitting } = useRate();
  const { planId } = props;

  const currentPayload = {
    filters: [
      {
        fieldName: "planId",
        fieldValue: planId,
        op: "eq",
        dataType: "int",
        isJoin: true,
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };

  const validationSchema = Yup.object().shape({
    rate: Yup.number()
      .nullable()
      .notRequired()
      .min(100)
      .max(10000)
      .test(
        "noEOrSign", // type of the validator (should be unique)
        "Please enter valid number", // error message
        (value) => typeof value === "number" && !/[eE+-]/.test(value.toString()),
      ),
    timeUnit: Yup.number()
      .nullable()
      .notRequired()
      .min(1)
      .max(1000)
      .test(
        "noEOrSign", // type of the validator (should be unique)
        "Please enter valid number", // error message
        (value) => typeof value === "number" && !/[eE+-]/.test(value.toString()),
      ),
    unitType: Yup.string().required("Required"),
    status: Yup.string().required("Required"),
  });

  const {
    formState: { errors },
    control,
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    try {
      console.log(data, "datadatadata");
      await addRate(data);
      reset();
      dispatch(showSuccessSnackbar("Rate added successfully!"));
      fetchData();
      unSetDialog("add_rate");
    } catch (error) {
      console.log(error);
      dispatch(showErrorSnackbar(error.message));
    }
  };

  React.useEffect(() => {
    if (planId) {
      const initialValues = {
        plan: {
          planId: planId,
        },
        rate: null,
        timeUnit: null,
        unitType: "",
        status: "ACTIVE",
      };
      reset(initialValues);
    }
  }, [planId]);

  const fetchData = async () => {
    await getAllRate(currentPayload);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={12} className="row" pt={8}>
        <Grid item md={6}>
          <CustomTextField
            label={t("rate")}
            name="rate"
            control={control}
            fullWidth
            variant="outlined"
            errors={errors}
            required={true}
          />
        </Grid>
        <Grid item md={6}>
          <CustomTextField
            label={t("time_unit")}
            name="timeUnit"
            control={control}
            fullWidth
            variant="outlined"
            errors={errors}
            required={true}
          />
        </Grid>
        <Grid item xs={9.5} sm={6} md={6} lg={6} xl={4}>
          <ReactHookFormSelect
            name="unitType"
            label={t("unit_type")}
            control={control}
            fullWidth
            variant="outlined"
            errors={errors}
            isRequired={true}
          >
            <MenuItem value={"DAYS"}>Days</MenuItem>
            <MenuItem value={"MONTHS"}>Months</MenuItem>
          </ReactHookFormSelect>
        </Grid>
        <Grid item xs={9.5} sm={6} md={6} lg={6} xl={4}>
          <ReactHookFormSelect
            name="status"
            label={t("Status")}
            control={control}
            fullWidth
            variant="outlined"
            errors={errors}
            isRequired={true}
          >
            <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
            <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
          </ReactHookFormSelect>
        </Grid>
      </Grid>
      <DialogActions>
        <Button
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          endIcon={isSubmitting ? <CircularProgress size={24} /> : null}
        >
          Add
        </Button>

        <Button variant="text" onClick={() => unSetDialog("map_plan_modal")} color="primary">
          Close
        </Button>
      </DialogActions>
    </form>
  );
};

export default AddRate;
