import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import BulkOperationReducer from "../reducers/BulkOperationReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { BulkOperationConstants } from "../constants/bulkoperation.constants";
import { saveFile } from "../utils/common";
const initialState = {
  hasError: false,
  errorMessage: "",
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
};

const BulkOperationContext = createContext({
  ...initialState,
  sampleFile: () => {},
  uploadFile: () => {},
});

function BulkOperationProvider({ children }) {
  const [state, dispatch] = useReducer(BulkOperationReducer, initialState);

  const sampleFile = async (fileType) => {
    const response = await axios.get("/radius/api/addon/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_addon." + fileType);
    dispatch({
      type: BulkOperationConstants.DOWNLOAD_BULK_OPERATION_REQUEST,
    });
    dispatch({
      type: BulkOperationConstants.DOWNLOAD_BULK_OPERATION_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: BulkOperationConstants.UPLOAD_BULK_OPERATION_REQUEST,
      });
      const response = await axios({
        url: "/radius/api/bulk-operation/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: BulkOperationConstants.UPLOAD_BULK_OPERATION_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: BulkOperationConstants.UPLOAD_BULK_OPERATION_FAILURE,
      });
    }
  };

  return (
    <BulkOperationContext.Provider
      value={{
        ...state,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </BulkOperationContext.Provider>
  );
}

export { BulkOperationContext, BulkOperationProvider };
