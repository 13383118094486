import { PlanGroupConstants } from "../constants/plan_group.constants";

const PlanGroupReducer = (state, action) => {
  switch (action.type) {
    case PlanGroupConstants.FETCH_PLAN_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PlanGroupConstants.FETCH_PLAN_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        planGroups: action.payload.data,
        paging: action.payload.paging,
      };
    case PlanGroupConstants.FETCH_PLAN_GROUP_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case PlanGroupConstants.FETCH_PLAN_GROUP_PLAN_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PlanGroupConstants.FETCH_PLAN_GROUP_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        planGroupPlans: action.payload.data,
        paging: action.payload.paging,
      };
    case PlanGroupConstants.FETCH_PLAN_GROUP_PLAN_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PlanGroupConstants.PLAN_GROUP_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PlanGroupConstants.PLAN_GROUP_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        planGroupSearchResults: action.payload,
      };
    case PlanGroupConstants.PLAN_GROUP_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PlanGroupConstants.ADD_PLAN_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PlanGroupConstants.ADD_PLAN_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case PlanGroupConstants.ADD_PLAN_GROUP_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };

    case PlanGroupConstants.ADD_PLAN_GROUP_PLAN_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PlanGroupConstants.ADD_PLAN_GROUP_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case PlanGroupConstants.ADD_PLAN_GROUP_PLAN_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        errorMessage: action.payload.message,
      };

    case PlanGroupConstants.GET_PLAN_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PlanGroupConstants.GET_PLAN_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        planGroup: action.payload,
      };
    case PlanGroupConstants.GET_PLAN_GROUP_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PlanGroupConstants.UPDATE_PLAN_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PlanGroupConstants.UPDATE_PLAN_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        planGroup: action.payload,
      };
    case PlanGroupConstants.UPDATE_PLAN_GROUP_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case PlanGroupConstants.DOWNLOAD_PLAN_GROUP_REQUEST:
      console.log("action33333333", action.payload);
      return {
        ...state,
        isLoadingDownload: true,
        hasError: false,
        downloadFileType: action.payload.fileType,
      };
    case PlanGroupConstants.DOWNLOAD_PLAN_GROUP_SUCCESS:
      return {
        ...state,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case PlanGroupConstants.DOWNLOAD_PLAN_GROUP_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingDownload: false,
        downloadFileType: "",
      };
    case PlanGroupConstants.UPLOAD_PLAN_GROUP_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case PlanGroupConstants.UPLOAD_PLAN_GROUP_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case PlanGroupConstants.UPLOAD_PLAN_GROUP_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };
    case PlanGroupConstants.ADD_PLAN_GROUP_TENANT_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case PlanGroupConstants.ADD_PLAN_GROUP_TENANT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case PlanGroupConstants.ADD_PLAN_GROUP_TENANT_FAILURE:
      return {
        ...state,
        hasError: true,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case PlanGroupConstants.FETCH_PLAN_GROUP_TENANT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case PlanGroupConstants.FETCH_PLAN_GROUP_TENANT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addOnTenants: action.payload.data,
        paging: action.payload.paging,
      };
    case PlanGroupConstants.FETCH_PLAN_GROUP_TENANT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };

    case PlanGroupConstants.PLAN_GROUP_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoadingAutoSearch: true,
        hasError: false,
      };
    case PlanGroupConstants.PLAN_GROUP_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoadingAutoSearch: false,
        planGroupAutoSearchResults: action.payload,
      };
    case PlanGroupConstants.PLAN_GROUP_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingAutoSearch: false,
      };

    case PlanGroupConstants.PLAN_GROUP_PLAN_AUTO_SEARCH_REQUEST:
      return {
        ...state,
        isLoadingAutoSearch: true,
        hasError: false,
      };
    case PlanGroupConstants.PLAN_GROUP_PLAN_AUTO_SEARCH_SUCCESS:
      return {
        ...state,
        isLoadingAutoSearch: false,
        planGroupPlanAutoSearchResults: action.payload,
      };
    case PlanGroupConstants.PLAN_GROUP_PLAN_AUTO_SEARCH_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingAutoSearch: false,
      };

    case PlanGroupConstants.DELETE_PLAN_GROUP_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        hasError: false,
      };
    case PlanGroupConstants.DELETE_PLAN_GROUP_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
      };
    case PlanGroupConstants.DELETE_PLAN_GROUP_FAILURE:
      return {
        ...state,
        hasError: true,
        deleteLoading: false,
      };

    default:
      return state;
  }
};

export default PlanGroupReducer;
