import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  severity: "warning",
  isOpen: false,
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    showSuccessSnackbar: (state, action) => {
      console.log("i am here1112");
      state.message = action.payload;
      state.severity = "success";
      state.isOpen = true;
    },
    showErrorSnackbar: (state, action) => {
      state.message = action.payload;
      state.severity = "error";
      state.isOpen = true;
    },
    showInfoSnackbar: (state, action) => {
      state.message = action.payload;
      state.severity = "info";
      state.isOpen = true;
    },
    clearSnackbar: (state, action) => {
      state.message = "";
      state.severity = "warning";
      state.isOpen = false;
    },
  },
});
export const selectIsOpen = (state) => state.snackbar.isOpen;

export const { showSuccessSnackbar, showErrorSnackbar, showInfoSnackbar, clearSnackbar, isOpened } =
  snackbarSlice.actions;

export default snackbarSlice.reducer;
