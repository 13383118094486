export const PaymentProcessConstants = {
  FETCH_PAYMENT_PROCESS_REQUEST: "FETCH_PAYMENT_PROCESS_REQUEST",
  FETCH_PAYMENT_PROCESS_SUCCESS: "FETCH_PAYMENT_PROCESS_SUCCESS",
  FETCH_PAYMENT_PROCESS_FAILURE: "FETCH_PAYMENT_PROCESS_FAILURE",
  FETCH_DEPOSIT_PAYMENT_PROCESS_REQUEST: "FETCH_DEPOSIT_PAYMENT_PROCESS_REQUEST",
  FETCH_DEPOSIT_PAYMENT_PROCESS_SUCCESS: "FETCH_DEPOSIT_PAYMENT_PROCESS_SUCCESS",
  FETCH_DEPOSIT_PAYMENT_PROCESS_FAILURE: "FETCH_DEPOSIT_PAYMENT_PROCESS_FAILURE",
  ADD_PAYMENT_PROCESS_REQUEST: "ADD_PAYMENT_PROCESS_REQUEST",
  ADD_PAYMENT_PROCESS_SUCCESS: "ADD_PAYMENT_PROCESS_SUCCESS",
  ADD_PAYMENT_PROCESS_FAILURE: "ADD_PAYMENT_PROCESS_FAILURE",
  PAYMENT_PROCESS_SEARCH_REQUEST: "PAYMENT_PROCESS_SEARCH_REQUEST",
  PAYMENT_PROCESS_SEARCH_SUCCESS: "PAYMENT_PROCESS_SEARCH_SUCCESS",
  PAYMENT_PROCESS_SEARCH_FAILURE: "PAYMENT_PROCESS_SEARCH_FAILURE",
  PAYMENT_PROCESS_AUTO_SEARCH_REQUEST: "PAYMENT_PROCESS_AUTO_SEARCH_REQUEST",
  PAYMENT_PROCESS_AUTO_SEARCH_SUCCESS: "PAYMENT_PROCESS_AUTO_SEARCH_SUCCESS",
  PAYMENT_PROCESS_AUTO_SEARCH_FAILURE: "PAYMENT_PROCESS_AUTO_SEARCH_FAILURE",
  GET_PAYMENT_PROCESS_REQUEST: "GET_PAYMENT_PROCESS_REQUEST",
  GET_PAYMENT_PROCESS_SUCCESS: "GET_PAYMENT_PROCESS_SUCCESS",
  GET_PAYMENT_PROCESS_FAILURE: "GET_PAYMENT_PROCESS_FAILURE",
  UPDATE_PAYMENT_PROCESS_REQUEST: "UPDATE_PAYMENT_PROCESS_REQUEST",
  UPDATE_PAYMENT_PROCESS_SUCCESS: "UPDATE_PAYMENT_PROCESS_SUCCESS",
  UPDATE_PAYMENT_PROCESS_FAILURE: "UPDATE_PAYMENT_PROCESS_FAILURE",
  DOWNLOAD_PAYMENT_PROCESS_REQUEST: "DOWNLOAD_PAYMENT_PROCESS_REQUEST",
  DOWNLOAD_PAYMENT_PROCESS_SUCCESS: "DOWNLOAD_PAYMENT_PROCESS_SUCCESS",
  DOWNLOAD_PAYMENT_PROCESS_FAILURE: "DOWNLOAD_PAYMENT_PROCESS_FAILURE",
  UPLOAD_PAYMENT_PROCESS_REQUEST: "UPLOAD_PAYMENT_PROCESS_REQUEST",
  UPLOAD_PAYMENT_PROCESS_SUCCESS: "UPLOAD_PAYMENT_PROCESS_SUCCESS",
  UPLOAD_PAYMENT_PROCESS_FAILURE: "UPLOAD_PAYMENT_PROCESS_FAILURE",
};
