import React from "react";
import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Paper as MuiPaper,
  CardContent,
  Box,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import EditDelete from "../../../../../components/EditDelete";
import Toolbar from "../../../../../components/Toolbar";
import useMainContentDimensions from "../../../../../utils/mainContentDimensions";
import useWindowDimensions from "../../../../../utils/windowDimensions";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const CustomDataGrid = (props) => {
  const { width: mainContentWidth } = useMainContentDimensions();
  const { height } = useWindowDimensions();

  const {
    editPage,
    addPage,
    trans,
    data,
    rowCount,
    paging,
    onPageChange,
    isLoading,
    id,
    handleSortModelChange,
    sortModel,
    downloadFile,
    openDownloadDialog,
  } = props;
  console.log(isLoading, "1111111");

  const columns = [
    {
      field: "action",
      headerName: "Action",
      filter: false,
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;
          const thisRow = {};
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          console.log(thisRow, "thisRow");
        };
        return (
          <EditDelete
            rowClick={onClick}
            editPage={() => editPage(params.id)}
            rowId={params.id}
            menus={["edit"]}
          />
        );
      },
    },
    {
      field: "rateFullName",
      headerName: "Rate Name",
      width: 300,
    },
    {
      field: "rateLabel",
      headerName: "Rate",
      width: 300,
    },
    {
      field: "timeUnit",
      headerName: "Time Unit",
      width: 200,
    },
    {
      field: "unitType",
      headerName: "Unit Type",
      width: 200,
    },
    {
      field: "status",
      headerName: "status",
      width: 200,
    },
  ];
  return (
    <Card sx={{ width: "100%", maxWidth: mainContentWidth - 64 }}>
      <CardHeader
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // Stack on small screens
          justifyContent: "space-between",
          alignItems: "flex-start", // Adjust alignment as needed
        }}
        action={
          <Toolbar
            addPage={addPage}
            downloadFile={downloadFile}
            openDownloadDialog={openDownloadDialog}
          />
        }
      />
      <CardContent sx={{ height: height - 180, display: "flex", flexDirection: "column" }}>
        <Box sx={{ flex: 1, overflow: "auto" }}>
          <DataGrid
            columns={columns}
            rows={data}
            rowCount={rowCount}
            getRowId={(row) => row[id]}
            loading={isLoading}
            rowsPerPageOptions={[5]}
            pagination
            page={paging.pageNumber}
            pageSize={paging.pageSize}
            paginationMode="server"
            onPageChange={(page) => onPageChange(page)}
            onPageSizeChange={(pageSize) => onPageChange(pageSize)}
            checkboxSelection
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
CustomDataGrid.propTypes = {
  editPage: PropTypes.func.isRequired,
  addPage: PropTypes.func.isRequired,
  trans: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  paging: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  handleSortModelChange: PropTypes.func.isRequired,
  sortModel: PropTypes.object.isRequired,
};
export default CustomDataGrid;
