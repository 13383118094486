//Masters

import TenantList from "../pages/cpanel/tenant";
import AddTenant from "../pages/cpanel/tenant/add";
import EditTenant from "../pages/cpanel/tenant/edit";
import BranchList from "../pages/cpanel/branch";
import AddBranch from "../pages/cpanel/branch/add";
import EditBranch from "../pages/cpanel/branch/edit";
import DepartmentList from "../pages/cpanel/department";
import AddDepartment from "../pages/cpanel/department/add";
import EditDepartment from "../pages/cpanel/department/edit";

import TenantSharing from "../pages/cpanel/tenant_sharing";

//Composers

import { TenantComposer } from "../composers/TenantComposer";
import { BranchComposer } from "../composers/BranchComposer";
import { EmployeeComposer } from "../composers/EmployeeComposer";

import { DepartmentComposer } from "../composers/DepartmentComposer";

import { TenantSharingComposer } from "../composers/TenantSharingComposer";

import { DashboardComposer } from "../composers/DashboardComposer";
// Guards
import AuthGuard from "../components/guards/AuthGuard";
import DashboardLayout from "../layouts/Dashboard";
import AddEmployee from "../pages/cpanel/employee/add";
import EditEmployee from "../pages/cpanel/employee/edit";
import EmployeeList from "../pages/cpanel/employee";
import TenantDetailPage from "../pages/cpanel/tenant/TenantDetailPage";
import ProtectedLayout from "../layouts/ProtectedLayout";

const CpanelRoutes = {
  path: "cpanel",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  roles: ["SUPER_ADMIN"],
  children: [
    {
      path: "tenant",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <TenantComposer>
                <AddTenant />
              </TenantComposer>
            </ProtectedLayout>
          ),
          roles: ["SUPER_ADMIN"],
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <TenantComposer>
                <EditTenant />
              </TenantComposer>
            </ProtectedLayout>
          ),
          roles: ["SUPER_ADMIN"],
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <TenantComposer>
                <TenantList />
              </TenantComposer>
            </ProtectedLayout>
          ),
          roles: ["SUPER_ADMIN"],
        },
        {
          path: "details",
          element: (
            <ProtectedLayout>
              <TenantComposer>
                <TenantDetailPage loadingFrom="normalPage" />
              </TenantComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "branch",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <BranchComposer>
                <AddBranch />
              </BranchComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <BranchComposer>
                <EditBranch />
              </BranchComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <BranchComposer>
                <BranchList />
              </BranchComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "department",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <DepartmentComposer>
                <AddDepartment />
              </DepartmentComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <DepartmentComposer>
                <EditDepartment />
              </DepartmentComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <DepartmentComposer>
                <DepartmentList />
              </DepartmentComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },

    {
      path: "sharing",
      children: [
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <TenantSharingComposer>
                <TenantSharing />
              </TenantSharingComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "employee",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <EmployeeComposer>
                <AddEmployee />
              </EmployeeComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <EmployeeComposer>
                <EditEmployee />
              </EmployeeComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <EmployeeComposer>
                <EmployeeList />
              </EmployeeComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
  ],
};

export default CpanelRoutes;
