import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import CustomTextField from "../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import useTenant from "../../../../hooks/useTenant";
import useAuth from "../../../../hooks/useAuth";
import CustomDatePicker from "../../../../components/CustomDatePicker";
import usePlanGroup from "../../../../hooks/usePlanGroup";

const currentTenantPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};
const currentPlanGroupPlanPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const CardInfo = (props) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { validationFields } = props;

  const [tenantPayload, setTenantPayload] = useState(currentTenantPayload);
  const [tenantOptions, setTenantOptions] = useState([]);
  const { autoSearchTenant, tenantAutoSearchResults } = useTenant();

  const { autoSearchPlanGroupPlan, planGroupPlanAutoSearchResults } = usePlanGroup();
  const [planGroupPlanPayload, setPlanGroupPlanPayload] = useState(currentPlanGroupPlanPayload);
  const [planGroupPlanOptions, setPlanGroupPlanOptions] = useState([]);

  const fetchTenants = async () => {
    await autoSearchTenant(tenantPayload);
  };

  useEffect(() => {
    fetchTenants();
  }, [tenantPayload]);

  const fetchPlanGroupPlans = async () => {
    await autoSearchPlanGroupPlan(planGroupPlanPayload);
  };

  useEffect(() => {
    fetchPlanGroupPlans();
  }, [planGroupPlanPayload]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (tenantAutoSearchResults.data.length !== 0) {
      const results = [...tenantOptions, ...tenantAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setTenantOptions(filteredResults);
    }
  }, [tenantAutoSearchResults]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (planGroupPlanAutoSearchResults.data.length !== 0) {
      const results = [...planGroupPlanOptions, ...planGroupPlanAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setPlanGroupPlanOptions(filteredResults);
    }
  }, [planGroupPlanAutoSearchResults]);

  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();
  return (
    <Grid container spacing={12} className="row" pt={8}>
      <Grid item xs={8} sm={6} md={6} lg={6} xl={4}>
        <SearchableAutoComplete
          name="tenant"
          label={t("franchise_name")}
          control={control}
          data={tenantOptions}
          payload={tenantPayload}
          setPayload={setTenantPayload}
          loading={false}
          multiple={false}
          defaultValue={""}
          hasNextPage={false}
          fields={validationFields}
          fullWidth
          variant="outlined"
          errors={errors}
        ></SearchableAutoComplete>
      </Grid>
      <Grid item xs={8} sm={6} md={6} lg={6} xl={4}>
        <SearchableAutoComplete
          control={control}
          data={planGroupPlanOptions}
          label={t("select_plan")}
          payload={planGroupPlanPayload}
          setPayload={setPlanGroupPlanPayload}
          loading={false}
          name="plan"
          multiple={false}
          defaultValue={""}
          hasNextPage={false}
          fields={validationFields}
          errors={errors}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("quantity")}
          name="quantity"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("gross_card_value")}
          name="cardValue"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomDatePicker
          name="validTill"
          nameTemp="validTillTemp"
          label={t("valid_till")}
          range={false}
          control={control}
          fields={validationFields}
          timePicker={true}
          format="DD-MM-YYYY HH:mm:ss"
          conversionFormat="YYYY-MM-DD HH:mm:ss"
          minDate={new Date()}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("prefix")}
          name="prefix"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item xs={8} sm={6} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          name="pinLength"
          label={t("pin_length")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          <MenuItem value={"5"}>5</MenuItem>
          <MenuItem value={"6"}>6</MenuItem>
          <MenuItem value={"7"}>7</MenuItem>
          <MenuItem value={"8"}>8</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      <Grid item xs={8} sm={6} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          name="passwordLength"
          label={t("password_length")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          <MenuItem value={"4"}>4</MenuItem>
          <MenuItem value={"5"}>5</MenuItem>
          <MenuItem value={"6"}>6</MenuItem>
          <MenuItem value={"7"}>7</MenuItem>
          <MenuItem value={"8"}>8</MenuItem>
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
};

export default CardInfo;
