import React, { useEffect, useState } from "react";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import { useDialog } from "../../../../contexts/DialogContext";
import SaveIcon from "@mui/icons-material/Save";

import { Grid, DialogActions, Button, CircularProgress } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import usePlanGroup from "../../../../hooks/usePlanGroup";

function DeletePlanGroupModal(props) {
  const { planSharingId, refreshPage } = props;

  const { deletePlanGroupPlan, deleteLoading } = usePlanGroup();
  const dispatch = useDispatch();
  const { unSetDialog } = useDialog();

  const methods = useForm();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async () => {
    try {
      await deletePlanGroupPlan(planSharingId);
      dispatch(showSuccessSnackbar("Plan group deleted successfully!"));
      refreshPage();
      unSetDialog("delete_plan_group");
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to delete plan group"));
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} className="row" pt={8}>
            <Grid item md={6}>
              <i>Do you want to delete this sharing ?</i>
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              endIcon={deleteLoading ? <CircularProgress size={24} /> : null}
              disabled={false}
            >
              Yes
            </Button>

            <Button variant="text" onClick={() => unSetDialog("delete_plan_group")} color="primary">
              No
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default DeletePlanGroupModal;
