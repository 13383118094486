import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";

import SaveCancel from "../../../components/SaveCancel";
import useMaterial from "../../../hooks/useMaterial";
import MaterialFields from "./components/MaterialFields";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  materialName: Yup.string().required("Required"),
  uom: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

function EditMaterial() {
  const { getMaterial, material, updateMaterial, isSubmitting } = useMaterial();

  React.useEffect(() => {
    if (Object.keys(material).length !== 0) {
      console.log(material, "material.status");
      const initialValues = {
        materialId: material.materialId,
        materialName: material.materialName,
        uom: material.uom,
        status: material.status,
      };
      reset(initialValues);
    }
  }, [material]);

  const dispatch = useDispatch();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });

  const [currentMaterial, setCurrentMaterial] = useState(null);
  const { state } = useLocation();
  console.log(state, "location");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      await updateMaterial(data);
      dispatch(showSuccessSnackbar("Material updated successfully!"));
      navigate("/inventory-mgnt/materials/list");
      reset();
    } catch (err) {
      dispatch(showErrorSnackbar("Failed to update Material"));
    }
  };

  const cancelBtn = () => {
    navigate("/masters/material/list");
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Inventory Mgnt</Typography>
        <Typography>Materials</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getMaterial(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_material")} subheader={breadCrump()} />
          <CardContent>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                Material updated successfully!
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <MaterialFields fields={validationSchema.fields} />
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default EditMaterial;
