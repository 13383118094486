import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Grid, FormControlLabel, Checkbox } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomTextField from "../../../../../components/CustomTextField";
import useTenant from "../../../../../hooks/useTenant";
import useAuth from "../../../../../hooks/useAuth";

const currentTenantPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const SmsApi = (props) => {
  const { user } = useAuth();
  const { validationFields } = props;
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();
  const { t } = useTranslation();

  return (
    <Grid container spacing={12} className="row" pt={8}>
      {user && user.businessType === "SUPER_ADMIN" && (
        <Grid item md={6}>
          <CustomTextField
            label={t("host")}
            name="smsUrl"
            type="text"
            my={2}
            control={control}
            fullWidth
            variant="outlined"
            errors={errors}
            fields={validationFields}
          />
        </Grid>
      )}

      <Grid item md={6}>
        <CustomTextField
          label={t("userName")}
          name="smsUserName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>

      <Grid item md={6}>
        <CustomTextField
          label={t("password")}
          name="smsPassword"
          type="password"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
    </Grid>
  );
};

export default SmsApi;
