import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";
import { showSuccessSnackbar, showErrorSnackbar } from "../../redux/slices/snackbar";

import SaveCancel from "../../components/SaveCancel";
import useComplaint from "../../hooks/useComplaint";
import ComplaintFields from "./components/ComplaintFields";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  userName: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  complaintType: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  mobileNo: Yup.string()
    .required("Required")
    .matches(/^\d{10}$/, "Must be a valid phone number with up to 10 digits"),
  priority: Yup.string().required("Required"),
  subject: Yup.string().required("Required"),
  comments: Yup.string().required("Required"),
});

function EditComplaint() {
  const dispatch = useDispatch();

  const { getComplaint, complaint, updateComplaint, isSubmitting } = useComplaint();

  React.useEffect(() => {
    if (Object.keys(complaint).length !== 0) {
      console.log(complaint.status, "complaint.status");
      const initialValues = {
        userName: {
          label: complaint.radUser.userName,
          value: complaint.radUser.radUserId,
        },
        employee: {
          label: complaint.employee.employeeName,
          value: complaint.employee.employeeId,
        },
        complaintType: {
          label: complaint.complaintType.complaintTypeName,
          value: complaint.complaintType.complaintTypeId,
        },
        complaintId: complaint.complaintId,
        complaintName: complaint.complaintName,
        comments: complaint.comments,
        status: complaint.status,
        priority: complaint.priority,
        subject: complaint.subject,
      };
      reset(initialValues);
    }
  }, [complaint]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [status, setStatus] = useState({
    sent: false,
  });

  const [errorSubmit, setErrorSubmit] = useState({
    submit: "",
  });

  const [currentComplaint, setCurrentComplaint] = useState(null);
  const { state } = useLocation();
  console.log(state, "location");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      data["radUser"] = {
        radUserId: data.userName.value,
      };

      data["complaintType"] = {
        complaintTypeId: data.complaintType.value,
      };
      await updateComplaint(data);
      dispatch(showSuccessSnackbar("Complaint  edited successfully!"));
      cancelBtn();
    } catch (err) {
      // setStatus({ sent: false });
      // setErrorSubmit({ submit: err.message });
      dispatch(showErrorSnackbar("Failed to edit complaint "));
    }
  };

  const cancelBtn = () => {
    if (Object.keys(state).length != 0) {
      if (state.backTo == undefined) {
        navigate("/complaints/list");
      } else {
        navigate(state?.backTo);
      }
    } else {
      navigate("/complaints/list");
    }
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Masters</Typography>
        <Typography>complaint</Typography>
        <Typography>Edit</Typography>
      </Breadcrumbs>
    );
  };

  const fetchData = async () => {
    await getComplaint(state.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("edit_complaint")} subheader={breadCrump()} />
          <CardContent>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                complaint updated successfully!
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <ComplaintFields hideAssignee={true} userNameDisabled={true} />
                <br></br>
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default EditComplaint;
