import {
  BookOpen,
  Briefcase,
  Calendar,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  ShoppingCart,
  PieChart,
  Sliders,
  Users,
  Settings,
  Database,
  Framer,
  Aperture,
  Archive,
  Server,
  Account,
} from "react-feather";

import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import DashboardIcon from "@mui/icons-material/Dashboard";
import docsSection from "./docsSection";
import elementsSection from "./elementsSection";
import { Email } from "@mui/icons-material";
import AddCardIcon from "@mui/icons-material/AddCard";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import StorefrontIcon from "@mui/icons-material/Storefront";
import InventoryIcon from "@mui/icons-material/Inventory";
import BusinessIcon from "@mui/icons-material/Business";
import TimelineIcon from "@mui/icons-material/Timeline";
const pagesSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Dashboard",
    href: "/dashboard/default",
    // children: [
    //   {
    //     href: "/dashboard/default",
    //     title: "Default",
    //   },
    // {
    //   href: "/dashboard/analytics",
    //   title: "Analytics",
    // },
    // {
    //   href: "/dashboard/saas",
    //   title: "SaaS",
    // },
    //],
  },
  // {
  //   href: "/user-dashboard",
  //   icon: DashboardIcon,
  //   title: "User Dashboard",
  //   href: "/user-dashboard/default",
  // },
  {
    href: "/radius",
    icon: Aperture,
    title: "Radius",
    children: [
      {
        href: "/radius/nas/list",
        title: "Nas",
      },
      {
        href: "/radius/nas-group/list",
        title: "Nas Group",
      },
      {
        href: "/radius/ippool/list",
        title: "Ip Pool",
      },
      {
        href: "/radius/plan/list",
        title: "Plan",
      },
      {
        href: "/radius/plan-group/list",
        title: "Plan Group",
      },
      {
        href: "/radius/addon/list",
        title: "Add On",
      },
    ],
  },
  {
    href: "/wallet/list",
    icon: AccountBalanceWalletIcon,
    title: "Wallet",
  },
  {
    href: "/cpanel",
    icon: Framer,
    title: "CPanel",
    children: [
      {
        href: "/cpanel/tenant/list",
        title: "Franchise",
      },
      {
        href: "/cpanel/branch/list",
        title: "Branch",
      },
      // {
      //   href: "/cpanel/wallet/list",
      //   title: "Wallet",
      // },
      // {
      //   href: "/cpanel/sharing/list",
      //   title: "Tenant Sharing",
      // },

      {
        href: "/cpanel/employee/list",
        title: "Employee",
      },
    ],
  },
  {
    href: "/masters",
    icon: Database,
    title: "Masters",
    children: [
      {
        href: "/masters/country/list",
        title: "Country",
      },
      {
        href: "/masters/state/list",
        title: "State",
      },
      {
        href: "/masters/district/list",
        title: "District",
      },
      {
        href: "/masters/taluk/list",
        title: "Taluk",
      },
      // {
      //   href: "/masters/city/list",
      //   title: "City",
      // },
      // {
      //   href: "/masters/village/list",
      //   title: "Village",
      // },
      // {
      //   href: "/masters/pincode/list",
      //   title: "Pincode",
      // },
      {
        href: "/masters/emailmaster/list",
        title: "Email Template",
      },
      {
        href: "/masters/smstemplate/list",
        title: "Sms Template",
      },
    ],
  },
  {
    href: "/user",
    icon: Users,
    title: "User",
    children: [
      {
        href: "/user/userlist/list",
        title: "List",
      },
      {
        href: "/user/active",
        title: "Active Users",
      },
      {
        href: "/user/online",
        title: "Online Users",
      },
      {
        href: "/user/expired",
        title: "Expired Users",
      },
      {
        href: "/user/suspended",
        title: "Suspended Users",
      },
      {
        href: "/user/migration",
        title: "Migration",
      },
      {
        href: "/user/session-history",
        title: "Session History",
      },
      {
        href: "/user/bulk",
        title: "Bulk Operation",
      },
      {
        href: "/user/schedule-user",
        title: "Schedule User",
      },
    ],
  },
  // {
  //   href: "/settings",
  //   icon: Settings,
  //   title: "Settings",
  //   children: [
  //     {
  //       href: "/settings/smsapi/list",
  //       title: "SMS API",
  //     },
  //     {
  //       href: "/settings/whatsapp/list",
  //       title: "Whats App API",
  //     },
  //   ],
  // },
  {
    href: "/reports",
    icon: Sliders,
    title: "Reports",
    children: [
      {
        href: "/reports/renewal",
        title: "Renewal Report",
      },
      {
        href: "/reports/dues",
        title: "Dues Report",
      },
      // {
      //   href: "/reports/collection",
      //   title: "Collection Report",
      // },
      {
        href: "/reports/wallet",
        title: "Wallet Report",
      },
      {
        href: "/reports/revenueshare",
        title: "Revenue Share Report",
      },
      {
        href: "/reports/gst-invoices",
        title: "GST Invoices",
      },
      {
        href: "/reports/balance-statement",
        title: "Balance Statement",
      },
      {
        href: "/reports/material-reports",
        title: "Material Reports",
      },
      // {
      //   href: "/reports/paymentgateway",
      //   title: "Payment Gateway Report",
      // },
      // {
      //   href: "/reports/tenant-payment-gateway",
      //   title: "Tenant Payment Gateway Report",
      // },
      // {
      //   href: "/reports/hotel-activation",
      //   title: "Hotel Activation Report",
      // },
    ],
  },
  {
    href: "/complaints",
    icon: Archive,
    title: "Complaints",
    children: [
      {
        href: "/complaints/list",
        title: "Complaint List",
      },
      {
        href: "/complaints/stats",
        title: "Complaint Stats",
      },
      {
        href: "/complaints/closed",
        title: "Complaint Closed",
      },
      {
        href: "/complaints/type/list",
        title: "Complaint Type",
      },
      // {
      //   href: "/complaints/details",
      //   title: "Complaint Details",
      // },
    ],
  },
  {
    href: "/leads",
    icon: Archive,
    title: "Leads",
    children: [
      {
        href: "/leads/list",
        title: "Leads List",
      },
      {
        href: "/leads/stats",
        title: "Leads Stats",
      },
      {
        href: "/leads/closed",
        title: "Leads Closed",
      },
    ],
  },
  {
    href: "/invoices",
    icon: CreditCard,
    title: "Invoices",
    children: [
      {
        href: "/invoices/unpaid",
        title: "Unpaid Invoices",
      },
      {
        href: "/invoices/paid",
        title: "Paid Invoices",
      },
    ],
  },
  {
    href: "/card-system",
    icon: CardMembershipIcon,
    title: "Card System",
    children: [
      {
        href: "/card-system/generate-card/list",
        title: "Generate Card",
      },

      {
        href: "/card-system/prepaid-card/list",
        title: "List Prepaid Cards",
      },

      {
        href: "/card-system/online-card/list",
        title: "Online Cards",
      },
      {
        href: "/card-system/card-stastics/",
        title: "Card Statistics",
      },
      {
        href: "/card-system/card-session-history/",
        title: "Card Session History",
      },
    ],
  },
  {
    href: "/hms",
    icon: StorefrontIcon,
    title: "HMS",
    children: [
      {
        href: "/hms/hotel-user/list",
        title: "Hotel Users",
      },
      {
        href: "/hms/activate-user/list",
        title: "Activate User",
      },
      {
        href: "/hms/deactivate-user/list",
        title: "Deactivate User",
      },
    ],
  },
  {
    href: "/inventory-mgnt",
    icon: InventoryIcon,
    title: "Inventory Mgnt",
    children: [
      {
        href: "/inventory-mgnt/materials/list",
        title: "Materials",
      },
    ],
  },
  {
    href: "/mikrotik-mgnt",
    icon: BusinessIcon,
    title: "Mikrotik Mgnt",
    children: [
      {
        href: "/mikrotik-mgnt/addresses/list",
        title: "IP Address",
      },
      {
        href: "/mikrotik-mgnt/routes/list",
        title: "IP Routes",
      },
      {
        href: "/mikrotik-mgnt/pool/list",
        title: "IP Pool",
      },
      {
        title: "Radius",
        href: "/mikrotik-mgnt/mk-radius/list",
      },
      {
        href: "/mikrotik-mgnt/ppp-profile/list",
        title: "PPP Profile",
      },
      {
        href: "/mikrotik-mgnt/ppp-secret/list",
        title: "PPP Secrets",
      },
      {
        href: "/mikrotik-mgnt/ppp-server/list",
        title: "PPP Server",
      },
    ],
  },
  {
    href: "/agreement-tracker/list",
    icon: TimelineIcon,
    title: "Agreement Tracker",
  },
  // {
  //   href: "/logs",
  //   icon: Server,
  //   title: "Logs",
  //   children: [
  //     {
  //       href: "/logs/email",
  //       title: "Email Logs",
  //     },
  //     {
  //       href: "/logs/sms",
  //       title: "SMS Logs",
  //     },
  //     {
  //       href: "/logs/error",
  //       title: "Error Logs",
  //     },
  //     {
  //       href: "/logs/schedule",
  //       title: "Schedule Logs",
  //     },
  //   ],
  // },
  // {
  //   href: "/pages",
  //   icon: Layout,
  //   title: "Pages",
  //   children: [
  //     {
  //       href: "/pages/profile",
  //       title: "Profile",
  //     },
  //     {
  //       href: "/pages/settings",
  //       title: "Settings",
  //     },
  //     {
  //       href: "/pages/pricing",
  //       title: "Pricing",
  //     },
  //     {
  //       href: "/pages/chat",
  //       title: "Chat",
  //     },
  //     {
  //       href: "/pages/blank",
  //       title: "Blank Page",
  //     },
  //   ],
  // },
  {
    href: "/logs",
    icon: Email,
    title: "Logs",
    children: [
      {
        href: "/logs/email",
        title: "Email Logs",
      },
      {
        href: "/logs/sms",
        title: "Sms Logs",
      },
      {
        href: "/logs/login-history",
        title: "Login History",
      },
      {
        href: "/logs/user-activities",
        title: "User Activities",
      },
    ],
  },
  {
    href: "/sms_and_email",
    icon: Email,
    title: "Sms and Email",
    children: [
      {
        href: "/sms-and-email/email-tenant",
        title: "Send Email to Tenants",
      },
      {
        href: "/sms-and-email/email-user",
        title: "Send Email to Users",
      },
      {
        href: "/sms-and-email/sms-employee",
        title: "Send Sms to Employee",
      },
      {
        href: "/sms-and-email/sms-tenant",
        title: "Send Sms to Tenants",
      },
      {
        href: "/sms-and-email/sms-user",
        title: "Send Sms to Users",
      },
    ],
  },
];

// const navItems = [
//   {
//     title: "Pages",
//     pages: pagesSection,
//   },
//   {
//     title: "Elements",
//     pages: elementsSection,
//   },
//   {
//     title: "Material App",
//     pages: docsSection,
//   },
// ];

const MsoItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
];

export default MsoItems;
