import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, MenuItem } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import useEmployee from "../../../../hooks/useEmployee";
import useTenant from "../../../../hooks/useTenant";
import useBranch from "../../../../hooks/useBranch";

import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import FilterReset from "../../../../components/FilterReset";
import { searchPayload, convertedValue } from "../../../../utils/common";
import { OperatorConstants } from "../../../../constants/operator.constants";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import CustomTextField from "../../../../components/CustomTextField";
import { useDialog } from "../../../../contexts/DialogContext";
import CustomDatePicker from "../../../../components/CustomDatePicker";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const statusOptions = [
  {
    label: "Active",
    value: "A",
  },
  {
    label: "InActive",
    value: "INA",
  },
];

function AdvanceFilter(props) {
  const { unSetDialog } = useDialog();
  const initialValues = {
    prefixOperator: "eq",
    quantityOperator: "eq",
    statusOperator: "eq",
    validTillOperator: "btn",
    quantity: "",
    validTill: [],
    validTillTemp: "",
    prefix: "",
    status: [],
  };

  const { fields, payload, setPayload, setFilterResetFunc } = props;

  const [statusMultiValue, setStatusMultiValue] = useState(false);

  const [defaultStatus, setDefaultStatus] = useState([]);

  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = methods;

  useEffect(() => {
    setFilterResetFunc(() => reset);
  }, [reset]);

  const statusOpValue = watch("statusOperator");

  const onSubmit = async (data) => {
    try {
      let filters = [];
      console.log(data, "data33333333333333");

      if (data.quantity != null && Object.keys(data.quantity).length != 0) {
        filters.push({
          fieldName: "quantity",
          fieldValue: data.quantity,
          op: data.quantityOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }
      if (data.prefix != null && Object.keys(data.prefix).length != 0) {
        filters.push({
          fieldName: "prefix",
          fieldValue: data.prefix,
          op: data.prefixOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }

      if (data.status != null && Object.keys(data.status).length != 0) {
        filters.push({
          fieldName: "status",
          fieldValue: convertedValue(data.status, "value").join(","),
          op: data.statusOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }
      if (data.validTill != null && Object.keys(data.validTill).length != 0) {
        filters.push({
          fieldName: "validTill",
          fieldValue: data.validTillTemp,
          op: data.validTillOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }
      setPayload({
        ...payload,
        filters: filters,
      });
      unSetDialog("advance_filter");
    } catch (err) {
      console.log(err, "errerrerrerr");
    }
  };

  useEffect(() => {
    if (statusOpValue == "in" || statusOpValue == "nin") {
      setDefaultStatus([]);
      setValue("status", []);
      setStatusMultiValue(true);
    } else {
      setDefaultStatus({});
      setValue("status", {});
      setStatusMultiValue(false);
    }
  }, [statusOpValue]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} pt={8}>
            <Grid item xs={9.5} sm={12} md={6} lg={2} xl={4}>
              <ReactHookFormSelect
                name="prefixOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.string).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={4} xl={4}>
              <CustomTextField
                label={t("Prefix")}
                name="prefix"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={2} xl={4}>
              <ReactHookFormSelect
                name="quantityOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.string).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={4} xl={4}>
              <CustomTextField
                label={t("Quantity")}
                name="quantity"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} pt={8}>
            <Grid item xs={9.5} sm={12} md={6} lg={2} xl={4}>
              <ReactHookFormSelect
                name="validTillOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.date).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={4} xl={4}>
              <CustomDatePicker
                name="validTill"
                nameTemp="validTillTemp"
                label={t("valid_till")}
                range={true}
                control={control}
              />
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={2} xl={4}>
              <ReactHookFormSelect
                name="statusOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((t, k) => (
                  <MenuItem key={k} value={t[0]}>
                    {t[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={4} xl={4}>
              <SearchableAutoComplete
                control={control}
                data={statusOptions}
                label={t("Status")}
                payload={null}
                setPayload={null}
                loading={false}
                name="status"
                multiple={statusMultiValue}
                defaultValue={defaultStatus}
              />
            </Grid>
          </Grid>
          <FilterReset resetForm={reset} />
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default AdvanceFilter;
