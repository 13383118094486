import React, { useEffect, useState } from "react";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import useAuth from "../../../hooks/useAuth";

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  Button,
  Typography,
  DialogActions,
  CircularProgress,
  Stack,
  Box,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { spacing } from "@mui/system";
import useKycDocument from "../../../hooks/useKycDocument";
import { useSearchParams } from "react-router-dom";
import UploadId from "./UploadId";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DoneIcon from "@mui/icons-material/Done";
import BrowserNotSupportedIcon from "@mui/icons-material/BrowserNotSupported";

import UploadAddress from "./UploadAddress";
import UploadProfile from "./UploadProfile";
import UploadCaf from "./UploadCafForm";
import Approve from "./Approve";

function KycDocuments(props) {
  let [searchParams, setSearchParams] = useSearchParams();

  const { isSubmitting } = useKycDocument();

  const isMobile = useMediaQuery("(max-width:600px)"); // Adjust the max-width as needed
  const isLaptop = useMediaQuery("(min-width:601px) and (max-width:1024px)");
  const { user } = useAuth();

  console.log(user, "userrrrr");

  const userId = searchParams.get("id");

  const kycDocumentId = searchParams.get("id");

  const currentPayload = {
    filters: [
      {
        fieldName: "radUserId",
        fieldValue: kycDocumentId,
        op: "eq",
        dataType: "int",
        condition: "and",
        isJoin: true,
        attributeName: "radUser",
        entityName: "RadUserEntity",
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
  };

  const { getAllKycDocument, kycDocuments, kycDocument, paging, downloadFile } = useKycDocument();
  const { openDialog } = useDialog();

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAllKycDocument(payload);
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const uploadIdClick = (title) => {
    let dropDownValues = [];
    console.log(title, "title");
    if (title == "Id Proof") {
      dropDownValues = [
        {
          key: "AADHAR_CARD",
          value: "Aadhar Card",
        },
        {
          key: "PAN_CARD",
          value: "Pan Card",
        },
        {
          key: "VOTER_ID",
          value: "Voter Id",
        },
        {
          key: "DRIVING_LIC",
          value: "Driving Licence",
        },
      ];
    }
    if (title == "Address Proof") {
      dropDownValues = [
        {
          key: "HOME_TAX",
          value: "Home Tax",
        },
        {
          key: "WATER_BILL",
          value: "Water Bill",
        },
        {
          key: "GAS_BILL",
          value: "Gas Bill",
        },
      ];
    }
    if (title == "Profile Proof") {
      dropDownValues = [
        {
          key: "PROFILE_PIC",
          value: "Profile Pic",
        },
      ];
    }
    if (title == "CAF Proof") {
      dropDownValues = [
        {
          key: "CAF_PROOF",
          value: "Caf Proof",
        },
      ];
    }
    return openDialog(
      "upload_id_proof",
      "Upload Id Proof",
      <UploadId
        key={getTimeStamp()}
        radUserId={searchParams.get("id")}
        dropDownValues={dropDownValues}
      />,
    );
  };

  const approveClick = (id) => {
    return openDialog(
      "approve",
      "Approve",
      <Approve key={getTimeStamp()} userId={searchParams.get("id")} />,
    );
  };

  const uploadProfileClick = (id) => {
    return openDialog(
      "Upload Profile Proof",
      <UploadProfile key={getTimeStamp()} userId={searchParams.get("id")} />,
    );
  };

  const uploadCafClick = (id) => {
    return openDialog(
      "Upload Caf Proof",
      true,
      <UploadCaf key={getTimeStamp()} userId={searchParams.get("id")} />,
    );
  };

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const columns = [
    {
      field: "documentName",
      headerName: "Document Name",
      flex: 1,
    },
  ];

  return (
    <Card mb={6}>
      <Grid container spacing={6} id="invoice_details">
        <Grid item xs={12}>
          <Card xs={6} md container>
            <CardContent>
              <Grid container spacing={6} id="invoice_details">
                {kycDocuments.map((kycDocument) => (
                  <Grid item spacing={2} xs={12} md={6}>
                    <Card mb={6}>
                      <CardHeader title={kycDocument.title} />
                      <CardContent>
                        <Grid item xs={12} sm={12} md={6} lg={12} xl>
                          <img
                            src={kycDocument.title}
                            style={{
                              width: "100%",
                              maxWidth: "400px",
                            }}
                            height={200}
                          />
                          <Stack
                            direction="row"
                            spacing={isMobile ? 2 : isLaptop ? 5 : 10} // Adjust spacing based on screen size
                            paddingTop={isMobile ? 2 : 5} // Adjust padding for mobile
                          >
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              startIcon={<UploadFileIcon />}
                              onClick={() => uploadIdClick(kycDocument.title)}
                              endIcon={isSubmitting ? <CircularProgress size={24} /> : null}
                              disabled={false}
                              size={isMobile ? "small" : "medium"} // Adjust button size for mobile
                              fullWidth={isMobile} // Make button full width on mobile
                            >
                              Upload
                            </Button>
                            {user &&
                              user.businessType == "SUPER_ADMIN" &&
                              user.businessType == "MULTI_SERVICE_OPERATOR" && (
                                <>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                    startIcon={<DoneIcon />}
                                    onClick={() => approveClick(kycDocument.title)}
                                    endIcon={isSubmitting ? <CircularProgress size={24} /> : null}
                                    disabled={false}
                                    size={isMobile ? "small" : "medium"} // Adjust button size for mobile
                                    fullWidth={isMobile} // Make button full width on mobile
                                  >
                                    Approve
                                  </Button>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="error"
                                    startIcon={<BrowserNotSupportedIcon />}
                                    endIcon={isSubmitting ? <CircularProgress size={24} /> : null}
                                    disabled={false}
                                    size={isMobile ? "small" : "medium"} // Adjust button size for mobile
                                    fullWidth={isMobile} // Make button full width on mobile
                                  >
                                    Reject
                                  </Button>
                                </>
                              )}
                          </Stack>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Card>
  );
}

export default KycDocuments;
