import { BulkOperationConstants } from "../constants/bulkoperation.constants";

const BulkOperationReducer = (state, action) => {
  switch (action.type) {
    case BulkOperationConstants.UPLOAD_BULK_OPERATION_REQUEST:
      return {
        ...state,
        isUpload: true,
        uploadError: false,
      };
    case BulkOperationConstants.UPLOAD_BULK_OPERATION_SUCCESS:
      return {
        ...state,
        uploadSuccess: true,
        uploadError: false,
        isUpload: false,
      };
    case BulkOperationConstants.UPLOAD_BULK_OPERATION_FAILURE:
      return {
        ...state,
        uploadError: true,
        isUpload: false,
      };

    default:
      return state;
  }
};

export default BulkOperationReducer;
