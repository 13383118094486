import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { CardSystemProvider } from "../contexts/CardSystemContext";
import { DialogProvider } from "../contexts/DialogContext";
import { TenantProvider } from "../contexts/TenantContext";
import { PlanGroupProvider } from "../contexts/PlanGroupContext";
import { PlanProvider } from "../contexts/PlanContext";

export const CardSystemComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <CardSystemProvider key={0} />,
        <TenantProvider key={1} />,
        <PlanGroupProvider key={2} />,
        <PlanProvider key={3} />,
        <DialogProvider key={4} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
export default CardSystemComposer;
