import { useContext } from "react";

import { RadUserPlanContext } from "../contexts/RadUserPlanContext";

const useRadUserPlan = () => {
  const context = useContext(RadUserPlanContext);

  if (!context) throw new Error("RadUserPlanContext must be placed within RadUserPlan Provider");

  return context;
};

export default useRadUserPlan;
