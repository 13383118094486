//Masters
import CountryList from "../pages/masters/country";
import AddCountry from "../pages/masters/country/add";
import EditCountry from "../pages/masters/country/edit";
import StateList from "../pages/masters/state";
import AddState from "../pages/masters/state/add";
import EditState from "../pages/masters/state/edit";
import DistrictList from "../pages/masters/district";
import AddDistrict from "../pages/masters/district/add";
import EditDistrict from "../pages/masters/district/edit";
import TalukList from "../pages/masters/taluk";
import AddTaluk from "../pages/masters/taluk/add";
import EditTaluk from "../pages/masters/taluk/edit";
import CityList from "../pages/masters/city";
import AddCity from "../pages/masters/city/add";
import EditCity from "../pages/masters/city/edit";
import VillageList from "../pages/masters/village";
import AddVillage from "../pages/masters/village/add";
import EditVillage from "../pages/masters/village/edit";
import PincodeList from "../pages/masters/pincode";
import AddPincode from "../pages/masters/pincode/add";
import EditPincode from "../pages/masters/pincode/edit";
import EmailMasterList from "../pages/masters/emailmaster";
import AddEmailMaster from "../pages/masters/emailmaster/add";
import EditEmailMaster from "../pages/masters/emailmaster/edit";
import SmsTemplateList from "../pages/masters/smstemplate";
import AddSmsTemplate from "../pages/masters/smstemplate/add";
import EditSmsTemplate from "../pages/masters/smstemplate/edit";

//Composers
import { CountryComposer } from "../composers/CountryComposer";
import { StateMasterComposer } from "../composers/StateMasterComposer";
import { DistrictComposer } from "../composers/DistrictComposer";
import { TalukComposer } from "../composers/TalukComposer";
import { CityComposer } from "../composers/CityComposer";
import { VillageComposer } from "../composers/VillageComposer";
import { PincodeComposer } from "../composers/PincodeComposer";
import { EmailMasterComposer } from "../composers/EmailMasterComposer";
import { SmsTemplateComposer } from "../composers/SmsTemplateComposer";
import { DashboardComposer } from "../composers/DashboardComposer";

// Guards
import AuthGuard from "../components/guards/AuthGuard";
import DashboardLayout from "../layouts/Dashboard";
import ProtectedLayout from "../layouts/ProtectedLayout";

const MasterRoutes = {
  path: "masters",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  roles: ["SUPER_ADMIN"],
  children: [
    {
      path: "country",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <CountryComposer>
                <AddCountry />
              </CountryComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <CountryComposer>
                <EditCountry />
              </CountryComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <CountryComposer>
                <CountryList />
              </CountryComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "state",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <StateMasterComposer>
                <AddState />
              </StateMasterComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <StateMasterComposer>
                <EditState />
              </StateMasterComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <StateMasterComposer>
                <StateList />
              </StateMasterComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "district",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <DistrictComposer>
                <AddDistrict />
              </DistrictComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <DistrictComposer>
                <EditDistrict />
              </DistrictComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <DistrictComposer>
                <DistrictList />
              </DistrictComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "taluk",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <TalukComposer>
                <AddTaluk />
              </TalukComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <TalukComposer>
                <EditTaluk />
              </TalukComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <TalukComposer>
                <TalukList />
              </TalukComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "city",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <CityComposer>
                <AddCity />
              </CityComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <CityComposer>
                <EditCity />
              </CityComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <CityComposer>
                <CityList />
              </CityComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "village",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <VillageComposer>
                <AddVillage />
              </VillageComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <VillageComposer>
                <EditVillage />
              </VillageComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <VillageComposer>
                <VillageList />
              </VillageComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "pincode",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <PincodeComposer>
                <AddPincode />
              </PincodeComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <PincodeComposer>
                <EditPincode />
              </PincodeComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <PincodeComposer>
                <PincodeList />
              </PincodeComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "emailmaster",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <EmailMasterComposer>
                <AddEmailMaster />
              </EmailMasterComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <EmailMasterComposer>
                <EditEmailMaster />
              </EmailMasterComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <EmailMasterComposer>
                <EmailMasterList />
              </EmailMasterComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "smstemplate",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <SmsTemplateComposer>
                <AddSmsTemplate />
              </SmsTemplateComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <SmsTemplateComposer>
                <EditSmsTemplate />
              </SmsTemplateComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <SmsTemplateComposer>
                <SmsTemplateList />
              </SmsTemplateComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
  ],
};

export default MasterRoutes;
