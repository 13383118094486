//Users
import Lead from "../pages/lead/index.js";
import AddLead from "../pages/lead/add.js";
import EditLead from "../pages/lead/edit.js";

import LeadStats from "../pages/lead/leadstats.js";
import LeadDetails from "../pages/lead/leaddetails.js";
import AssignUser from "../pages/lead/components/assign.js";
import ClosedLead from "../pages/lead/components/Closed.js";

//Composers
import { LeadComposer } from "../composers/LeadComposer";
import { DashboardComposer } from "../composers/DashboardComposer.js";

// Guards
import AuthGuard from "../components/guards/AuthGuard.js";
import DashboardLayout from "../layouts/Dashboard.js";
import ProtectedLayout from "../layouts/ProtectedLayout";
// import LeadClosed from "../pages/lead/leadclosed.js";

const LeadRoutes = {
  path: "leads",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  roles: ["SUPER_ADMIN"],
  children: [
    {
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <LeadComposer>
                <AddLead />
              </LeadComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <LeadComposer>
                <EditLead />
              </LeadComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <LeadComposer>
                <Lead />
              </LeadComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "stats",
          element: (
            <ProtectedLayout>
              <LeadComposer>
                <LeadStats />
              </LeadComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "Details",
          element: (
            <ProtectedLayout>
              <LeadComposer>
                <LeadDetails />
              </LeadComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "assign",
          element: (
            <ProtectedLayout>
              <LeadComposer>
                <AssignUser />
              </LeadComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "closed",
          element: (
            <ProtectedLayout>
              <LeadComposer>
                <ClosedLead />
              </LeadComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
  ],
};

export default LeadRoutes;
