//Composers
import { SettingComposer } from "../composers/SettingComposer.js";

import { DashboardComposer } from "../composers/DashboardComposer.js";

// Guards
import AuthGuard from "../components/guards/AuthGuard.js";
import DashboardLayout from "../layouts/Dashboard.js";
import Settings from "../pages/cpanel/tenant/general_settings/index.js";
import Sms from "../pages/cpanel/tenant/sms_notifications";
import Email from "../pages/cpanel/tenant/mail_notifications";
import ProtectedLayout from "../layouts/ProtectedLayout";
// import LeadClosed from "../pages/lead/leadclosed.js";

const SettingsRoutes = {
  path: "cpanel",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  roles: ["SUPER_ADMIN"],
  children: [
    {
      path: "tenant",
      children: [
        {
          path: "general-setting",
          element: (
            <ProtectedLayout>
              <SettingComposer>
                <Settings />
              </SettingComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "tenant",
      children: [
        {
          path: "email-setting",
          element: (
            <ProtectedLayout>
              <SettingComposer>
                <Email />
              </SettingComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "tenant",
      children: [
        {
          path: "sms-setting",
          element: (
            <ProtectedLayout>
              <SettingComposer>
                <Sms />
              </SettingComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
  ],
};

export default SettingsRoutes;
