export const PlanGroupConstants = {
  FETCH_PLAN_GROUP_REQUEST: "FETCH_PLAN_GROUP_REQUEST",
  FETCH_PLAN_GROUP_SUCCESS: "FETCH_PLAN_GROUP_SUCCESS",
  FETCH_PLAN_GROUP_FAILURE: "FETCH_PLAN_GROUP_FAILURE",
  ADD_PLAN_GROUP_REQUEST: "ADD_PLAN_GROUP_REQUEST",
  ADD_PLAN_GROUP_SUCCESS: "ADD_PLAN_GROUP_SUCCESS",
  ADD_PLAN_GROUP_FAILURE: "ADD_PLAN_GROUP_FAILURE",
  ADD_PLAN_GROUP_PLAN_REQUEST: "ADD_PLAN_GROUP_PLAN_REQUEST",
  ADD_PLAN_GROUP_PLAN_SUCCESS: "ADD_PLAN_GROUP_PLAN_SUCCESS",
  ADD_PLAN_GROUP_PLAN_FAILURE: "ADD_PLAN_GROUP_PLAN_FAILURE",
  PLAN_GROUP_SEARCH_REQUEST: "PLAN_GROUP_SEARCH_REQUEST",
  PLAN_GROUP_SEARCH_SUCCESS: "PLAN_GROUP_SEARCH_SUCCESS",
  PLAN_GROUP_SEARCH_FAILURE: "PLAN_GROUP_SEARCH_FAILURE",
  GET_PLAN_GROUP_REQUEST: "GET_PLAN_GROUP_REQUEST",
  GET_PLAN_GROUP_SUCCESS: "GET_PLAN_GROUP_SUCCESS",
  GET_PLAN_GROUP_FAILURE: "GET_PLAN_GROUP_FAILURE",
  UPDATE_PLAN_GROUP_REQUEST: "UPDATE_PLAN_GROUP_REQUEST",
  UPDATE_PLAN_GROUP_SUCCESS: "UPDATE_PLAN_GROUP_SUCCESS",
  UPDATE_PLAN_GROUP_FAILURE: "UPDATE_PLAN_GROUP_FAILURE",
  DOWNLOAD_PLAN_GROUP_REQUEST: "DOWNLOAD_PLAN_GROUP_REQUEST",
  DOWNLOAD_PLAN_GROUP_SUCCESS: "DOWNLOAD_PLAN_GROUP_SUCCESS",
  DOWNLOAD_PLAN_GROUP_FAILURE: "DOWNLOAD_PLAN_GROUP_FAILURE",
  UPLOAD_PLAN_GROUP_REQUEST: "UPLOAD_PLAN_GROUP_REQUEST",
  UPLOAD_PLAN_GROUP_SUCCESS: "UPLOAD_PLAN_GROUP_SUCCESS",
  UPLOAD_PLAN_GROUP_FAILURE: "UPLOAD_PLAN_GROUP_FAILURE",
  ADD_PLAN_GROUP_TENANT_REQUEST: "ADD_PLAN_GROUP_TENANT_REQUEST",
  ADD_PLAN_GROUP_TENANT_SUCCESS: "ADD_PLAN_GROUP_TENANT_SUCCESS",
  ADD_PLAN_GROUP_TENANT_FAILURE: "ADD_PLAN_GROUP_TENANT_FAILURE",
  FETCH_PLAN_GROUP_TENANT_REQUEST: "FETCH_PLAN_GROUP_TENANT_REQUEST",
  FETCH_PLAN_GROUP_TENANT_SUCCESS: "FETCH_PLAN_GROUP_TENANT_SUCCESS",
  FETCH_PLAN_GROUP_TENANT_FAILURE: "FETCH_PLAN_GROUP_TENANT_FAILURE",
  FETCH_PLAN_GROUP_PLAN_REQUEST: "FETCH_PLAN_GROUP_PLAN_REQUEST",
  FETCH_PLAN_GROUP_PLAN_SUCCESS: "FETCH_PLAN_GROUP_PLAN_SUCCESS",
  FETCH_PLAN_GROUP_PLAN_FAILURE: "FETCH_PLAN_GROUP_PLAN_FAILURE",
  PLAN_GROUP_AUTO_SEARCH_REQUEST: "PLAN_GROUP_AUTO_SEARCH_REQUEST",
  PLAN_GROUP_AUTO_SEARCH_SUCCESS: "PLAN_GROUP_AUTO_SEARCH_SUCCESS",
  PLAN_GROUP_AUTO_SEARCH_FAILURE: "PLAN_GROUP_AUTO_SEARCH_FAILURE",
  PLAN_GROUP_PLAN_AUTO_SEARCH_REQUEST: "PLAN_GROUP_PLAN_AUTO_SEARCH_REQUEST",
  PLAN_GROUP_PLAN_AUTO_SEARCH_SUCCESS: "PLAN_GROUP_PLAN_AUTO_SEARCH_SUCCESS",
  PLAN_GROUP_PLAN_AUTO_SEARCH_FAILURE: "PLAN_GROUP_PLAN_AUTO_SEARCH_FAILURE",
  DELETE_PLAN_GROUP_REQUEST: "DELETE_PLAN_GROUP_REQUEST",
  DELETE_PLAN_GROUP_SUCCESS: "DELETE_PLAN_GROUP_SUCCESS",
  DELETE_PLAN_GROUP_FAILURE: "DELETE_PLAN_GROUP_FAILURE",
};
