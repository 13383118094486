import React, { useEffect, useState, useCallback } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";

import CustomTextField from "../components/CustomTextField";
import ReactHookFormSelect from "../components/ReactHookFormSelect";
import { useTranslation } from "react-i18next";

import useVillage from "../hooks/useVillage";
import usePincode from "../hooks/usePincode";
import useCity from "../hooks/useCity";
import useTaluk from "../hooks/useTaluk";
import useDistrict from "../hooks/useDistrict";
import useCountry from "../hooks/useCountry";
import useStateMaster from "../hooks/useStateMaster";
import { searchPayloadEqInt } from "../utils/common";
import { useFormState } from "../contexts/TenantFormContext";
const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};
const AddressDetail = (props) => {
  const {
    register,
    watch,
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  const { t } = useTranslation();

  console.log("i am here", control);

  const { validationFields } = props;

  //const { searchVillage, villageSearchResults: villages } = useVillage();
  // const { searchPincode, pincodeSearchResults } = usePincode();
  //const { searchCity, citySearchResults } = useCity();
  const { searchTaluk, talukSearchResults } = useTaluk();
  const { searchDistrict, districtSearchResults } = useDistrict();
  const { searchState, stateSearchResults } = useStateMaster();
  const { searchCountry, countrySearchResults } = useCountry();
  let searchPayloadValue = {};

  // const fetchData = async () => {
  //   await searchPincode();
  // };

  const fetchTalukData = async () => {
    await searchTaluk(currentPayload);
  };

  //const villageId = watch("villageId");
  // const pinCodeId = watch("pinCodeId");
  //const cityId = watch("cityId");
  const talukId = watch("talukId");
  const districtId = watch("districtId");
  const stateId = watch("stateId");

  // useEffect(() => {
  //   console.log(villageId, "villageIdvillageIdvillageId");
  //   if (villageId && villages.length !== 0) {
  //     filteredCities(villageId);
  //   }
  // }, [villageId, villages]);

  // useEffect(() => {
  //   if (pinCodeId) {
  //     fetchVillage(pinCodeId);
  //   }
  // }, [pinCodeId]);

  // useEffect(() => {
  //   if (cityId && citySearchResults.length !== 0) {
  //     filteredTaluks(cityId);
  //   }
  // }, [cityId, citySearchResults]);

  useEffect(() => {
    if (countrySearchResults && countrySearchResults.length != 0) {
      setValue("countryId", 1);
    }
  }, [countrySearchResults]);

  useEffect(() => {
    if (talukId && talukSearchResults.length !== 0) {
      filteredDistricts(talukId);
    }
  }, [talukId, talukSearchResults]);

  useEffect(() => {
    if (districtId && districtSearchResults.length !== 0) {
      filteredStates(districtId);
    }
  }, [districtId, districtSearchResults]);

  useEffect(() => {
    if (stateId && stateSearchResults.length !== 0) {
      filteredCountries(stateId);
    }
  }, [stateId, stateSearchResults]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // handleChange(name, value);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    //fetchData();
    fetchTalukData();
    fetchCountry(276);
  }, []);

  // const filteredCities = async (villageId) => {
  //   console.log(villages, "villagesvillages");
  //   const cityValue = villages.filter(function (item) {
  //     return item.villageId === villageId;
  //   });
  //   if (cityValue.length != 0) {
  //     fetchCity(cityValue[0]["city"]["cityId"]);
  //   }
  // };

  // const filteredTaluks = async (cityId) => {
  //   const talukValue = citySearchResults.filter(function (item) {
  //     return item.cityId === cityId;
  //   });
  //   if (talukValue.length != 0) {
  //     fetchTaluk(talukValue[0]["taluk"]["talukId"]);
  //   }
  // };

  const filteredDistricts = async (talukId) => {
    const districtValue = talukSearchResults.filter(function (item) {
      return item.talukId === talukId;
    });
    if (districtValue.length != 0) {
      fetchDistricts(districtValue[0]["district"]["districtId"]);
    }
  };

  const filteredStates = async (districtId) => {
    const stateValue = districtSearchResults.filter(function (item) {
      return item.districtId === districtId;
    });
    if (stateValue.length != 0) {
      fetchState(stateValue[0]["state"]["stateId"]);
    }
  };

  const filteredCountries = async (stateId) => {
    const countryValue = stateSearchResults.filter(function (item) {
      return item.stateId === stateId;
    });
    if (countryValue.length != 0) {
      fetchCountry(countryValue[0]["country"]["countryId"]);
    }
  };

  // const fetchVillage = async (pinCodeId) => {
  //   if (pinCodeId) {
  //     searchPayloadValue = searchPayloadEqInt("pincodeId", pinCodeId);
  //     await searchVillage(searchPayloadValue);
  //   }
  // };
  // const fetchCity = async (selectedCityId) => {
  //   if (selectedCityId) {
  //     searchPayloadValue = searchPayloadEqInt("cityId", selectedCityId, false);
  //     await searchCity(searchPayloadValue);
  //   }
  // };
  const fetchTaluk = async (selectedTalukId) => {
    if (selectedTalukId) {
      searchPayloadValue = searchPayloadEqInt("talukId", selectedTalukId, false);
      await searchTaluk(searchPayloadValue);
    }
  };
  const fetchDistricts = async (selectedDistrictId) => {
    if (selectedDistrictId) {
      searchPayloadValue = searchPayloadEqInt("districtId", selectedDistrictId, false);
      await searchDistrict(searchPayloadValue);
    }
  };
  const fetchState = async (selectedStateId) => {
    if (selectedStateId) {
      searchPayloadValue = searchPayloadEqInt("stateId", selectedStateId, false);
      await searchState(searchPayloadValue);
    }
  };
  const fetchCountry = async (selectedCountryId) => {
    if (selectedCountryId) {
      searchPayloadValue = searchPayloadEqInt("countryId", selectedCountryId, false);
      await searchCountry(searchPayloadValue);
    }
  };

  return (
    <Grid container spacing={12} className="row" pt={8}>
      <Grid item md={6}>
        <CustomTextField
          label={t("door_no")}
          name="doorNo"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("street_name")}
          name="streetName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextField
          label={t("pincode_name")}
          name="pinCode"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
        {/* <ReactHookFormSelect
          id="pinCode"
          name="pinCode"
          label="Pincode"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        >
          {pincodeSearchResults &&
            pincodeSearchResults.map((pinCode) => (
              <MenuItem key={pinCode.pincodeName} value={pinCode.pincodeId}>
                {pinCode.pincodeName}
              </MenuItem>
            ))}
        </ReactHookFormSelect> */}
      </Grid>

      <Grid item md={6}>
        {/* <ReactHookFormSelect
          id="villageId"
          name="villageId"
          label="Village"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        >
          {villages &&
            villages.map((village) => (
              <MenuItem key={village.villageId} value={village.villageId}>
                {village.villageName}
              </MenuItem>
            ))}
        </ReactHookFormSelect> */}
        <CustomTextField
          label={t("village_name")}
          name="villageName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item md={6}>
        {/* <ReactHookFormSelect
          id="cityId"
          name="cityId"
          label="City"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={fields}
        >
          {citySearchResults &&
            citySearchResults.map((city) => (
              <MenuItem key={city.cityId} value={city.cityId}>
                {city.cityName}
              </MenuItem>
            ))}
        </ReactHookFormSelect> */}
        <CustomTextField
          label={t("town_name")}
          name="townName"
          type="text"
          my={2}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item xs={10} sm={12} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          id="talukId"
          name="talukId"
          label={t("taluk_name")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          {talukSearchResults &&
            talukSearchResults.map((taluk) => (
              <MenuItem key={taluk.talukId} value={taluk.talukId}>
                {taluk.talukName}
              </MenuItem>
            ))}
        </ReactHookFormSelect>
      </Grid>
      <Grid item xs={10} sm={12} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          id="districtId"
          name="districtId"
          label={t("district_name")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          {districtSearchResults &&
            districtSearchResults.map((district) => (
              <MenuItem key={district.districtId} value={district.districtId}>
                {district.districtName}
              </MenuItem>
            ))}
        </ReactHookFormSelect>
      </Grid>
      <Grid item xs={10} sm={12} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          id="stateId"
          name="stateId"
          label={t("state_name")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          {stateSearchResults &&
            stateSearchResults.map((state) => (
              <MenuItem key={state.stateId} value={state.stateId}>
                {state.stateName}
              </MenuItem>
            ))}
        </ReactHookFormSelect>
      </Grid>
      <Grid item xs={10} sm={12} md={6} lg={6} xl={4}>
        <ReactHookFormSelect
          id="countryId"
          name="countryId"
          label={t("country_name")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          {countrySearchResults &&
            countrySearchResults.map((country) => (
              <MenuItem key={country.countryId} value={country.countryId}>
                {country.countryName}
              </MenuItem>
            ))}
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
};

export default AddressDetail;
