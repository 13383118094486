//Masters
import AddPool from "../pages/mikrotik_mgnt/pool/add";
import PoolList from "../pages/mikrotik_mgnt/pool/index";
import AddAddresses from "../pages/mikrotik_mgnt/addresses/add";
import EditAddresses from "../pages/mikrotik_mgnt/addresses/edit";
import AddressesList from "../pages/mikrotik_mgnt/addresses/index";
import AddRoutes from "../pages/mikrotik_mgnt/routes/add";
import EditRoutes from "../pages/mikrotik_mgnt/routes/edit";
import RoutesList from "../pages/mikrotik_mgnt/routes/index";
import AddRadius from "../pages/mikrotik_mgnt/radius/add";
import EditRadius from "../pages/mikrotik_mgnt/radius/edit";
import RadiusList from "../pages/mikrotik_mgnt/radius/index";
import EditPool from "../pages/mikrotik_mgnt/pool/edit";
import AddPppProfile from "../pages/mikrotik_mgnt/ppp_profile/add";
import EditPppProfile from "../pages/mikrotik_mgnt/ppp_profile/edit";
import PppProfileList from "../pages/mikrotik_mgnt/ppp_profile/index";
import PppSecretList from "../pages/mikrotik_mgnt/ppp_secret/index";
import PppServerList from "../pages/mikrotik_mgnt/ppp_server/index";
import AddPppSecret from "../pages/mikrotik_mgnt/ppp_secret/add";
import AddPppServer from "../pages/mikrotik_mgnt/ppp_server/add";
import EditPppServer from "../pages/mikrotik_mgnt/ppp_server/edit";
import EditPppSecret from "../pages/mikrotik_mgnt/ppp_secret/edit";

//Composers
import { IpAddressComposer } from "../composers/IpAddressComposer";
import { DashboardComposer } from "../composers/DashboardComposer";
import { RoutesComposer } from "../composers/RoutesComposer";
import { MkRadiusComposer } from "../composers/MkRadiusComposer";
import { PppProfileComposer } from "../composers/PppProfileComposer";
import { MkIpPoolComposer } from "../composers/MkIpPoolComposer";
import { PppSecretComposer } from "../composers/PppSecretComposer";
import { PppServerComposer } from "../composers/PppServerComposer";

// Guards
import AuthGuard from "../components/guards/AuthGuard";
import DashboardLayout from "../layouts/Dashboard";
import ProtectedLayout from "../layouts/ProtectedLayout";

const MikrotikMgntRoutes = {
  path: "mikrotik-mgnt",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  roles: ["SUPER_ADMIN"],
  children: [
    {
      path: "pool",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <MkIpPoolComposer>
                <AddPool />
              </MkIpPoolComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <MkIpPoolComposer>
                <EditPool />
              </MkIpPoolComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <MkIpPoolComposer>
                <PoolList />
              </MkIpPoolComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "addresses",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <IpAddressComposer>
                <AddAddresses />
              </IpAddressComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <IpAddressComposer>
                <EditAddresses />
              </IpAddressComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <IpAddressComposer>
                <AddressesList />
              </IpAddressComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "routes",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <RoutesComposer>
                <AddRoutes />
              </RoutesComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <RoutesComposer>
                <EditRoutes />
              </RoutesComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <RoutesComposer>
                <RoutesList />
              </RoutesComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "mk-radius",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <MkRadiusComposer>
                <AddRadius />
              </MkRadiusComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <MkRadiusComposer>
                <EditRadius />
              </MkRadiusComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <MkRadiusComposer>
                <RadiusList />
              </MkRadiusComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "ppp-profile",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <PppProfileComposer>
                <AddPppProfile />
              </PppProfileComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <PppProfileComposer>
                <EditPppProfile />
              </PppProfileComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <PppProfileComposer>
                <PppProfileList />
              </PppProfileComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "ppp-secret",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <PppSecretComposer>
                <AddPppSecret />
              </PppSecretComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <PppSecretComposer>
                <EditPppSecret />
              </PppSecretComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <PppSecretComposer>
                <PppSecretList />
              </PppSecretComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "ppp-server",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <PppServerComposer>
                <AddPppServer />
              </PppServerComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <PppServerComposer>
                <EditPppServer />
              </PppServerComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <PppServerComposer>
                <PppServerList />
              </PppServerComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
  ],
};

export default MikrotikMgntRoutes;
