import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "name", headerName: "Name", width: 130 },
  { field: "age", headerName: "Age", type: "number", width: 90 },
  { field: "status", headerName: "Status", width: 110 },
];

const rows = [
  { id: 1, name: "Alice", age: 35, status: "Active" },
  { id: 2, name: "Bob", age: 42, status: "Inactive" },
  { id: 3, name: "Charlie", age: 29, status: "Active" },
  { id: 4, name: "David", age: 50, status: "Inactive" },
  { id: 5, name: "Eve", age: 22, status: "Active" },
];

function DashboardDataGridWidget() {
  return (
    <Card sx={{ padding: 2, borderRadius: 2, boxShadow: 3, height: "100%" }}>
      <CardContent>
        <Typography variant="h6" component="div" gutterBottom>
          User Expiring
        </Typography>
        <Box sx={{ height: 400 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        </Box>
      </CardContent>
    </Card>
  );
}

export default DashboardDataGridWidget;
