import React, { useMemo } from "react";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { Controller } from "react-hook-form";

const CustomCheckBox = ({ name, label, control, type, errors, fields, disabled, ...props }) => {
  const { t } = useTranslation();
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.

  return (
    <FormGroup>
      <Controller
        render={({ field }) => (
          <FormControlLabel
            name={props.id || props.name}
            disabled={disabled}
            label={t(label)}
            fullWidth
            variant="outlined"
            my={2}
            error={errors[name] ? true : false}
            helperText={errors[name] !== undefined ? errors[name]?.message : ""}
            control={
              <Checkbox
                {...field}
                checked={field.value != undefined ? JSON.parse(field.value) : false}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            }
          />
        )}
        name={name}
        control={control}
      />
    </FormGroup>
  );
};

export default React.memo(CustomCheckBox);
