import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";

import { Box, CssBaseline, Hidden, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";
import { useNavigate, useLocation } from "react-router-dom";

import { GlobalStyles } from "@mui/system";
import Navbar from "../components/navbar/Navbar";
import SuperAdminItems from "../components/sidebar/SuperAdminItems";
import LCOItems from "../components/sidebar/lcoItems";
import DistributorItems from "../components/sidebar/distributorItems";
import UserItems from "../components/sidebar/UserItems";
import HotelItems from "../components/sidebar/HotelItems";
import MsoItems from "../components/sidebar/MSOItems";

import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";
import Settings from "../components/Settings";
import Notification from "../pages/components/Notification";
import useAuth from "../hooks/useAuth";
import EmployeeItems from "../components/sidebar/EmployeeItems";
import useTenant from "../hooks/useTenant";
import Logo from "../vendor/logo.png";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children }) => {
  const [logoSrc, setLogoSrc] = useState(null);
  const [logoTextName, setLogoTextName] = useState("");
  const { user, getUserMenusWithPermissions, userMenus } = useAuth();
  const { getTenant, tenant, getTenantSettings, tenantSetting, getTenantLogo, tenantLogo } =
    useTenant();
  const [mobileOpen, setMobileOpen] = useState(false);

  const [menuItems, setMenuItems] = useState([
    {
      title: "Pages",
      pages: [],
    },
  ]);

  console.log("content loaded for every page........");

  console.log(user, "useruser");

  const fetchData = async () => {
    await getUserMenusWithPermissions();
  };

  const fetchTenant = async (tenantId) => {
    await getTenant(tenantId);
  };

  const fetchTenantSettings = async (tenantId) => {
    await getTenantSettings(tenantId);
  };

  const fetchTenantLogo = async (tenantId) => {
    await getTenantLogo(tenantId);
  };

  useEffect(() => {
    console.log(userMenus, "userMenususerMenususerMenus");
    if (userMenus != undefined) {
      setMenuItems([
        {
          title: "Pages",
          pages: userMenus,
        },
      ]);
    }
  }, [userMenus]);

  useEffect(() => {
    console.log(userMenus, "userMenususerMenususerMenus");
    if (user != undefined) {
      if (user.businessType != "SUPER_ADMIN") {
        fetchTenant(user.tenantId);
      } else {
        setLogoTextName("Hyper Radius");
        setLogoSrc(Logo);
      }
    }
  }, [user]);

  useEffect(() => {
    console.log(tenantLogo, "tenantLogotenantLogo");
    if (tenantLogo != null) {
      const imageUrl = URL.createObjectURL(tenantLogo);
      setLogoSrc(imageUrl);
    }
  }, [tenantLogo]);

  useEffect(() => {
    console.log(tenant, "tenanttenant");
    if (Object.keys(tenant).length != 0) {
      if (tenant.businessType == "MULTI_SERVICE_OPERATOR") {
        fetchTenantSettings(tenant?.tenantId);
        fetchTenantLogo(tenant?.tenantId);
      } else {
        fetchTenantSettings(tenant?.mso.tenantId);
        fetchTenantLogo(tenant?.mso.tenantId);
      }
    }
  }, [tenant]);

  useEffect(() => {
    console.log(tenantSetting, "tenantSettingtenantSetting");
    if (Object.keys(tenantSetting).length != 0) {
      if (tenantSetting.logoTextName != null) {
        setLogoTextName(tenantSetting.logoTextName);
      } else {
        setLogoTextName("Hyper Radius");
      }
    }
  }, [tenantSetting]);

  const router = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const theme = useTheme();
  const globalStyles = (
    <GlobalStyles
      styles={{
        body: {
          height: "100%",
          overflowX: "hidden",
          background: `${theme.palette.background.default}`,
          margin: 0,
        },
        html: {
          height: "100%",
          overflowX: "hidden",
        },
        "#root": {
          height: "100%",
          overflowX: "hidden",
        },

        ".MuiIconButton-root": {
          adding: "4px",
          width: "50px",
          height: "50px",
        },
      }}
    />
  );

  useEffect(() => {
    fetchData();
  }, []);

  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Root>
      <CssBaseline />
      {globalStyles}
      <Notification />
      <Drawer theme={theme}>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={menuItems}
            logoSrc={logoSrc}
            logoTextName={logoTextName}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={menuItems}
            logoSrc={logoSrc}
            logoTextName={logoTextName}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isLgUp ? 8 : 5} theme={theme} id="main-content">
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
      {/* <Settings /> */}
    </Root>
  );
};

export default Dashboard;
