import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import {
  Alert as MuiAlert,
  AlertTitle,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";

import SaveCancel from "../../../components/SaveCancel";
import useTenant from "../../../hooks/useTenant";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import TenantDetail from "./components/TenantDetail";
import LoginDetail from "./components/LoginDetail";
import AddressDetail from "../../../components/AddressDetail";
import ContactDetail from "./components/ContactDetail";
import IpTvSettings from "./components/IpTvSettings";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const tabFields = {
  0: [
    "businessType",
    "mso",
    "distributor",
    "tenantName",
    "officialMobileNo",
    "officialEmailId",
    "sharingType",
    "planGroup",
    "status",
  ],
  1: [
    "doorNo",
    "streetName",
    "pinCode",
    "villageName",
    "townName",
    "talukId",
    "districtId",
    "stateId",
    "countryId",
  ],
  2: ["userName", "password", "confirmPassword"],
  3: ["firstName", "lastName", "emailId", "mobileNo"],
  4: [],
};
// min 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

// .matches(
//   /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
//   "Password must contain at least 8 characters, one uppercase, one number and one special case character"
// ),

const validationSchema = Yup.object().shape({
  tenantName: Yup.string().required("Required"),
  mobileNo: Yup.string()
    .required("Required")
    .matches(/^\d{10}$/, "Must be a valid phone number with up to 10 digits"),
  officialMobileNo: Yup.string()
    .required("Required")
    .matches(/^\d{10}$/, "Must be a valid phone number with up to 10 digits"),
  emailId: Yup.string("Enter email id")
    .required("Email id is required")
    .email("Enter a valid email"),
  officialEmailId: Yup.string("Enter email id")
    .required("Email id is required")
    .email("Enter a valid email"),
  userName: Yup.string().required("Required"),

  password: Yup.string().required("Required"),
  confirmPassword: Yup.string()
    .required("Password is required")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: () => Yup.string().oneOf([Yup.ref("password")], "Both password need to be the same"),
    }),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  doorNo: Yup.string().required("Required"),
  streetName: Yup.string().required("Required"),
  pinCode: Yup.string().required("Required"),
  villageName: Yup.string().required("Required"),
  townName: Yup.string().required("Required"),
  talukId: Yup.string().required("Required"),
  districtId: Yup.string().required("Required"),
  stateId: Yup.string().required("Required"),
  countryId: Yup.string().required("Required"),
  businessType: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  sharingType: Yup.string().required("Required"),
  mso: Yup.object()
    .nullable()
    .when("businessType", {
      is: (val) => (val && (val == "LOCAL_CABLE_OPERATOR" || val == "DISTRIBUTOR") ? true : false),
      then: () =>
        Yup.object()
          .shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
          .nullable()
          .required("MSO is required"),
    }),
  // distributor: Yup.object()
  //   .nullable()
  //   .when("businessType", {
  //     is: (val) => (val && val == "LOCAL_CABLE_OPERATOR" ? true : false),
  //     then: () =>
  //       Yup.object()
  //         .shape({
  //           label: Yup.string().required(),
  //           value: Yup.string().required(),
  //         })
  //         .nullable()
  //         .required("Distributor is required"),
  //   }),
  planGroup: Yup.object()
    .nullable()
    .when("businessType", {
      is: (val) => (val && val == "LOCAL_CABLE_OPERATOR" ? true : false),
      then: () =>
        Yup.object()
          .shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
          .nullable()
          .required("Sharing group is required"),
    }),
  keyId: Yup.string()
    .required("Razor pay key id is required")
    .when("paymentGateway", {
      is: (val) => (val && val.label == "RazorPay" ? true : false),
      then: () => Yup.string().required("Razor pay key id is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
  keyValue: Yup.string()
    .required("Razor pay key value is required")
    .when("paymentGateway", {
      is: (val) => (val && val.label == "RazorPay" ? true : false),
      then: () => Yup.string().required("Razor pay key value is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
  webHookSecret: Yup.string()
    .required("Razor pay webhook is required")
    .when("paymentGateway", {
      is: (val) => (val && val.label == "RazorPay" ? true : false),
      then: () => Yup.string().required("Razor pay webhook is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
});

const useTabStyles = styled({
  root: {
    justifyContent: "center",
    width: "1000px",
  },
  scroller: {
    flexGrow: "0",
  },
});

function AddTenant() {
  const dispatch = useDispatch();
  const initialValues = {
    tenantName: "",
    mobileNo: "",
    emailId: "",
    officialMobileNo: "",
    officialEmailId: "",
    firstName: "",
    lastName: "",
    gstNo: "",
    businessType: "",
    userName: "",
    doorNo: "",
    streetName: "",
    pinCode: "",
    talukId: "",
    townName: "",
    districtId: "",
    stateId: "",
    countryId: "",
    streetName: "",
    villageName: "",
    mso: null,
    distributor: null,
    planGroup: null,
    sharingType: "FIXED",
    paymentGateway: null,
    razorPayKeyId: "",
    razorPayKeyValue: "",
    razorPayWebHookSecret: "",
    // msoSharing: 0,
    // distSharing: 0,
    // lcoSharing: 0,
    ipTV: null,
    status: "ACTIVE",
  };

  const classes = useTabStyles();

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  React.useEffect(() => {
    let errorTabs = [];

    for (const [validationKey, fields] of Object.entries(errors)) {
      for (const [key, tablField] of Object.entries(tabFields)) {
        if (tablField.indexOf(validationKey) !== -1) {
          errorTabs.push(Number(key));
        }
      }
    }
    const latestTab = Math.min(...errorTabs);

    if (latestTab !== Infinity) {
      setValue(latestTab);
    }
  }, [errors]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [status, setStatus] = useState({
    sent: false,
  });

  console.log(errors, "error in add tennt");

  const { t } = useTranslation();
  const { addTenant, hasError, errorMessage } = useTenant();
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Cpanel</Typography>
        <Typography>Tenant</Typography>
        <Typography>Add</Typography>
      </Breadcrumbs>
    );
  };

  const onSubmit = async (data) => {
    try {
      const msoDto = {
        tenantId: data?.mso?.value,
      };
      const distributorDto = {
        tenantId: data?.distributor?.value,
      };
      data["mso"] = msoDto;
      data["distributor"] = distributorDto;
      data["taluk"] = {
        talukId: data?.talukId,
      };
      data["district"] = {
        districtId: data?.districtId,
      };
      data["state"] = {
        stateId: data?.stateId,
      };
      data["country"] = {
        countryId: data?.countryId,
      };
      console.log(data.iptv, "data?.distributor?.value");
      if (msoDto.tenantId == undefined) {
        delete data["mso"];
      }
      if (distributorDto.tenantId == undefined) {
        delete data["distributor"];
      }

      if (data.planGroup != undefined) {
        data["planGroup"] = {
          planGroupId: data?.planGroup?.value,
          planGroupName: data?.planGroup?.label,
        };
      }

      if (data.ipTV != null) {
        data["ipTV"] = {
          ipTvId: data.iptv.value,
        };
      }
      console.log(data.paymentGateway);
      if (data.paymentGateway != undefined) {
        data["paymentGateway"] = {
          paymentGatewayId: data?.paymentGateway?.value,
          paymentGatewayName: data?.paymentGateway?.label,
        };
      }

      let payload = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != ""));

      //let newPayload = clearEmpties(payload);

      console.log(payload, "payloadpayload11111");

      await addTenant(payload);
      dispatch(showSuccessSnackbar("Tenant added successfully!"));
      //setStatus({ sent: true });
      reset();
    } catch (err) {
      //setStatus({ sent: false });
      //setErrorSubmit({ submit: err.message });
      let messageDetails = [];
      if (err.errors != undefined) {
        let errors = err.errors;
        for (let error in errors) {
          messageDetails.push(errors[error].field + " " + errors[error].message);
        }
        dispatch(showErrorSnackbar(messageDetails.join(",")));
      } else {
        dispatch(showErrorSnackbar(err.message));
      }
    }
  };

  const clearEmpties = (o) => {
    for (var k in o) {
      if (!o[k] || typeof o[k] !== "object") {
        continue; // If null or not an object, skip to the next iteration
      }

      // The property is an object
      if (Object.keys(o[k]).length === 0) {
        delete o[k]; // The object had no properties, so delete that property
      }
      return o;
    }
  };

  const cancelBtn = () => {
    navigate("/cpanel/tenant/list");
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_franchise")} subheader={breadCrump()} />
          <CardContent>
            {/* {status && status.sent && (
              <Alert onClose={() => {}} severity="success" my={3}>
                <AlertTitle>Success</AlertTitle>
                Tenant added successfully!
              </Alert>
            )} */}

            {/* {errorSubmit && errorSubmit.submit && (
              <Alert severity="error" my={3}>
                {errorSubmit.submit}
              </Alert>
            )} */}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    classes={{ scroller: classes.scroller }}
                    value={value}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="tenant"
                    sx={{
                      justifyContent: "center",
                      width: {
                        xs: "100%", // Full width on mobile devices
                        sm: "500px", // Fixed width on small devices
                        md: "700px", // Wider on medium devices
                        lg: "900px", // Even wider on larger devices
                      },
                      minWidth: "300px", // Ensure a minimum width across all devices
                      maxWidth: "100%", // Ensure it doesn't overflow the container
                    }}
                  >
                    {" "}
                    <Tab label="Franchise Details" {...a11yProps(0)} />
                    <Tab label="Address" {...a11yProps(1)} />
                    <Tab label="Login Details" {...a11yProps(2)} />
                    <Tab label="Contact Details" {...a11yProps(3)} />
                    <Tab label="IpTv Settings" {...a11yProps(3)} />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <TenantDetail fields={validationSchema.fields} />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <AddressDetail fields={validationSchema.fields} />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <LoginDetail fields={validationSchema.fields} />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <ContactDetail fields={validationSchema.fields} />
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <IpTvSettings fields={validationSchema.fields} />
                  </TabPanel>
                </Box>

                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default AddTenant;
