import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  Button,
  Typography,
  CardContent,
  Box,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import Toolbar from "../../../components/Toolbar";
import useWindowDimensions from "../../../utils/windowDimensions";
import usePaidInvoice from "../../../hooks/usePaidInvoice";
// import AdvanceFilter from "./components/AdvanceFilter";
import useAuth from "../../../hooks/useAuth";
import useMainContentDimensions from "../../../utils/mainContentDimensions";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function OnlineCards(props) {
  const { user } = useAuth();
  console.log(user, "llllllllllllll");
  const { isLoadingDownload, downloadFileType } = usePaidInvoice();
  const { width: mainContentWidth } = useMainContentDimensions();

  const { state } = useLocation();
  const { t } = useTranslation();

  const { radUserId } = props;

  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "createdAt",
        sort: "DESC",
      },
    ],
  };

  useEffect(() => {
    if (radUserId != undefined) {
      const payloadValues = {
        ...currentPayload,
        filters: [
          {
            fieldName: "radUserId",
            fieldValue: radUserId,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
            attributeName: "radUser",
            entityName: "RadUserEntity",
          },
        ],
      };
      setPayload(payloadValues);
    } else {
      setPayload(currentPayload);
    }
  }, [radUserId]);

  useEffect(() => {
    if (user != undefined && user.businessType == "USER") {
      const payloadValues = {
        ...currentPayload,
        filters: [
          {
            fieldName: "radUserId",
            fieldValue: user.radUserId,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
            attributeName: "radUser",
            entityName: "RadUserEntity",
          },
        ],
      };
      setPayload(payloadValues);
    } else {
      setPayload(currentPayload);
    }
  }, [user]);

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height } = useWindowDimensions();
  const { getPaidInvoice, getAllPaidInvoice, paidInvoices, paging, downloadFile } =
    usePaidInvoice();
  const { openDialog } = useDialog();

  const [payload, setPayload] = useState({});

  const [filterResetFunc, setFilterResetFunc] = useState(
    () => () => console.log("Refresh initialized"),
  );

  const fetchData = async () => {
    if (Object.keys(payload).length != 0) {
      await getAllPaidInvoice(payload);
    }
  };

  const refreshPage = () => {
    if (radUserId != undefined) {
      const payloadValues = {
        ...currentPayload,
        filters: [
          {
            fieldName: "radUserId",
            fieldValue: radUserId,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
            attributeName: "radUser",
            entityName: "RadUserEntity",
          },
        ],
      };
      setPayload(payloadValues);
    } else {
      setPayload(currentPayload);
    }
    filterResetFunc();
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: currentPayload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Card System</Typography>
        <Typography>Online Cards</Typography>
      </Breadcrumbs>
    );
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const columns = [
    {
      field: "accType",
      headerName: "A/C Type",
      width: 130,
    },
    {
      field: "cuName",
      headerName: "Customer Name",
      width: 130,
    },
    {
      field: "ipAddress",
      headerName: "IP Address",
      width: 130,
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 130,
    },
    {
      field: "mac",
      headerName: "Mac",
      width: 130,
    },
    {
      field: "userService",
      headerName: "User Service",
      width: 150,
    },
    {
      field: "runningService",
      headerName: "Running Service",
      width: 130,
    },
    {
      field: "startTime",
      headerName: "Start Time",
      width: 130,
    },
    {
      field: "runningService",
      headerName: "Online Time",
      width: 130,
    },
    {
      field: "downloaded",
      headerName: "Downloaded",
      width: 130,
    },
    {
      field: "uploaded",
      headerName: "Uploaded",
      width: 130,
    },
    {
      field: "total",
      headerName: "Total",
      width: 130,
    },
    {
      field: "nasIp",
      headerName: "NAS IP",
      width: 130,
    },
  ];

  // const openFilterDialog = () => {
  //   return openDialog(
  //     "Advance Filter",
  //     true,
  //     <AdvanceFilter
  //       payload={payload}
  //       setPayload={setPayload}
  //       setFilterResetFunc={setFilterResetFunc}
  //     />,
  //   );
  // };

  return (
    <Card sx={{ width: "100%", maxWidth: mainContentWidth - 64 }}>
      <CardHeader
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // Stack on small screens
          justifyContent: "space-between",
          alignItems: "flex-start", // Adjust alignment as needed
        }}
        action={
          <Toolbar
            downloadFile={downloadFile}
            refreshPage={refreshPage}
            // openFilterDialog={openFilterDialog}
            isLoadingDownload={isLoadingDownload}
            downloadFileType={downloadFileType}
          />
        }
        title={
          <Typography variant="h6" sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem" } }}>
            {t("online_cards")}
          </Typography>
        }
        subheader={<Box sx={{ display: "flex", flexWrap: "wrap" }}>{breadCrump()}</Box>}
      />
      <CardContent sx={{ height: height - 180, display: "flex", flexDirection: "column" }}>
        <Box sx={{ flex: 1, overflow: "auto" }}>
          <DataGrid
            columns={columns}
            rows={paidInvoices}
            refreshPage={fetchData}
            getRowId={(row) => row["renewId"]}
            rowCount={paging.totalNumberOfRecords}
            loading={false}
            rowsPerPageOptions={[5]}
            pagination
            page={paging.pageNumber}
            pageSize={paging.pageSize}
            paginationMode="server"
            onPageChange={(page) => onPageChange(page)}
            onPageSizeChange={(pageSize) => onPageChange(pageSize)}
            checkboxSelection
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
          />
        </Box>
      </CardContent>
    </Card>
  );
}

export default OnlineCards;
