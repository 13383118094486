import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, MenuItem } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import useEmployee from "../../../../hooks/useEmployee";
import useSmsTemplate from "../../../../hooks/useSmsTemplate";
import useBranch from "../../../../hooks/useBranch";

import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import FilterReset from "../../../../components/FilterReset";
import { searchPayload, convertedValue } from "../../../../utils/common";
import { OperatorConstants } from "../../../../constants/operator.constants";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import CustomTextField from "../../../../components/CustomTextField";
import { useDialog } from "../../../../contexts/DialogContext";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const statusOptions = [
  {
    label: "Active",
    value: "A",
  },
  {
    label: "InActive",
    value: "INA",
  },
];

function AdvanceFilter(props) {
  const { unSetDialog } = useDialog();
  const initialValues = {
    smsTemplateNameOperator: "eq",
    smsTemplateCodeOperator: "eq",
    statusOperator: "eq",
    smsTemplateName: [],
    smsTemplateCode: "",
    status: [],
  };

  const { fields, payload, setPayload, setFilterResetFunc } = props;

  const { autoSearchSmsTemplate, smsTemplateAutoSearchResults, isLoadingAutoSearch } =
    useSmsTemplate();

  const [smsTemplatePayload, setSmsTemplatePayload] = useState(currentPayload);
  const [smsTemplateOptions, setSmsTemplateOptions] = useState([]);
  const [smsTemplateMultiValue, setSmsTemplateMultiValue] = useState(false);
  const [statusMultiValue, setStatusMultiValue] = useState(false);

  const [defaultSmsTemplate, setDefaultSmsTemplate] = useState([]);

  const [defaultStatus, setDefaultStatus] = useState([]);

  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = methods;

  useEffect(() => {
    setFilterResetFunc(() => reset);
  }, [reset]);

  const smsTemplateOpValue = watch("smsTemplateNameOperator");

  const statusOpValue = watch("statusOperator");

  const onSubmit = async (data) => {
    try {
      let filters = [];

      if (data.smsTemplateName != null && Object.keys(data.smsTemplateName).length != 0) {
        filters.push({
          fieldName: "smsTemplateId",
          fieldValue: convertedValue(data.smsTemplateName, "value").join(","),
          op: data.smsTemplateNameOperator,
          dataType: "int",
          condition: "and",
          isJoin: false,
        });
      }
      if (data.smsTemplateCode != "" && data.smsTemplateCode != null) {
        filters.push({
          fieldName: "smsTemplateCode",
          fieldValue: data.smsTemplateCode,
          op: data.smsTemplateCodeOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }
      if (data.status != null && Object.keys(data.status).length != 0) {
        filters.push({
          fieldName: "status",
          fieldValue: convertedValue(data.status, "value").join(","),
          op: data.statusOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }
      setPayload({
        ...payload,
        filters: filters,
      });
      unSetDialog("advance_filter");
    } catch (err) {
      console.log(err, "errerrerrerr");
    }
  };

  useEffect(() => {
    if (smsTemplateOpValue == "in" || smsTemplateOpValue == "nin") {
      setDefaultSmsTemplate([]);
      setValue("smsTemplateName", []);
      setSmsTemplateMultiValue(true);
    } else {
      setDefaultSmsTemplate({});
      setValue("smsTemplateName", {});
      setSmsTemplateMultiValue(false);
    }
  }, [smsTemplateOpValue]);

  useEffect(() => {
    if (statusOpValue == "in" || statusOpValue == "nin") {
      setDefaultStatus([]);
      setValue("status", []);
      setStatusMultiValue(true);
    } else {
      setDefaultStatus({});
      setValue("status", {});
      setStatusMultiValue(false);
    }
  }, [statusOpValue]);

  const fetchSmsTemplate = async () => {
    await autoSearchSmsTemplate(smsTemplatePayload);
  };
  useEffect(() => {
    fetchSmsTemplate();
  }, [smsTemplatePayload]);

  useEffect(() => {
    if (smsTemplateAutoSearchResults.data.length !== 0) {
      setSmsTemplateOptions([...smsTemplateOptions, ...smsTemplateAutoSearchResults.data]);
    }
  }, [smsTemplateAutoSearchResults]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4} pt={8}></Grid>

          <Grid container spacing={12} pt={8}>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <ReactHookFormSelect
                name="smsTemplateNameOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <SearchableAutoComplete
                control={control}
                data={smsTemplateOptions}
                label={t("template_name")}
                payload={smsTemplatePayload}
                setPayload={setSmsTemplatePayload}
                loading={isLoadingAutoSearch}
                name="smsTemplateName"
                multiple={smsTemplateMultiValue}
                defaultValue={defaultSmsTemplate}
              />
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <ReactHookFormSelect
                name="smsTemplateCodeOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.string).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                label={t("template_code")}
                name="smsTemplateCode"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
          </Grid>
          <Grid container spacing={12} pt={8}>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <ReactHookFormSelect
                name="statusOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((t, k) => (
                  <MenuItem key={k} value={t[0]}>
                    {t[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <SearchableAutoComplete
                control={control}
                data={statusOptions}
                label={t("Status")}
                payload={null}
                setPayload={null}
                loading={false}
                name="status"
                multiple={statusMultiValue}
                defaultValue={defaultStatus}
              />
            </Grid>
          </Grid>
          <FilterReset resetForm={reset} />
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default AdvanceFilter;
