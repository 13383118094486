import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Grid, ButtonBase, Typography, Button, Tabs, Tab, Box, Paper, Avatar } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import {
  Refresh,
  Edit as EditIcon,
  PublishedWithChanges as PublishedWithChangesIcon,
  Password as PasswordIcon,
  WifiPassword as WifiPasswordIcon,
  Call as CallIcon,
  Mail as MailIcon,
  MobileFriendly as MobileFriendlyIcon,
  Person as PersonIcon,
  CurrencyExchangeRounded as CurrencyExchangeRoundedIcon,
  PendingActions as PendingActionsIcon,
  Payments as PaymentsIcon,
  CreditScore as CreditScoreIcon,
} from "@mui/icons-material";
import { a11yProps } from "../../../utils/common";
import TabPanel from "../../../components/TabPanel";
import useUser from "../../../hooks/useUser";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import BasicDetails from "./BasicDetails";
import ProfileImg from "../../icons/profile_img.png";
import RenewUser from "../userlist/components/RenewUser";
import ChangePlan from "../userlist/components/ChangePlan";
import ChangeAppPassword from "../userlist/components/ChangeAppPass";
import ChangeRouterPassword from "../userlist/components/ChangeRouterPass";
import PaidInvoice from "../../invoices/paid_invoices";
import UnpaidInvoice from "../../invoices/unpaid_invoices";
import WalletEntries from "./WalletEntries";
import PlanHistory from "./PlanHistory";
import Complaint from "../../complaint";
import TrafficReport from "./TrafficReport";
import ProformaInvoices from "./ProformaInvoices";
import SessionHistory from "./SessionHistory";
import CancelledInvoices from "../../users/user_details/CancelledInvoices";
import AuthenticationLogs from "./AuthenticationLogs";
import KycDocuments from "./KycDocuments";
import Materials from "./materials";
import ScheduleRenewalScreen from "../userlist/components/Schedule";
import ScreenshotMonitorIcon from "@mui/icons-material/ScreenshotMonitor";
import AutoRenewal from "../userlist/components/AutoRenewal";
import useScheduleUser from "../../../hooks/useScheduleUser";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import SuspendUser from "../userlist/components/SuspendUser";
import WalletIcon from "@mui/icons-material/Wallet";
import AddWallet from "../userlist/components/AddWallet";
import DisconnectUser from "./DisconnectUser";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ProformaInvoice from "../userlist/components/ProformaInvoice";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import TimerIcon from "@mui/icons-material/Timer";
import UpdateDisabledIcon from "@mui/icons-material/UpdateDisabled";
import CloseSession from "./CloseSession";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import SpeedGraph from "./SpeedGraph";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShowAppPassword from "../userlist/components/ShowApp";
import ShowRouterPass from "../userlist/components/ShowRouter";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import ChangeValidity from "../userlist/components/ChangeValidity";
import OtherDetails from "./OtherDetails";
const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [
    {
      field: "createdAt",
      sort: "DESC",
    },
  ],
};
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});
const useTabStyles = styled({
  root: {
    justifyContent: "center",
    width: "1000px",
  },
  scroller: {
    flexGrow: "0",
  },
});
function NormalUser(props) {
  console.log(props, "normal user details");
  const classes = useTabStyles();
  let [searchParams, setSearchParams] = useSearchParams();
  const { getUser, user, getUserDetailsInvoice, userDetailsInvoice } = useUser();
  const { getActiveScheduleUser } = useScheduleUser();
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const [payload, setPayload] = useState(currentPayload);
  const [selectedRows, setSelectedRows] = useState([]);
  const fetchData = async () => {
    await getUser(searchParams.get("id"));
  };
  const fetchScheduleData = async () => {
    await getActiveScheduleUser(searchParams.get("id"));
  };
  const [filterResetFunc, setFilterResetFunc] = useState(
    () => () => console.log("Refresh initialized"),
  );

  const refreshPage = () => {
    setPayload(currentPayload);
    fetchData();
    filterResetFunc();
  };
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    if (searchParams && searchParams.get("id")) {
      fetchData();
      fetchScheduleData();
    }
  }, [searchParams]);
  const editUser = () => {
    navigate("/user/userlist/edit", { state: { id: searchParams.get("id") } });
  };

  const renewClick = (id) => {
    return openDialog(
      "renew_user",
      "Renew User",
      <RenewUser key={getTimeStamp()} userId={searchParams.get("id")} />,
    );
  };
  const changePlan = (id) => {
    return openDialog(
      "change_plan",
      "Change plan",
      <ChangePlan key={getTimeStamp()} userId={searchParams.get("id")} />,
    );
  };
  const changeRouterPass = (id) => {
    return openDialog(
      "change_router_password",
      "Change Router Password",
      <ChangeRouterPassword key={getTimeStamp()} userId={searchParams.get("id")} />,
    );
  };
  const changeAppPass = (id) => {
    return openDialog(
      "change_app_password",
      "Change App Password",
      <ChangeAppPassword key={getTimeStamp()} userId={searchParams.get("id")} />,
    );
  };
  const changeScheduleRenew = (id) => {
    return openDialog(
      "schedule",
      "Schedule",
      <ScheduleRenewalScreen key={getTimeStamp()} userId={searchParams.get("id")} />,
    );
  };
  const caRenewal = (id) => {
    return openDialog(
      "auto_renewal",
      "Auto Renewal",
      <AutoRenewal key={getTimeStamp()} userId={searchParams.get("id")} />,
    );
  };
  const suspenduser = (id) => {
    return openDialog(
      "suspend",
      "Suspend",
      <SuspendUser key={getTimeStamp()} userId={searchParams.get("id")} fetchData={fetchData} />,
    );
  };
  const addWallet = (id) => {
    return openDialog(
      "add_wallet",
      "Add Wallet",
      <AddWallet key={getTimeStamp()} userId={searchParams.get("id")} />,
    );
  };
  const invoiceClick = (id) => {
    return openDialog(
      "proforma_invoice",
      "Proforma Invoice",
      <ProformaInvoice key={getTimeStamp()} userId={searchParams.get("id")} />,
    );
  };
  const disconnectUser = () => {
    return openDialog(
      "disconnect_user",
      "Disconnect User",
      <DisconnectUser
        key={getTimeStamp()}
        userId={searchParams.get("id")}
        refreshPage={refreshPage}
      />,
    );
  };
  const closeClick = (id) => {
    return openDialog(
      "close_user_session",
      "Close User Session",
      <CloseSession
        key={getTimeStamp()}
        userId={searchParams.get("id")}
        refreshPage={refreshPage}
      />,
    );
  };

  const showClick = (id) => {
    return openDialog(
      "show_graph",
      "Show Graph",
      <SpeedGraph key={getTimeStamp()} userId={user?.userName} refreshPage={refreshPage} />,
    );
  };

  const showRouterClick = (id) => {
    return openDialog(
      "show_router_password",
      "Show Router Password",
      <ShowRouterPass
        key={getTimeStamp()}
        userId={searchParams.get("id")}
        refreshPage={refreshPage}
      />,
    );
  };

  const showAppClick = (id) => {
    return openDialog(
      "show_app_password",
      "Show App Password",
      <ShowAppPassword
        key={getTimeStamp()}
        userId={searchParams.get("id")}
        refreshPage={refreshPage}
      />,
    );
  };

  const changeValidityClick = (id) => {
    return openDialog(
      "change_validity",
      "Change Validity",
      <ChangeValidity
        key={getTimeStamp()}
        userId={searchParams.get("id")}
        refreshPage={refreshPage}
      />,
    );
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid container spacing={2}>
          <Grid item>
            <ButtonBase sx={{ width: 128, height: 128 }}>
              <Img alt="complex" src={ProfileImg} />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="row" spacing={2}>
              <Grid item xs>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <PaymentsIcon fontSize="large" />
                  </Grid>
                  <Grid item>Invoice Amount</Grid>
                  <Grid item>{user.totalInvoiceAmount}</Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <CreditScoreIcon fontSize="large" />
                  </Grid>
                  <Grid item>Total Paid</Grid>
                  <Grid item>{user.paidAmount}</Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <PendingActionsIcon fontSize="large" />
                  </Grid>
                  <Grid item>Due Amount</Grid>
                  <Grid item>{user.dueAmount}</Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <PersonIcon fontSize="large" />
                  </Grid>
                  <Grid item>User Name</Grid>
                  <Grid item>{user.userName}</Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <MobileFriendlyIcon fontSize="large" />
                  </Grid>
                  <Grid item>Mobile</Grid>
                  <Grid item>{user.mobileNo}</Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <MailIcon fontSize="large" />
                  </Grid>
                  <Grid item>Email</Grid>
                  <Grid item>
                    <a href={"mailto: " + user.emailId}>{user.emailId}</a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="row" spacing={1}>
              {props.permissionKeys && props.permissionKeys.includes("refresh") && (
                <Grid item>
                  <Button variant="contained" startIcon={<Refresh />} onClick={() => refreshPage()}>
                    Refresh
                  </Button>
                </Grid>
              )}
              {props.permissionKeys && props.permissionKeys.includes("edit") && (
                <Grid item>
                  <Button variant="contained" startIcon={<EditIcon />} onClick={() => editUser()}>
                    Edit
                  </Button>
                </Grid>
              )}
              {props.permissionKeys && props.permissionKeys.includes("renew") && (
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<PublishedWithChangesIcon />}
                    onClick={() => renewClick()}
                  >
                    Renew
                  </Button>
                </Grid>
              )}
              {props.permissionKeys && props.permissionKeys.includes("change_plan") && (
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<CurrencyExchangeRoundedIcon />}
                    onClick={() => changePlan()}
                  >
                    Change Plan
                  </Button>
                </Grid>
              )}
              {props.permissionKeys && props.permissionKeys.includes("change_router_password") && (
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<WifiPasswordIcon />}
                    onClick={() => changeRouterPass()}
                  >
                    Change Router Pwd
                  </Button>
                </Grid>
              )}
              {props.permissionKeys && props.permissionKeys.includes("change_app_password") && (
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<PasswordIcon />}
                    onClick={() => changeAppPass()}
                  >
                    Change App Pwd
                  </Button>
                </Grid>
              )}
              {props.permissionKeys && props.permissionKeys.includes("schedule") && (
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<PendingActionsIcon />}
                    onClick={() => changeScheduleRenew()}
                  >
                    Schedule
                  </Button>
                </Grid>
              )}
              {props.permissionKeys && props.permissionKeys.includes("auto_renewal") && (
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<ScreenshotMonitorIcon />}
                    onClick={() => caRenewal()}
                  >
                    Auto Renewal
                  </Button>
                </Grid>
              )}
              {props.permissionKeys && props.permissionKeys.includes("suspend") && (
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<RunningWithErrorsIcon />}
                    onClick={() => suspenduser()}
                  >
                    Suspend
                  </Button>
                </Grid>
              )}
              {props.permissionKeys && props.permissionKeys.includes("wallet") && (
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<WalletIcon />}
                    onClick={() => addWallet()}
                  >
                    Wallet
                  </Button>
                </Grid>
              )}
              {props.permissionKeys && props.permissionKeys.includes("proforma_invoices") && (
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<ReceiptIcon />}
                    onClick={() => invoiceClick()}
                  >
                    Proforma Invoices
                  </Button>
                </Grid>
              )}

              {user.userStatus == "online" &&
                props.permissionKeys &&
                props.permissionKeys.includes("user_disconnect") && (
                  <Grid item>
                    <Button
                      variant="contained"
                      startIcon={<PersonOffIcon />}
                      onClick={() => disconnectUser()}
                    >
                      Disconnect User
                    </Button>
                  </Grid>
                )}
              {user.userStatus == "online" &&
                props.permissionKeys &&
                props.permissionKeys.includes("session_close") && (
                  <Grid item>
                    <Button
                      variant="contained"
                      startIcon={<TimerIcon />}
                      onClick={() => closeClick()}
                    >
                      Close User Session
                    </Button>
                  </Grid>
                )}
              {user.userStatus == "online" &&
                props.permissionKeys &&
                props.permissionKeys.includes("show_graph") && (
                  <Grid item>
                    <Button
                      variant="contained"
                      startIcon={<ShowChartIcon />}
                      onClick={() => showClick()}
                    >
                      Show Graph
                    </Button>
                  </Grid>
                )}
              {props.permissionKeys && props.permissionKeys.includes("show_router_pwd") && (
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<VisibilityIcon />}
                    onClick={() => showRouterClick()}
                  >
                    Show Router Password
                  </Button>
                </Grid>
              )}
              {props.permissionKeys && props.permissionKeys.includes("show_app_pwd") && (
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<VisibilityIcon />}
                    onClick={() => showAppClick()}
                  >
                    Show App Password
                  </Button>
                </Grid>
              )}
              {props.permissionKeys && props.permissionKeys.includes("change_validity") && (
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<MoreTimeIcon />}
                    onClick={() => changeValidityClick()}
                  >
                    Change Validity
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            flexGrow: 1,
            marginTop: 4,
            width: "100%",
          }}
        >
          <Tabs
            classes={{ scroller: classes.scroller }}
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            aria-label="Company"
            scrollButtons
            allowScrollButtonsMobile
            sx={{
              justifyContent: "center",
              width: {
                xs: "100%", // Full width on mobile devices
                sm: "500px", // Fixed width on small devices
                md: "700px", // Wider on medium devices
                lg: "1000px", // Even wider on larger devices
              },
              minWidth: "300px", // Ensure a minimum width across all devices
              maxWidth: "100%", // Ensure it doesn't overflow the container
            }}
          >
            <Tab label="Basic Details" {...a11yProps(0)} />
            <Tab label="Wallet Entries" {...a11yProps(1)} />
            <Tab label="Unpaid Invoices" {...a11yProps(2)} />
            <Tab label="Paid Invoices" {...a11yProps(3)} />
            <Tab label="Cancelled Invoices" {...a11yProps(4)} />
            <Tab label="Plan History" {...a11yProps(5)} />
            <Tab label="KYC Documents" {...a11yProps(6)} />
            <Tab label="Complaints" {...a11yProps(7)} />
            <Tab label="Traffic Report" {...a11yProps(8)} />
            <Tab label="Proforma Invoices" {...a11yProps(9)} />
            <Tab label="Session History" {...a11yProps(10)} />
            <Tab label="Authentication Logs" {...a11yProps(11)} />
            <Tab label="Materials" {...a11yProps(12)} />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl>
                <BasicDetails user={user} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl>
                <OtherDetails user={user} />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <WalletEntries />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <UnpaidInvoice radUserId={user?.radUserId} />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <PaidInvoice radUserId={user?.radUserId} />
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <CancelledInvoices
              setPayload={setPayload}
              payload={payload}
              setFilterResetFunc={setFilterResetFunc}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={5}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={12} xl>
                <PlanHistory />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={6}>
            <KycDocuments radUserId={user?.radUserId} />
          </TabPanel>
          <TabPanel value={tabValue} index={7}>
            <Complaint
              radUserId={user?.radUserId}
              userName={user?.userName}
              backTo={"/user/details?id=" + user?.radUserId}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={8}>
            <TrafficReport />
          </TabPanel>
          <TabPanel value={tabValue} index={9}>
            <ProformaInvoices />
          </TabPanel>
          <TabPanel value={tabValue} index={10}>
            <SessionHistory />
          </TabPanel>
          <TabPanel value={tabValue} index={11}>
            <AuthenticationLogs />
          </TabPanel>
          <TabPanel value={tabValue} index={12}>
            <Materials />
          </TabPanel>
        </Box>
      </Grid>
    </React.Fragment>
  );
}
export default NormalUser;
