import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { useNavigate } from "react-router-dom";

import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes/index";
import useAuth from "./hooks/useAuth";
import ErrorBoundaryComponent from "./components/ErrorBoundaryComponent";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";

import { AuthProvider } from "./contexts/JWTContext";

// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";

function App() {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  console.log("loading");

  return (
    <ErrorBoundaryComponent>
      <HelmetProvider>
        <Helmet titleTemplate="%s | HyperRadius" defaultTitle="HyperRadius - Dashboard" />
        <Provider store={store}>
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={createTheme(theme)}>
              <AuthProvider>{content}</AuthProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
          {/* </LocalizationProvider> */}
        </Provider>
      </HelmetProvider>
    </ErrorBoundaryComponent>
  );
}

export default App;
