import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert as MuiAlert, Button, DialogActions, Box, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useDialog } from "../../../../contexts/DialogContext";
import usePolicy from "../../../../hooks/usePlan";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import useTenant from "../../../../hooks/useTenant";
import useAddon from "../../../../hooks/useAddon";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";
import { useTranslation } from "react-i18next";

const initialValues = {
  tenant: [],
};

const validationSchema = Yup.object().shape({
  tenant: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.number().required(),
      }),
    )
    .min(1, "You need at least one tenant")
    .required("Please select the tenant"),
});

const Alert = styled(MuiAlert)(spacing);

const MapTenantModal = ({ addOnId }) => {
  const { unSetDialog } = useDialog();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { autoSearchTenant, tenantAutoSearchResults, autoSearchLoading, isSubmitting } =
    useTenant();
  const { getAddOnTenants, addAddOnTenant } = useAddon();
  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };

  const [payload, setPayload] = useState(currentPayload);

  const [addOnTenantPayload, setAddOnTenantPayload] = useState({
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  });

  const [tenantOptions, setTenantOptions] = useState([]);

  const fetchData = async () => {
    await autoSearchTenant(payload);
  };

  useEffect(() => {
    if (tenantAutoSearchResults.data.length !== 0) {
      setTenantOptions([...tenantOptions, ...tenantAutoSearchResults.data]);
    }
  }, [tenantAutoSearchResults]);

  useEffect(() => {
    fetchData();
  }, [payload]);

  const { handleSubmit, control, reset } = methods;

  const onSubmit = async (data) => {
    try {
      const addOnTenantDetails = [];
      const tenants = data.tenant;
      for (let i = 0; i < tenants.length; i++) {
        addOnTenantDetails.push({
          tenantId: tenants[i]["value"],
          tenantName: tenants[i]["label"],
          addOn: {
            addOnId: addOnId,
          },
        });
      }
      //console.log(policyDetails, "policyDetails");
      await addAddOnTenant(addOnTenantDetails);
      await getAddOnTenants(addOnTenantPayload);
      reset();
      dispatch(showSuccessSnackbar("Tenant mapped successfully!"));
      unSetDialog("advance_filter");
    } catch (err) {
      dispatch(showErrorSnackbar(err.message));
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 10 }}>
            <SearchableAutoComplete
              control={control}
              data={tenantOptions}
              label={t("select_franchise")}
              payload={payload}
              setPayload={setPayload}
              loading={autoSearchLoading}
              name="tenant"
              multiple={true}
            />
          </Box>
          <DialogActions>
            <Button
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              endIcon={isSubmitting ? <CircularProgress size={24} /> : null}
            >
              Add
            </Button>

            <Button variant="text" onClick={() => unSetDialog("map_tenant_modal")} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default MapTenantModal;
