//Users
import UserList from "../pages/users/userlist";
import AddUser from "../pages/users/userlist/add";
import EditUser from "../pages/users/userlist/edit";
import OnlineUsers from "../pages/users/online";
import InvoiceDetails from "../components/InvoiceDetails";
import Migration from "../pages/users/migration";
import ActiveUsers from "../pages/users/active_users";
import UserDetails from "../pages/users/user_details";
import ExpiredUsers from "../pages/users/expired_users";
import SuspendedUsers from "../pages/users/suspended_users";
import SessionHistory from "../pages/session_history/index.js";

//Composers
import { UserComposer } from "../composers/UserComposer";
import { MigrationComposer } from "../composers/MigrationComposer";
import { DashboardComposer } from "../composers/DashboardComposer";
import { SessionHistoryComposer } from "../composers/SessionHistoryComposer";
import { ScheduleUserComposer } from "../composers/ScheduleUserComposer";
import { BulkOperationComposer } from "../composers/BulkOperationComposer";

// Guards
import AuthGuard from "../components/guards/AuthGuard";
import DashboardLayout from "../layouts/Dashboard";
import ScheduleUser from "../pages/users/user_details/ScheduleUser.js";
import ProtectedLayout from "../layouts/ProtectedLayout";
import BulkOperation from "../pages/users/bulk_operation/index.js";

const UserRoutes = {
  path: "user",
  element: (
    <AuthGuard>
      <DashboardComposer>
        <DashboardLayout />
      </DashboardComposer>
    </AuthGuard>
  ),
  roles: ["SUPER_ADMIN"],
  children: [
    {
      path: "userlist",
      children: [
        {
          path: "add",
          element: (
            <ProtectedLayout>
              <UserComposer>
                <AddUser />
              </UserComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "edit",
          element: (
            <ProtectedLayout>
              <UserComposer>
                <EditUser />
              </UserComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "list",
          element: (
            <ProtectedLayout>
              <UserComposer>
                <UserList />
              </UserComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
    {
      path: "active",
      element: (
        <ProtectedLayout>
          <UserComposer>
            <ActiveUsers />
          </UserComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "details",
      element: (
        <UserComposer>
          <ProtectedLayout>
            <UserDetails />
          </ProtectedLayout>
        </UserComposer>
      ),
    },
    {
      path: "online",
      element: (
        <ProtectedLayout>
          <UserComposer>
            <OnlineUsers />
          </UserComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "expired",
      element: (
        <ProtectedLayout>
          <UserComposer>
            <ExpiredUsers />
          </UserComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "suspended",
      element: (
        <ProtectedLayout>
          <UserComposer>
            <SuspendedUsers />
          </UserComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "migration",
      element: (
        <ProtectedLayout>
          <MigrationComposer>
            <Migration />
          </MigrationComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "bulk",
      element: (
        <ProtectedLayout>
          <BulkOperationComposer>
            <BulkOperation />
          </BulkOperationComposer>
        </ProtectedLayout>
      ),
    },
    {
      path: "invoice",
      element: (
        <ProtectedLayout>
          <UserComposer>
            <InvoiceDetails />
          </UserComposer>
        </ProtectedLayout>
      ),
    },
    {
      children: [
        {
          path: "session-history",
          element: (
            <ProtectedLayout>
              <SessionHistoryComposer>
                <SessionHistory />
              </SessionHistoryComposer>
            </ProtectedLayout>
          ),
        },
        {
          path: "schedule-user",
          element: (
            <ProtectedLayout>
              <ScheduleUserComposer>
                <ScheduleUser />
              </ScheduleUserComposer>
            </ProtectedLayout>
          ),
        },
      ],
    },
  ],
};

export default UserRoutes;
