import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import PaymentProcessReducer from "../reducers/PaymentProcessReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { PaymentProcessConstants } from "../constants/paymentprocess.constants";
import { saveFile } from "../utils/common";
const initialState = {
  isLoading: true,
  paymentProcesses: [],
  depositPaymentProcesses: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  paymentProcessSearchResults: [],
  paymentProcess: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  isLoadingDownload: false,
  paymentProcessAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoading: true,
  downloadFileType: "",
  isLoadingAutoSearch: true,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const PaymentProcessContext = createContext({
  ...initialState,
  getAllPaymentProcess: () => {},
  getAllDepositPaymentProcess: () => {},
  addPaymentProcess: () => {},
  searchPaymentProcess: () => {},
  autoSearchPaymentProcess: () => {},
  getPaymentProcess: () => {},
  updatePaymentProcess: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
});

function PaymentProcessProvider({ children }) {
  const [state, dispatch] = useReducer(PaymentProcessReducer, initialState);

  const getAllPaymentProcess = async (payload) => {
    try {
      dispatch({
        type: PaymentProcessConstants.FETCH_PAYMENT_PROCESS_REQUEST,
      });
      const response = await axios.post("/payment/list", payload);
      dispatch({
        type: PaymentProcessConstants.FETCH_PAYMENT_PROCESS_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: PaymentProcessConstants.FETCH_PAYMENT_PROCESS_FAILURE,
      });
    }
  };

  const getAllDepositPaymentProcess = async (payload) => {
    try {
      dispatch({
        type: PaymentProcessConstants.FETCH_DEPOSIT_PAYMENT_PROCESS_REQUEST,
      });
      const response = await axios.post("/payment/tenant/list", payload);
      dispatch({
        type: PaymentProcessConstants.FETCH_DEPOSIT_PAYMENT_PROCESS_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: PaymentProcessConstants.FETCH_DEPOSIT_PAYMENT_PROCESS_FAILURE,
      });
    }
  };

  const searchPaymentProcess = async (payload) => {
    try {
      const response = await axios.post("/payment/search", payload);
      dispatch({
        type: PaymentProcessConstants.PAYMENT_PROCESS_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: PaymentProcessConstants.PAYMENT_PROCESS_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: PaymentProcessConstants.PAYMENT_PROCESS_SEARCH_FAILURE,
      });
    }
  };
  const autoSearchPaymentProcess = async (payload) => {
    try {
      const response = await axios.post("/payment/auto-search", payload);
      dispatch({
        type: PaymentProcessConstants.PAYMENT_PROCESS_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: PaymentProcessConstants.PAYMENT_PROCESS_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: PaymentProcessConstants.PAYMENT_PROCESS_AUTO_SEARCH_FAILURE,
      });
    }
  };
  const addPaymentProcess = async (payload) => {
    const response = await axios.post("/payment/add", payload);
    dispatch({
      type: PaymentProcessConstants.ADD_PAYMENT_PROCESS_REQUEST,
    });
    dispatch({
      type: PaymentProcessConstants.ADD_PAYMENT_PROCESS_SUCCESS,
      payload: response.data,
    });
  };

  const getPaymentProcess = async (id) => {
    const response = await axios.get("/payment/" + id);
    dispatch({
      type: PaymentProcessConstants.GET_PAYMENT_PROCESS_REQUEST,
    });
    dispatch({
      type: PaymentProcessConstants.GET_PAYMENT_PROCESS_SUCCESS,
      payload: response.data,
    });
  };

  const updatePaymentProcess = async (payload) => {
    const response = await axios.put("/payment/update/" + payload.paymentProcessId, payload);
    dispatch({
      type: PaymentProcessConstants.UPDATE_PAYMENT_PROCESS_REQUEST,
    });
    dispatch({
      type: PaymentProcessConstants.UPDATE_PAYMENT_PROCESS_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: PaymentProcessConstants.DOWNLOAD_PAYMENT_PROCESS_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/payment/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "paymentProcess." + fileType);
    dispatch({
      type: PaymentProcessConstants.DOWNLOAD_PAYMENT_PROCESS_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/payment/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_paymentProcess." + fileType);
    dispatch({
      type: PaymentProcessConstants.DOWNLOAD_PAYMENT_PROCESS_REQUEST,
    });
    dispatch({
      type: PaymentProcessConstants.DOWNLOAD_PAYMENT_PROCESS_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: PaymentProcessConstants.UPLOAD_PAYMENT_PROCESS_REQUEST,
      });
      const response = await axios({
        url: "/payment/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: PaymentProcessConstants.UPLOAD_PAYMENT_PROCESS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: PaymentProcessConstants.UPLOAD_PAYMENT_PROCESS_FAILURE,
      });
    }
  };

  return (
    <PaymentProcessContext.Provider
      value={{
        ...state,
        getAllPaymentProcess,
        getAllDepositPaymentProcess,
        addPaymentProcess,
        searchPaymentProcess,
        autoSearchPaymentProcess,
        getPaymentProcess,
        updatePaymentProcess,
        downloadFile,
        sampleFile,
        uploadFile,
      }}
    >
      {children}
    </PaymentProcessContext.Provider>
  );
}

export { PaymentProcessContext, PaymentProcessProvider };
