import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";

import { Paper as MuiPaper } from "@mui/material";

import { spacing } from "@mui/system";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import useAuth from "../hooks/useAuth";
import { clearSnackbar, selectIsOpen } from "../redux/slices/snackbar";
const drawerWidth = 258;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const ProtectedLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { user, checkUserPermission } = useAuth();

  const isSnackBarOpen = useSelector(selectIsOpen);

  console.log(isSnackBarOpen, "content loaded for every page........");

  const navigate = useNavigate();

  const router = useLocation();
  const currentRoute = router.pathname;
  const [permissions, setPermissions] = React.useState(null);
  const [childrenContent, setChildrenContent] = useState("Loading...");
  const [permissonKey, setPermissionKey] = useState("");

  React.useEffect(() => {
    if (isSnackBarOpen) {
      const timer = setTimeout(() => {
        dispatch(clearSnackbar());
      }, 3000); // Clears snackbar after 3 seconds

      return () => clearTimeout(timer); // Cleanup on unmount or when snackbarVisible changes
    }
  }, [isSnackBarOpen, dispatch]);

  React.useEffect(() => {
    const loadPermissions = async () => {
      let routeSplit = currentRoute.split("/");
      console.log(routeSplit, "routeSplit");
      if (routeSplit.length === 4) {
        const userPermissions = await checkUserPermission(routeSplit[2], routeSplit[3]);
        console.log(userPermissions, "userPermissionsuserPermissions");
        setPermissions(userPermissions);
        setPermissionKey(routeSplit[3]);
      } else if (routeSplit.length === 3) {
        const userPermissions = await checkUserPermission(routeSplit[1], routeSplit[2]);
        console.log(userPermissions, "userPermissionsuserPermissions");
        setPermissions(userPermissions);
        setPermissionKey(routeSplit[2]);
      }
    };
    loadPermissions();
  }, [currentRoute]);

  React.useEffect(() => {
    if (permissions == null) {
      setChildrenContent("Loading...");
    } else {
      if (permissions.length == 0) {
        navigate("/unauthorized");
      } else {
        console.log(permissions, "children0000000000");
        if (permissions["permissionKeys"].includes(permissonKey)) {
          setChildrenContent(React.cloneElement(children, { permissions }));
        } else {
          navigate("/unauthorized");
        }
      }
    }
  }, [permissions, currentRoute]);

  return <MainContent id="content">{childrenContent}</MainContent>;
};

export default ProtectedLayout;
