import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, MenuItem } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import useCountry from "../../../../hooks/useCountry";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import FilterReset from "../../../../components/FilterReset";
import { searchPayload, convertedValue } from "../../../../utils/common";
import { OperatorConstants } from "../../../../constants/operator.constants";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import CustomTextField from "../../../../components/CustomTextField";
import { useDialog } from "../../../../contexts/DialogContext";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

function AdvanceFilter(props) {
  const { unSetDialog } = useDialog();
  const initialValues = {
    countryOperator: "eq",
    codeOperator: "eq",
    country: [],
    countryCode: "",
  };

  const { fields, payload, setPayload } = props;

  const { autoSearchCountry, countryAutoSearchResults, isLoadingAutoSearch } = useCountry();

  const [countryPayload, setCountryPayload] = useState(currentPayload);
  const [countryOptions, setCountryOptions] = useState([]);
  const [countryMultiValue, setCountryMultiValue] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState([]);

  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = methods;

  const opValue = watch("countryOperator");

  const onSubmit = async (data) => {
    try {
      let filters = [];

      if (data.country != null && Object.keys(data.country).length != 0) {
        filters.push({
          fieldName: "countryId",
          fieldValue: convertedValue(data.country, "value").join(","),
          op: data.countryOperator,
          dataType: "int",
          condition: "and",
          isJoin: false,
        });
      }
      if (data.countryCode != "" && data.countryCode != null) {
        filters.push({
          fieldName: "countryCode",
          fieldValue: data.countryCode,
          op: data.codeOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }
      setPayload({
        ...payload,
        filters: filters,
      });
      unSetDialog("advance_filter");
    } catch (err) {
      console.log(err, "errerrerrerr");
    }
  };

  useEffect(() => {
    if (opValue == "in" || opValue == "nin") {
      setDefaultCountry([]);
      setValue("country", []);
      setCountryMultiValue(true);
    } else {
      setDefaultCountry({});
      setValue("country", {});
      setCountryMultiValue(false);
    }
  }, [opValue]);

  const fetchCountry = async () => {
    await autoSearchCountry(countryPayload);
  };
  useEffect(() => {
    fetchCountry();
  }, [countryPayload]);

  useEffect(() => {
    if (countryAutoSearchResults.data.length !== 0) {
      setCountryOptions([...countryOptions, ...countryAutoSearchResults.data]);
    }
  }, [countryAutoSearchResults]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} pt={8}>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <ReactHookFormSelect
                name="countryOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <SearchableAutoComplete
                control={control}
                data={countryOptions}
                label="Country"
                payload={countryPayload}
                setPayload={setCountryPayload}
                loading={isLoadingAutoSearch}
                name="country"
                multiple={countryMultiValue}
                defaultValue={defaultCountry}
              />
            </Grid>
            <Grid item xs={9.5} sm={12} md={6} lg={6} xl={4}>
              <ReactHookFormSelect
                name="codeOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.string).map((t, k) => (
                  <MenuItem key={k} value={t[0]}>
                    {t[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                label={t("country_code")}
                name="countryCode"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
          </Grid>
          <FilterReset resetForm={reset} />
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default AdvanceFilter;
