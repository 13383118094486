import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import useAgreementTracker from "../../../hooks/useAgreementTracker";
import { useForm } from "react-hook-form";
import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Table,
  Box,
  Grid,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const TableRow = styled(MuiTableRow)`
  height: 42px;

  &:last-child th,
  &:last-child td {
    border-bottom: 0;
  }
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const BasicInformation = (props) => {
  const { getAgreementTracker, agreementTracker, agreementTrackers } = useAgreementTracker();

  const methods = useForm({
    resolver: yupResolver(),
  });

  const { state } = useLocation();

  const { id, agreementTrackerId } = props;

  console.log(state, "location");

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
            <Card sx={{ backgroundColor: "sss" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Status
                    </TableCell>

                    <TableCell align="right"> {agreementTracker.status} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Franchise Name:
                    </TableCell>
                    <TableCell align="right">{agreementTracker.tenant?.tenantName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Date of Send:
                    </TableCell>
                    <TableCell align="right">{agreementTracker.dateOfSendStr}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
            <Card sx={{ backgroundColor: "sss" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Date of Agreement:
                    </TableCell>
                    <TableCell align="right">{agreementTracker.dateOfAgreementStr}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Date of Expiry:
                    </TableCell>
                    <TableCell align="right">{agreementTracker.dateOfExpiryStr}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default BasicInformation;
