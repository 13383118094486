import styled from "@emotion/styled";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { MoreVertical } from "react-feather";
import { useTheme } from "@mui/material/styles";
import useComplaint from "../../../hooks/useComplaint";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import useWindowDimensions from "../../../utils/windowDimensions";
import { green, red, orange, pink, purple, blue, grey } from "@mui/material/colors";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import Upload from "../components/Upload";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

Chart.register(ArcElement, Tooltip, Legend);

const Card = styled(MuiCard)`
  margin-bottom: 24px;
`;

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(2)};
  }
`;

const ChartWrapper = styled.div`
  height: 200px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TableCell = styled(MuiTableCell)`
  padding: 8px;
`;

const GreenText = styled.span`
  color: ${() => green[400]};
  font-weight: bold;
`;

const OrangeText = styled.span`
  color: ${() => orange[400]};
  font-weight: bold;
`;

const RedText = styled.span`
  color: ${() => red[400]};
  font-weight: bold;
`;

const BlueText = styled.span`
  color: ${() => blue[400]};
  font-weight: bold;
`;

const Stats = () => {
  const stheme = useTheme();
  const { t } = useTranslation();
  const { state } = useLocation();

  const currentPayload = {
    filters: [
      {
        fieldName: "complaintId",
        fieldValue: state?.id,
        op: "eq",
        dataType: "int",
        condition: "and",
        isJoin: true,
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "complaintStatsId",
        sort: "DESC",
      },
    ],
  };

  const [payload, setPayload] = useState(currentPayload);
  const { getAllComplaintStats, complaintStats, isLoading, downloadFile } = useComplaint();
  const { openDialog } = useDialog();

  const fetchData = async () => {
    await getAllComplaintStats(payload);
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const data = {
    labels: ["Total", "Assigned", "Reassigned", "Resolved", "Closed"],
    datasets: [
      {
        data: [
          complaintStats.total,
          complaintStats.assigned,
          complaintStats.reAssigned,
          complaintStats.resolved,
          complaintStats.closed,
        ],
        backgroundColor: [
          stheme.palette.secondary.main,
          red[500],
          orange[500],
          pink[500],
          green[500],
          purple[500],
        ],
        borderWidth: 5,
        borderColor: stheme.palette.background.paper,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "80%",
  };

  return (
    <Card>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title={t("complaint_stats")}
      />

      <CardContent theme={stheme}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ChartWrapper>
              <DoughnutInner>
                <Typography variant="h4">{complaintStats.total}</Typography>
                <Typography variant="caption">Total</Typography>
              </DoughnutInner>
              <Doughnut data={data} options={options} />
            </ChartWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell align="right">Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries({
                  Total: complaintStats.total,
                  Assigned: complaintStats.assigned,
                  Reassigned: complaintStats.reAssigned,
                  Resolved: complaintStats.resolved,
                  Closed: complaintStats.closed,
                }).map(([status, count]) => (
                  <TableRow key={status}>
                    <TableCell component="th" scope="row">
                      {status}
                    </TableCell>
                    <TableCell align="right">
                      {status === "Total" ? (
                        <RedText>{count}</RedText>
                      ) : status === "Assigned" ? (
                        <RedText>{count}</RedText>
                      ) : status === "Reassigned" ? (
                        <OrangeText>{count}</OrangeText>
                      ) : status === "Resolved" ? (
                        <BlueText>{count}</BlueText>
                      ) : (
                        <GreenText>{count}</GreenText>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Stats;
