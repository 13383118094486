import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert as MuiAlert, Button, DialogActions, Box, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useDialog } from "../../../../contexts/DialogContext";
import useIpTv from "../../../../hooks/useIpTv";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import useTenant from "../../../../hooks/useTenant";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";

const initialValues = {
  policy: [],
};

const validationSchema = Yup.object().shape({
  iptvPlan: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.number().required(),
      }),
    )
    .min(1, "You need at least one policy")
    .required("Please select the policy"),
});

const Alert = styled(MuiAlert)(spacing);

const MapIpTvPlanModal = ({ tenantId, isNew }) => {
  const currentIpTvPlanPayload = {
    filters: [
      {
        fieldName: "tenantId",
        fieldValue: tenantId,
        op: "eq",
        isJoin: true,
        dataType: "long",
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };
  const { unSetDialog } = useDialog();
  const [modalNew, setModalNew] = useState(isNew);

  console.log("isNewisNew", isNew);
  const dispatch = useDispatch();

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { t } = useTranslation();

  const { autoSearchIpTvPlan, iptvPlanAutoSearchResults, autoSearchLoading } = useIpTv();
  const { addTenantIpTvPlan, isSubmitting, getTenantIpTvPlans } = useTenant();
  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };

  const [payload, setPayload] = useState(currentPayload);
  const [ipTvPolicyPayload, setIpTvPlanPayload] = useState(currentIpTvPlanPayload);

  const [ipTvPolicyOptions, setIpTvPlanOptions] = useState([]);

  const fetchData = async () => {
    console.log("i am hererer");
    await autoSearchIpTvPlan(payload);
  };

  useEffect(() => {
    if (iptvPlanAutoSearchResults.data.length !== 0) {
      setIpTvPlanOptions([...ipTvPolicyOptions, ...iptvPlanAutoSearchResults.data]);
    }
  }, [iptvPlanAutoSearchResults]);

  useEffect(() => {
    fetchData();
  }, [payload]);

  const { handleSubmit, control, reset } = methods;

  const onSubmit = async (data) => {
    try {
      const policyDetails = [];
      const plans = data.iptvPlan;

      for (let i = 0; i < plans.length; i++) {
        policyDetails.push({
          tenantId: tenantId,
          ipTvPolicyId: plans[i]["value"],
          planName: plans[i]["label"],
        });
      }

      console.log(policyDetails, "policyDetails");
      await addTenantIpTvPlan(policyDetails);
      await getTenantIpTvPlans(ipTvPolicyPayload);
      reset();
      dispatch(showSuccessSnackbar("IpTv Policy mapped successfully!"));
      unSetDialog("map_iptv_plan_modal");
    } catch (err) {
      dispatch(showErrorSnackbar(err.message));
    }
  };

  useEffect(() => {
    console.log(modalNew, "modalNewmodalNew");
    if (modalNew) {
      setIpTvPlanOptions([]);
      setModalNew(false);
    }
  }, [modalNew]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 10 }}>
            <SearchableAutoComplete
              control={control}
              data={ipTvPolicyOptions}
              label={t("choose_iptv_policy")}
              payload={payload}
              setPayload={setPayload}
              loading={autoSearchLoading}
              name="iptvPlan"
              multiple={true}
            />
          </Box>
          <DialogActions>
            <Button
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              endIcon={isSubmitting ? <CircularProgress size={24} /> : null}
            >
              Add
            </Button>

            <Button
              variant="text"
              onClick={() => unSetDialog("map_iptv_plan_modal")}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default MapIpTvPlanModal;
