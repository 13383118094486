import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { PaymentProcessProvider } from "../contexts/PaymentProcessContext";
import { DialogProvider } from "../contexts/DialogContext";

export const PaymentProcessComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[<PaymentProcessProvider key={0} />, <DialogProvider key={1} />]}
    >
      {children}
    </ContextProviderComposer>
  );
};
