import React, { useEffect } from "react";

import { styled } from "@mui/material/styles";

import useUser from "../../../hooks/useUser";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CircularProgress, Box } from "@mui/material";

import NormalUser from "./NormalUser";
import HotelUser from "./HotelUser";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [
    {
      field: "createdAt",
      sort: "DESC",
    },
  ],
};

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const useTabStyles = styled({
  root: {
    justifyContent: "center",
    width: "1000px",
  },
  scroller: {
    flexGrow: "0",
  },
});

function UserDetails(props) {
  console.log(props, "useeeeeeeeeeeeeeeeeeeee");
  const classes = useTabStyles();
  let [searchParams, setSearchParams] = useSearchParams();
  const { getUser, user, isLoading } = useUser();

  console.log("isLoading", isLoading);

  const fetchData = async () => {
    await getUser(searchParams.get("id"));
  };

  useEffect(() => {
    if (searchParams && searchParams.get("id")) {
      const currentPayload = {
        filters: [
          {
            fieldName: "radUserId",
            fieldValue: searchParams.get("id"),
            op: "eq",
            dataType: "string",
            condition: "and",
            isJoin: false,
          },
        ],
        pagination: {
          pageNumber: 1,
          pageSize: 100,
        },
        sortFields: [],
      };
      fetchData();
    }
  }, [searchParams]);

  return (
    <React.Fragment>
      {isLoading ? (
        <Box display="flex" justifyContent="center" my={6}>
          <CircularProgress />
        </Box>
      ) : user && user.userType == "REGULAR" ? (
        <NormalUser permissionKeys={props?.permissions?.permissionKeys} />
      ) : (
        <HotelUser />
      )}
    </React.Fragment>
  );
}

export default UserDetails;
